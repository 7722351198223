import { PureComponent } from 'react';
import * as React from 'react';
// Material helpers
import { withStyles, Button, Typography } from '@material-ui/core';
// DateTime helpers
import { DateTime } from 'luxon';
// Snackbar notifications
import { withSnackbar } from 'notistack';
// Component styles
import styles from './styles';
import Calendar from './calendar';

class SearchToolbar extends PureComponent {
  constructor(props) {
    super(props);

    // Set the minimum and maximum dates
    this.range = {
      min: DateTime.fromObject({ hour: 0 }),
      max: DateTime.fromObject({ hour: 0 }).plus({ days: 6 })
    };

    // Initialize the component's state
    this.state = {
      start: this.range.min,
      end: this.range.min.plus({ hours: 24 }).minus({ seconds: 1 }),
      selectedTime: 'anytime',
      value: [null, null]
    };

    // Bind state-manipulating functions
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleSearchClick() {
    const { onSearch } = this.props;
    const searchQuery = {};

    Object.entries(this.state).forEach(([key, value]) => {
      if (value !== null) {
        if (['start', 'end'].includes(key)) {
          value = value.toMillis();
        }
        searchQuery[key] = value;
      }
    });

    onSearch(searchQuery);
  }

  handleTimeChange(event) {
    const timeValue = event.target.value;
    this.setState({ selectedTime: timeValue });
  }

  handleDateChange(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate.plus({ hours: 24 }).minus({ seconds: 1 })
    });
  }

  render() {
    const { classes, loading, message } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h2">
          Booking Search
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          Search for a date and then available teachers and booking times will
          become visible.
          <br />
          After you choose a teacher, you will then choose your lesson topic.
        </Typography>
        <div className={classes.row}>
          <Calendar
            onDateChange={this.handleDateChange}
            selectedTime={this.state.selectedTime}
            onTimeChange={this.handleTimeChange} // Pass the method correctly
            showTime={true}
          />
          <Button
            className={classes.searchButton}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={this.handleSearchClick}>
            Search
          </Button>
        </div>
        {message && (
          <Typography variant="h5" className={classes.errorMessage}>
            {message}
          </Typography>
        )}
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(SearchToolbar));
