export default (theme) => ({
  portletContent: {
    padding: theme.spacing(2) // Adjust the padding as per your preference
  },
  title: {
    marginTop: theme.spacing(1),
    fontWeight: '500'
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: '400'
  },

  submitButton: {
    marginLeft: 'auto', // Push the button to the right
    marginTop: theme.spacing(2) // Add margin top for spacing
  },
  addDayTimeButton: {
    marginTop: theme.spacing(2), // Add margin top for spacing
    marginBottom: theme.spacing(2) // Add margin top for spacing
  }
});
