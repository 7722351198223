import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // height: '100vh',
    position: 'relative',
    overflowY: 'hidden',
    // justifyContent: 'space-between'
    height: 'calc(var(--vh, 1vh) * 100)'
  },
  main: {
    display: 'flex',
    flexDirection: 'row'
  },
  joiningRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  sidebar: {
    padding: theme.spacing(2.5),
    overflowY: 'auto',
    height: '90vh'
  },
  bottombar: {
    padding: theme.spacing(2.5),
    paddingTop: 0,
    paddingInlineEnd: 0
  },
  bottombarLandscape: {
    paddingTop: 0,
    display: 'flex',
    backgroundColor: '#ffffff'
  },
  participants: {
    overflowX: 'auto',
    display: 'flex'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailResponsive: {
    width: '70%'
  },
  materialsContainer: {
    display: 'flex',
    flex: '1',
    // height: 'calc(100vh - 81px)'
    height: '90vh'
  },
  materialsContainerResponsive: {
    display: 'flex',
    flex: '1',
    height: 'auto',
    justifyContent: 'center'
  },
  bookingInfoWrapper: {},
  divider: {
    marginTop: theme.spacing(2.5)
  },
  tip: {
    marginBottom: theme.spacing(2.5),
    maxWidth: 310
  },
  landscapView: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    position: 'absolute',
    bottom: 0
  },
  controls: {}
}));
