import { useState, useContext } from 'react';

// Material UI imports
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles
} from '@material-ui/core';

// Import calling context
import { CallContext } from 'services/call';

interface Props {
  kind: 'videoinput' | 'audioinput';
  mediaDevices: MediaDeviceInfo[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}));

function MediaSelect(props: Props): JSX.Element | null {
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const classes = useStyles();
  const call = useContext(CallContext);

  // Ensure that we're in a call
  if (!call.localParticipant) return null;

  // Destructure props & generate classnames
  const { kind, mediaDevices } = props;

  // Determine the track label
  const trackLabel: string = (
    kind === 'videoinput'
      ? Array.from(call.localParticipant.videoTracks.values())
      : Array.from(call.localParticipant.audioTracks.values())
  )[0].track.mediaStreamTrack.label;

  const onChangeHandler = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const device = mediaDevices.find((device) => device.label === value);
    if (device && call.switchCamera && call.switchMic) {
      setIsSwitching(true);
      if (kind === 'videoinput') {
        await call.switchCamera(device.deviceId);
      } else if (kind === 'audioinput') {
        await call.switchMic(device.deviceId);
      }
      setIsSwitching(false);
    }
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {kind === 'audioinput' ? 'Audio' : 'Video'} Sources
        </FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={trackLabel}
          onChange={onChangeHandler}>
          {mediaDevices
            .filter((device) => device.kind === kind)
            .map((device, index) => (
              <FormControlLabel
                key={index}
                disabled={isSwitching}
                value={device.label}
                control={<Radio />}
                label={device.label}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default MediaSelect;
