import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(5),
    textAlign: 'center'
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '80%',
    width: '554px'
  },
  sub: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2)
  },
  actions: {
    marginTop: theme.spacing(4)
  },
  signOut: {
    display: 'inline-block'
  },
  verifyEmail: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  }
}));
