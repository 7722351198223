export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  rangeText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  rangeSpacer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});
