import { ReactNode } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Component styles
const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      height: 'calc(100vh - 186px);',
      width: '100%'
    }
  };
});

const Loading = (): ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
};

export default Loading;
