import { grey } from '@material-ui/core/colors';

interface MaterialLevelInfo {
  color: string;
  name: string;
}

const levelDescriptions: Record<string, string> = {
  Beginners:
    'Introducing vocabulary, basic sentence construction, foundational conversation skills.',
  Elementary:
    'Building on basic vocabulary, simple to moderate sentences, basic conversation practice.',
  'Pre-Intermediate':
    'Growing vocabulary, forming some complex sentences, conversational practice with moderate complexity.',
  'Intermediate I':
    'Expanding vocabulary, forming more complex sentences, conversational practice with increased complexity.',
  'Intermediate II':
    'Enriching vocabulary, refining complex sentence structures, engaging in more targeted conversations.',
  'Pre-Advanced': 'Description for Pre-Advanced level...',
  'Advanced I': 'Description for Advanced I level...',
  'Advanced II': 'Description for Advanced II level...',
  'Pre-Proficient': 'Description for Pre-Proficient level...',
  Proficient: 'Description for Proficient level...'
};

const getMaterialLevelInfo = (
  level: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
): MaterialLevelInfo => {
  if (level === 1) {
    return {
      color: '#EAAD9A',
      name: 'Beginners'
    };
  } else if (level === 2) {
    return {
      color: '#EDB283',
      name: 'Elementary'
    };
  } else if (level === 3) {
    return {
      color: '#96E29C',
      name: 'Pre-Intermediate'
    };
  } else if (level === 4) {
    return {
      color: '#6EA672',
      name: 'Intermediate I'
    };
  } else if (level === 5) {
    return {
      color: '#4A734D',
      name: 'Intermediate II'
    };
  } else if (level === 6) {
    return {
      color: '#84DAEA',
      name: 'Pre-Advanced'
    };
  } else if (level === 7) {
    return {
      color: '#69AEBB',
      name: 'Advanced I'
    };
  } else if (level === 8) {
    return {
      color: '#487881',
      name: 'Advanced II'
    };
  } else if (level === 9) {
    return {
      color: '#C599E7',
      name: 'Pre-Proficient'
    };
  } else if (level === 10) {
    return {
      color: '#8C6EA2',
      name: 'Proficient'
    };
  }
  return {
    color: grey[500],
    name: 'Unknown'
  };
};

const getMaterialLevelDescription = (level: number | string): string => {
  const levelKey = level.toString();
  return levelDescriptions[levelKey] || 'Unknown';
};

export { getMaterialLevelInfo, getMaterialLevelDescription };
