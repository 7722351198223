// Externals
import classNames from 'classnames';

// Material helpers
import { Input, withStyles } from '@material-ui/core';

// Material icons
import { Search as SearchIcon } from '@mui/icons-material';

// Component styles
import styles from './styles';

const SearchInput = (props) => {
  const { classes, className, onChange, style, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        disableUnderline
        className={classes.input}
        onChange={onChange}
      />
    </div>
  );
};

export default withStyles(styles)(SearchInput);
