import { PureComponent, Fragment, createRef } from 'react';

// Authentication provider
import { withAuth } from 'services/auth';

// Authentication provider
import { withRouter } from 'react-router-dom';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Material UI components / helpers
import { Grid, withStyles, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Export cleanup
import compose from 'recompose/compose';

// Async status
import { AsyncStatus, BookingCard, ConfirmDialog } from 'components';

// Component styles
import styles from './styles';

class UpcomingBookingsView extends PureComponent {
  constructor(props) {
    super(props);

    // Initialzie the state
    this.state = {
      bookings: undefined,
      error: undefined
    };

    // Create a React ref for the cancel confirm dialog
    this.cancelConfirmDialog = createRef();

    // Bind the callback functions
    this.cancelBooking = this.cancelBooking.bind(this);
    this.onSnapData = this.onSnapData.bind(this);
    this.onSnapError = this.onSnapError.bind(this);
    this.retryHook = this.retryHook.bind(this);
    this.gotoSchedule = this.gotoSchedule.bind(this);

    // Initialize the ready flag
    this.ready = true;
  }

  componentDidMount() {
    const { auth } = this.props;

    // Retrive the current user's bookings from the API.
    this.bookingsHook = auth.api.bookings.hook(
      'bookingsMyLiveBookings',
      this.onSnapData,
      this.onSnapError,
      'UpcomingBookingsView'
    );
  }

  componentWillUnmount() {
    // If the unsubscribe function is valid, unsubscribe
    if (this.bookingsHook) this.bookingsHook();

    // Set the ready flag to false
    this.ready = false;
  }

  onSnapData(snap) {
    // Update the component's state with the bookings.
    if (this.ready) {
      this.setState({
        bookings: snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
      });
    }
  }

  onSnapError(error) {
    // Update the component's state to show an error
    if (this.ready) {
      this.setState({
        error: `${getErrorMessage(error)} (try reloading the page)`
      });

      // Log the error to the console.
      console.error(error);
    }
  }

  retryHook() {
    // Reset the state
    this.setState({
      bookings: undefined,
      error: undefined
    });

    // Unsubscribe from the bookings hook
    if (this.bookingsHook) this.bookingsHook();

    // Call componentDidMount to call the API again.
    this.componentDidMount();
  }

  async cancelBooking(booking) {
    const { auth, enqueueSnackbar } = this.props;
    const { id, tutor } = booking;

    // Wrap in a try-catch to handle errors
    try {
      // Notify the user of the cancellation beginning
      enqueueSnackbar('Cancelling lesson booking...');

      // Cancel the booking
      await auth.api.bookings.cancelBooking(tutor, id);

      // Notify the user of the csuccess
      enqueueSnackbar('Booking cancelled!', { variant: 'success' });
    } catch (error) {
      // Log the error to the console.
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  async cancelBookingTutor(booking, formData) {
    const { auth, enqueueSnackbar } = this.props;
    const { id, tutor } = booking;

    // Wrap in a try-catch to handle errors
    try {
      // Notify the user of the cancellation beginning
      enqueueSnackbar('Cancelling lesson booking...');

      // Cancel the booking
      await auth.api.bookings.cancelTutorBooking(tutor, id, formData.details);

      // Notify the user of the csuccess
      enqueueSnackbar('Booking cancelled!', { variant: 'success' });
    } catch (error) {
      // Log the error to the console.
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  gotoSchedule() {
    // Grab the history provider from the props
    const { auth, history, onUpdateTab } = this.props;

    // Redirect the user to the bookings/schedule page.
    if (auth.role === 'student') {
      onUpdateTab(0);
    } else {
      history.push('/tutor/schedule');
    }
  }

  render() {
    const { classes, auth, onViewFeedback } = this.props;
    const { error } = this.state;
    let { bookings } = this.state;

    if (error) {
      // Display the error message and retry button
      return <AsyncStatus error={error} onRetry={this.retryAPICall} />;
    }

    // If we haven't receive any booking yet, show a loading animation
    if (!bookings) return <AsyncStatus loading />;

    // Ensure only booked bookings are rendered
    bookings = bookings.filter(
      (booking) => booking.status === 'booked' && booking.when >= Date.now()
    );

    if (bookings.length === 0) {
      // Notify the user that they have no upcoming sessions.
      return (
        <div className={classes.root}>
          <AsyncStatus
            error="You have no upcoming bookings"
            onRetry={this.gotoSchedule}
            retryText={`go to ${
              auth.role === 'student' ? 'bookings search' : 'schedule'
            }`}
            retrySize="medium"
            textVariant="h4"
            retry
          />
        </div>
      );
    }
    // Return a rendered list of the items.
    return (
      <>
        <ConfirmDialog
          ref={this.cancelConfirmDialog}
          auth={auth}
          onConfirm={(booking, formData) => {
            formData
              ? this.cancelBookingTutor(booking, formData)
              : this.cancelBooking(booking);
          }}
        />
        <Alert className={classes.reminderAlert} severity="info">
          <b>Reminder: </b>{' '}
          {auth.role === 'tutor'
            ? 'Click on student profiles to view feedback history'
            : 'Click on teacher photos to navigate to their profile'}
        </Alert>
        <Grid container spacing={2}>
          {bookings &&
            bookings.map((booking, index) => (
              <Fragment key={booking.id}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <BookingCard
                    auth={auth}
                    booking={booking}
                    onCancel={() => {
                      this.cancelConfirmDialog.current.open(booking, {
                        item: `Booking with ${
                          auth.role == 'tutor'
                            ? booking.profile.student.name
                            : booking.profile.tutor.name
                        }`,
                        action: 'Cancel',
                        prefix: 'this',
                        ...(auth.role == 'tutor' && {
                          content:
                            'Please provide a reason for cancelling this lesson. This information will be assessed by the e-English administration team.'
                        })
                      });
                    }}
                    onViewFeedback={(bookingFeedback) => {
                      onViewFeedback(bookingFeedback);
                    }}
                  />
                </Grid>
                {index !== bookings.length - 1 && (
                  <Grid
                    key={booking.id}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}>
                    <Divider />
                  </Grid>
                )}
              </Fragment>
            ))}
        </Grid>
      </>
    );
  }
}

export default compose(
  withSnackbar,
  withRouter,
  withAuth,
  withStyles(styles)
)(UpcomingBookingsView);
