// View icons
import {
  DeviceUnknownRounded as UnknownIcon,
  DeveloperModeRounded as DeveloperIcon
} from '@mui/icons-material';

// Role-specific view imports
import DeveloperView from 'views/shared/Developer';
import AdminItems from './data/admin';
import TutorItems from './data/tutor';
import StudentItems from './data/student';

interface View {
  route: string;
  text?: string;
  icon?: any;
  component: any;
}

// Developer view
const Developer: View = {
  route: '/developer',
  text: 'Developer',
  icon: <DeveloperIcon />,
  component: DeveloperView
};

function getViewComponents(role: string): View[] {
  switch (role) {
    default:
      return [
        {
          route: '/not-found',
          text: 'Role Not Found',
          icon: <UnknownIcon />,
          component: null
        }
      ];
    case 'admin':
      return AdminItems;
    case 'tutor':
      return TutorItems;
    case 'student':
      return StudentItems;
  }
}

export type { View };

export default (role: string, dev = false): View[] => {
  const views = getViewComponents(role);
  return role && dev ? [...views, Developer] : views;
};
