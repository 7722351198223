import { red } from '@material-ui/core/colors';

export default (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  info: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'start',
    flexGrow: 1
  },
  label: {
    // marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  progress: {
    marginTop: 'auto',
    marginBottom: theme.spacing(0.5)
  },
  uploadButton: {
    marginRight: theme.spacing(1.5),
    width: 120
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary
  },
  cvsubtitle: {
    marginTop: theme.spacing(0.5),
    color: red[500],
    fontWeight: 500
  }
});
