export default (data) => {
  if (typeof data === 'string') return data;

  if (data.error) {
    const { error } = data;
    if (error.message) {
      switch (error.message) {
        default:
          return error.message;
        case 'Failed to fetch':
          return "We can't reach our servers right now, please bear with us.";
      }
    }
    if (error.errorInfo) return data.error.errorInfo.message;
  }

  if (typeof data.message === 'string') return data.message;
  return 'An error has occured.';
};
