export default (search, original) => {
  if (search.length > original.length) return false;
  for (let charIndex = 0; charIndex < search.length; charIndex += 1) {
    if (search[charIndex].toLowerCase() !== original[charIndex].toLowerCase()) {
      return false;
    }
  }

  return true;
};
