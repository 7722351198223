/* eslint-disable no-unused-vars */
import { createContext } from 'react';
import {
  RemoteParticipant,
  LocalTrack,
  LocalParticipant,
  LocalAudioTrackPublication,
  LocalVideoTrackPublication,
  LocalVideoTrack
} from 'twilio-video';

enum CallStatus {
  Disconnected = 0,
  Connecting,
  Connected
}

interface ICallContext {
  status: CallStatus;
  participants: RemoteParticipant[];
  localParticipant?: LocalParticipant;
  connected: boolean;
  muted: boolean;
  camera: boolean;
  screenSharing: boolean;
  error: Error | null;
  toggleMute?: () => void;
  switchMic?: (deviceId: string) => Promise<LocalAudioTrackPublication | null>;
  toggleCamera?: () => void;
  switchCamera?: (
    deviceId: string
  ) => Promise<LocalVideoTrackPublication | null>;
  join?: (
    token: string,
    roomName: string,
    tracks?: LocalTrack[]
  ) => Promise<void>;
  leave?: () => void;
  startScreenSharing?: () => Promise<void>;
  stopScreenSharing?: () => Promise<void>;
  screenTrack: LocalVideoTrack | null; // Add screenTrack property here
}

const CallContext = createContext<ICallContext>({
  status: CallStatus.Disconnected,
  participants: [],
  connected: false,
  muted: false,
  camera: false,
  screenSharing: false,
  error: null,
  screenTrack: null // Include screenTrack in the default context value
});

export { CallStatus };
export default CallContext;
