import { PureComponent } from 'react';

// Authentication provider
import { withAuth } from 'services/auth';

// To clean up exports
import compose from 'recompose/compose';

// Material UI components / helpers
import { Grid, withStyles } from '@material-ui/core';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Async status
import { AsyncStatus } from 'components';

// Announcement card
import AnnouncementCard from './components/AnnouncementCard';

import firebase from 'firebase/app';

// Component styles
import styles from './styles';

class Announcements extends PureComponent {
  constructor(props) {
    super(props);

    // Initialzie the state
    this.state = {
      loading: true,
      announcements: [],
      error: null
    };

    // Initialize the ready flag and bind any functions
    this.ready = true;
    this.retryAPICall = this.retryAPICall.bind(this);
  }

  async componentDidMount() {
    try {
      // Setup references to the API endpoints and
      // the uid of the current user
      const { auth } = this.props;
      const firestore = firebase.firestore();
      // Create a reference to the Firestore document to listen to
      const docRef = firestore.doc(`${auth.role}s/${auth?.user?.uid}`);
      this.unsubscribe = docRef.onSnapshot(async (doc) => {
        if (doc.exists) {
          const docs = doc.data()?.data?.announcements || [];
          const unviewedAnnouncements = docs.filter(
            (ann) =>
              typeof ann.is_viewed === 'undefined' || ann.is_viewed === false
          );
          const viewedAnnouncements = docs.filter(
            (ann) =>
              typeof ann.is_viewed === 'undefined' || ann.is_viewed === true
          );

          // Retrive the current user's bookings from the API.
          let data = await auth.api.announcements.getAnnouncements();

          // Filter out announcements from data array that are not present in viewedAnnouncements array based on id
          const unviewedData = data.filter((dataAnnouncement) => {
            // Check if there is no matching id in viewedAnnouncements
            return !viewedAnnouncements.some(
              (viewedAnnouncement) =>
                viewedAnnouncement.id === dataAnnouncement.id
            );
          });

          // Merge unviewedData with unviewedAnnouncements
          const mergedData = [...unviewedData, ...unviewedAnnouncements];

          // Update the component's state with the bookings.
          if (this.ready) {
            this.setState({
              loading: false,
              announcements: mergedData
            });
          }
        }
      });
    } catch (error) {
      // Update the component's state to show an error
      if (this.ready) {
        this.setState({
          loading: false,
          error: getErrorMessage(error)
        });
      }

      // Log the error to the console.
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.ready = false;

    // Unsubscribe the Firestore listener when the component is unmounted
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  retryAPICall() {
    // Reset the state
    this.setState({
      announcements: [],
      loading: true,
      error: null
    });

    // Call componentDidMount to call the API again.
    this.componentDidMount();
  }

  renderAnnouncements() {
    const { loading, announcements, error } = this.state;

    // const handleViewed = async (announcement) => {
    //   if (
    //     !announcement.admin ||
    //     !announcement.adminPost ||
    //     announcement.adminPost == 'undefined'
    //   ) {
    //     const nannouncements = this.state.announcements.filter((item) => {
    //       return item.bookingId === announcement.bookingId;
    //     });

    //     /** Get Old Collection */
    //     let old = await firebase
    //       .firestore()
    //       .doc(`${this.props.auth.role}s/${this.props.auth?.user?.uid}`)
    //       .get();

    //     old = old.data()?.data?.announcements;
    //     const udoc = old.map((item) => {
    //       if (item.bookingId === announcement.bookingId) {
    //         // If the bookingId matches, update the object
    //         return announcement;
    //       } else {
    //         // Otherwise, keep the original object
    //         return item;
    //       }
    //     });

    //     /** Update Array in Firestore */
    //     firebase
    //       .firestore()
    //       .doc(`${this.props.auth.role}s/${this.props.auth?.user?.uid}`)
    //       .update({ data: { announcements: udoc } });

    //     this.setState({ data: { announcement: nannouncements } });
    //   } else {
    //     this.setState({ data: { announcement: announcements } });
    //   }
    // };

    if (loading) {
      return <AsyncStatus loading />;
    }
    if (error !== null) {
      // Display the error message and retry button
      return <AsyncStatus error={error} onRetry={this.retryAPICall} retry />;
    }
    if (announcements.length === 0) {
      // Notify the user that they have no upcoming sessions.
      return <AsyncStatus error="No announcements have been posted" />;
    }
    // Return a rendered list of the items.
    return announcements.map((announcement) => {
      if (
        typeof announcement.is_viewed == 'undefined' ||
        announcement.is_viewed == false
      ) {
        return (
          <Grid
            key={`${announcement.when}${announcement.title}`}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}>
            <AnnouncementCard
              // handleViewed={handleViewed}
              announcement={announcement}
              auth={this.props.auth}
            />
          </Grid>
        );
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          {this.renderAnnouncements()}
        </Grid>
      </div>
    );
  }
}

export default compose(withAuth, withStyles(styles))(Announcements);
