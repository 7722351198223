export default (theme) => ({
  root: {
    minHeight: 200
  },
  fullList: {
    flexGrow: 1,
    maxHeight: 250,
    '& :first-child > div.MuiButtonBase-root': {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16
    }
  },
  refreshButton: {
    margin: -theme.spacing(2)
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
    flexDirection: 'column'
  },
  loadingInner: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '& .MuiSkeleton-text': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    }
  }
});
