// Material UI imports
import { makeStyles, Tooltip, Typography } from '@material-ui/core';

// Material Icons
// import { SendRounded as SendIcon } from '@mui/icons-material';

// Luxon DateTime helper
import { DateTime } from 'luxon';

// Portlet items
// import {
//   Portlet,
//   PortletHeader,
//   PortletLabel,
//   PortletContent,
//   // PortletFooter
// } from 'components';

// Style
import styles from './styles';
const useStyles = makeStyles(styles);

const getMessageClassName = (length, index) => {
  if (length > 1) {
    if (index === 0) {
      return 'first';
    } else if (index === length - 1) {
      return 'last';
    } else return 'first last';
  } else return '';
};

function MessageGroup(props) {
  const classes = useStyles();
  const { group, right } = props;

  return (
    <div className={`${classes.root} ${right ? 'right' : ''}`}>
      <Typography variant="caption" color="textSecondary">
        {group[0].name.split(' ')[0]}
      </Typography>
      {group.map((message, index) => (
        <div key={message.id}>
          <Tooltip
            title={DateTime.fromMillis(message.when).toFormat(
              'MMM d, yyyy, hh:mm a'
            )}
            placement={right ? 'left' : 'right'}>
            <div
              className={`message ${getMessageClassName(group.length, index)}`}
              style={{ opacity: message.unsent ? 0.5 : 1 }}>
              <Typography>{message.content}</Typography>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default MessageGroup;
