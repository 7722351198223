import { PureComponent } from 'react';

// luxon DateTime
import { DateTime } from 'luxon';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';
import CallTimeline from 'components/CallTimeline';

class Dialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false,
      booking: null
    };

    // Bind component functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.confirm
    };
  }

  // Create a open function to hook into in
  // the parent component
  open(booking) {
    this.setState({
      open: true,
      booking
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({ open: false });
  }

  confirm() {
    this.close();
  }

  render() {
    const { open, booking } = this.state;

    if (!booking) return null;

    // Render the dialog
    return (
      <ResponsiveDialog
        title={DateTime.fromMillis(booking.when).toLocaleString(
          DateTime.DATETIME_FULL
        )}
        subtitle={`${booking.profile.tutor.name} & ${booking.profile.student.name}`}
        events={this.events}
        open={open}
        confirmText="OK"
        hideCancelButton>
        <CallTimeline booking={booking} />
      </ResponsiveDialog>
    );
  }
}

export default Dialog;
