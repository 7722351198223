import { useState, useEffect, useRef } from 'react';

// Material UI imports
import {
  Button,
  FormControl,
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
  makeStyles,
  CircularProgress,
  Typography,
  Divider
} from '@material-ui/core';

// Material Icons
import { SendRounded as SendIcon } from '@mui/icons-material';

// MessageGroup component
import MessageGroup from './components/MessageGroup';

// Style
import styles from './styles';
const useStyles = makeStyles(styles);

// Helper function for grouping messages by user
function groupMessages(messages) {
  // Create an array to store each 'group' of messages
  // (i.e. batch of messages from one user, before response from other user)
  const messageGroups = [];

  // Add each batch of messages to the group array
  let lastMessage = null;
  let currentBatch = [];

  // Loop through all messages and batch them
  for (let m = 0; m < messages.length; m++) {
    const message = messages[m];

    // If the last message's sender is not the make
    if (lastMessage !== null && message.uid !== lastMessage.uid) {
      messageGroups.push(currentBatch);
      currentBatch = [message];
    } else {
      currentBatch.push(message);
    }

    // If we're processing the last message in the array, push
    // the remaining current batch to the message group array
    if (m === messages.length - 1 && currentBatch.length > 0) {
      messageGroups.push(currentBatch);
    } else {
      // Otherwise, update our 'lastMessage' variable
      // for comparison in the next loop iteration
      lastMessage = message;
    }
  }

  return messageGroups;
}

function Chat(props) {
  const [message, setMessage] = useState('');
  const { messages, uid, error, onSend, onRetry } = props;
  const scrollDiv = useRef(null);
  const classes = useStyles();

  // Handler for sending messages
  const sendMessage = (messageToSend) => {
    // If the user has typed in a valid message and
    // an 'onSend' handler has been provided, execute
    // the handler with the message
    if (messageToSend !== null && messageToSend !== '' && onSend)
      onSend(messageToSend);

    // Clear the text field
    setMessage('');
  };

  // Automatically scroll to the bottom when new messages are recieved
  useEffect(() => {
    if (scrollDiv.current)
      scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className={classes.root}>
      {(() => {
        // Firstly check whether there's a messaging error
        if (error) {
          return (
            <div className={classes.loader}>
              <Typography variant="subtitle1" color="textSecondary">
                {error}
              </Typography>
              {onRetry && (
                <Button
                  className={classes.retryButton}
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={onRetry}>
                  Retry
                </Button>
              )}
            </div>
          );
        }

        // If we've recieved the messages array
        if (messages) {
          if (messages.length > 0) {
            return (
              <div className={classes.messages}>
                {messages
                  ? groupMessages(messages).map((group, index) => (
                      <MessageGroup
                        key={index}
                        right={group[0].uid === uid}
                        group={group}
                      />
                    ))
                  : 'No messages'}
                <div ref={scrollDiv} />
              </div>
            );
          } else {
            return (
              <div className={classes.loader}>
                <Typography variant="subtitle1" color="textSecondary">
                  No messages yet, say hi!
                </Typography>
              </div>
            );
          }
        } else
          return (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          );
      })()}
      <Divider />
      <FormControl
        className={classes.actions}
        variant="filled"
        size="small"
        disabled={!messages}>
        <InputLabel htmlFor="chat-input">Message</InputLabel>
        <FilledInput
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') sendMessage(message);
          }}
          id="chat-input"
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                disabled={!messages}
                onClick={() => sendMessage(message)}
                edge="end">
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

export default Chat;
