import { colors } from '@material-ui/core';

import { white } from '../../common/colors';

export default {
  type: 'dark',
  common: {},
  primary: {
    main: '#0767DB',
    light: 'rgba(7, 103, 219, 0.08)',
    dark: '#0B48A0'
  },
  secondary: {
    main: '#7d58ff',
    light: 'rgba(125, 88, 255, 0.08)',
    dark: '#37248F'
  },
  text: {
    primary: white,
    secondary: colors.blueGrey[200],
    disabled: '#A6B1BB'
  },
  background: {
    default: 'rgb(22, 28, 36)',
    dark: 'rgb(22, 28, 36)',
    paper: 'rgb(33, 43, 54)'
  }
};
