export default (theme) => ({
  message: {
    marginBottom: theme.spacing(2)
  },
  from: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary
  },
  visible: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
});
