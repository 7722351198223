export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar,
    backdropFilter: 'blur(8px)'
  },
  toolbar: {
    height: '100%',
    width: '100%'
  },
  title: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1)
  },
  menuButton: {
    marginLeft: '-4px'
  },
  topbarRight: {
    marginLeft: 'auto'
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});
