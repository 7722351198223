// Palette
import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.background.default,
    color: palette.text.secondary
  },
  deletable: {
    '&:focus': {
      backgroundColor: palette.background.default
    }
  },
  outlined: {
    '&:hover': {
      backgroundColor: palette.background.default
    }
  }
};
