import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import useWindowSize from 'hooks/useWindowSize';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
};

export const ReactPdf = (props) => {
  const { url } = props;
  const [numPages, setNumPages] = useState();
  const [width] = useWindowSize();

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  return (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={'loading'}
      options={options}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={width < 500 ? width - 10 : width}
          height={422}
          scale={1}
        />
      ))}
    </Document>
  );
};
