export default (theme) => ({
  root: {},
  portlet: {
    borderRadius: '0!important'
  },
  lessonIcon: {
    color: theme.palette.text.secondary
  },
  waiting: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  waitingLoader: {
    marginRight: theme.spacing(2.5)
  },
  callLoader: {
    marginRight: theme.spacing(1.5),
    color: 'white'
  },
  waitingText: {
    color: theme.palette.text.secondary
  },
  bookingsBtn: {
    marginRight: theme.spacing(2.5)
  }
});
