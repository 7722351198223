import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';

interface Announcement {
  id?: string;
  for: string[];
  title: string;
  message: string;
  icon: 'announce' | 'feature' | 'update' | 'user';
  from?: string;
  admin?: boolean;
  is_viewed?: boolean;
}

interface APIAnnouncements {
  /**
   * API endpoint for getting announcements
   */
  getAnnouncements: () => Promise<Announcement[]>;
  /**
   * ADMIN API endpoint for posting announcements
   */
  adminPostAnnouncement: (announcement: Announcement) => Promise<Announcement>;
  /**
   * ADMIN API endpoint for deleting announcements
   */
  adminDeleteAnnouncement: (announcement: string) => Promise<void>;
}

function Announcements(self: API): APIAnnouncements {
  const getAnnouncements = async (): Promise<Announcement[]> => {
    // Grab the user's role from the credentials
    const role = self.credentials?.role;

    // Return the data from firebase
    return self._firebase<Announcement[]>(
      'announcementsGetAnnouncements',
      async () => {
        // Create the base query
        let announcementsQuery = firebase
          .firestore()
          .collection('announcements')
          .orderBy('when', 'desc');

        // If we're not an admin, only return the announcements we can see
        if (role !== 'admin') {
          announcementsQuery = announcementsQuery.where(
            'for',
            'array-contains',
            role
          );
        }

        // Grab the announcements and return them
        return (await announcementsQuery.get()).docs.map((announcement) => {
          return { id: announcement.id, ...announcement.data() };
        });
      },
      true
    );
  };

  // --- ADMIN ENDPOINTS ---
  const adminPostAnnouncement = async (
    announcement: Announcement
  ): Promise<Announcement> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and get the response
    const newAnnouncement = await self._http<Announcement>(
      'announcements',
      'POST',
      { data: announcement },
      false
    );

    // Update the store with the new announcement
    const storeAnnouncements = self.store.get(
      'firebase-announcementsGetAnnouncements'
    );
    self.store.set('firebase-announcementsGetAnnouncements', [
      ...(storeAnnouncements || []),
      newAnnouncement
    ]);

    return newAnnouncement;
  };

  const adminDeleteAnnouncement = async (
    announcement: string
  ): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Call the firebase function
    await firebase
      .firestore()
      .collection('announcements')
      .doc(announcement)
      .delete();

    // Get the announcements from the store
    const storeAnnouncements = self.store.get(
      `firebase-announcementsGetAnnouncements`
    );

    // Update the store
    if (storeAnnouncements !== undefined) {
      // Get the announcements from the store and find the deleted announcement's index
      const announcementIndex = storeAnnouncements.findIndex(
        (storeItem: Announcement) => storeItem.id === announcement
      );

      // Check whether we actually found the announcement
      if (announcementIndex !== -1) {
        // Remove the deleted announcement
        storeAnnouncements.splice(announcementIndex, 1);

        // Update the store
        self.store.set(
          `firebase-announcementsGetAnnouncements`,
          storeAnnouncements
        );
      }
    }
  };

  // Return the endpoint functions
  return {
    getAnnouncements,
    // ADMIN ENDPOINTS
    adminPostAnnouncement,
    adminDeleteAnnouncement
  };
}

export default Announcements;
