import firebase from 'firebase/app';
import 'firebase/firestore';

// UUID helper for hooks
import { uuid } from 'helpers';

interface Hook {
  id: string;
  snap: (
    snap: firebase.firestore.QuerySnapshot | firebase.firestore.DocumentSnapshot
  ) => void;
  error: (error: firebase.firestore.FirestoreError) => void;
}

class Live {
  _listeners: { [key: string]: () => void };
  _store: {
    [key: string]:
      | firebase.firestore.QuerySnapshot
      | firebase.firestore.DocumentSnapshot;
  };
  _hooks: { [key: string]: Hook[] };

  constructor() {
    // Setup the internal variables
    this._listeners = {};
    this._store = {};
    this._hooks = {};
  }

  _hookSnap(
    name: string,
    snap: firebase.firestore.QuerySnapshot | firebase.firestore.DocumentSnapshot
  ): void {
    // Log the hook snap
    console.debug(`${name} hook snap!`);

    // Call all of the hook functions
    this._store[name] = snap;
    if (this._hooks[name]) this._hooks[name].forEach((hook) => hook.snap(snap));
  }

  _hookError(name: string, error: firebase.firestore.FirestoreError): void {
    // Log the hook snap
    console.error(`${name} hook error!`, error);

    // Call all of the error functions
    if (this._hooks[name])
      this._hooks[name].forEach((hook) => hook.error(error));
  }

  _removeHook(name: string, id: string): void {
    if (Object.values(this._hooks).length > 0) {
      // Find the hook in the array
      const hookIndex = this._hooks[name].findIndex((hook) => hook.id === id);

      // Remove the hook from the array
      if (this._hooks[name]) this._hooks[name].splice(hookIndex, 1);
    }
  }

  listener(
    name: string,
    element: firebase.firestore.DocumentReference | firebase.firestore.Query
  ): void {
    if (element instanceof firebase.firestore.DocumentReference) {
      this._listeners[name] = element.onSnapshot(
        (snap) => this._hookSnap(name, snap),
        (error) => this._hookError(name, error)
      );
    } else if (element instanceof firebase.firestore.Query) {
      this._listeners[name] = element.onSnapshot(
        (snap) => this._hookSnap(name, snap),
        (error) => this._hookError(name, error)
      );
    }

    // Initialize the store and hook arrays
    this._hooks[name] = [];
  }

  unmount(): void {
    // Unsubscribe to all listeners
    Object.values(this._listeners).forEach((listener) => listener());

    // Reset the hooks and store
    this._hooks = {};
    this._store = {};
  }

  hook(
    name: string,
    snap: (
      data:
        | firebase.firestore.QuerySnapshot
        | firebase.firestore.DocumentSnapshot
    ) => void,
    error: (error: firebase.firestore.FirestoreError) => void
  ): () => void {
    // Generate a UID for the hook
    const id = uuid();

    // Ensure that the listener has
    if (!this._hooks[name]) {
      console.warn(`Attempted to hook into non-existent listener (${name})`);
    }

    // Push the callback functions into the listener object
    this._hooks[name].push({ id, snap, error });

    // Send the store data if it exists
    if (this._store[name]) snap(this._store[name]);

    return () => {
      this._removeHook(name, id);
    };
  }

  initial(
    name: string
  ):
    | firebase.firestore.QuerySnapshot
    | firebase.firestore.DocumentSnapshot
    | null {
    // Return the hook's store
    return this._store[name];
  }
}

export default Live;
