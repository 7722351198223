import { PureComponent } from 'react';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Authentication
import { withAuth } from 'services/auth';

import { withRouter } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/firestore';

// Material UI components / helpers
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  withStyles,
  Typography
} from '@material-ui/core';

// Error message helper
import { getErrorMessage } from 'helpers';

// Components for testing
import { Chat, Portlet } from 'components';

// Component styles
import styles from './styles';

class Developer extends PureComponent {
  constructor(props) {
    super(props);

    this.run = this.run.bind(this);

    // Initialize the state
    this.state = {
      data: '',
      customMethod: 'GET',
      customEndpoint: '',
      customBody: ''
    };

    window.firestore = firebase.firestore();
  }

  async run(endpoint, method = 'GET', body = {}, store = false) {
    const { auth, enqueueSnackbar } = this.props;
    try {
      // Show an error snackbar
      enqueueSnackbar(`${method} ${endpoint}`);

      const data = await auth.api.developer.run(endpoint, method, body, store);
      console.log(data);
      return data;
    } catch (error) {
      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });

      // Log the error to the console
      console.error(error);
    }
  }

  render() {
    const { classes, auth, enqueueSnackbar, history } = this.props;
    const { data, customMethod, customEndpoint, customBody } = this.state;

    const testMessages = [
      {
        content: 'hello',
        uid: 'josh',
        when: 0,
        name: 'Josh Person',
        id: 'test'
      },
      {
        content: 'how are you',
        uid: 'jack',
        when: 0,
        name: 'Jack Beringen',
        id: 'test2'
      },
      {
        content: 'you good?',
        uid: 'jack',
        when: 0,
        name: 'Jack Beringen',
        id: 'test3'
      },
      {
        content: 'good thanks!',
        uid: 'josh',
        when: 0,
        name: 'Josh Person',
        id: 'test4'
      },
      {
        content: 'just ate PIZZA my dude',
        uid: 'josh',
        when: 0,
        name: 'Josh Person',
        id: 'test5',
        unsent: true
      }
    ];

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography>{data}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Test Components</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ display: 'flex' }}>
              <Portlet>
                <Chat
                  uid="jack"
                  messages={testMessages}
                  onSend={(message) => console.log(message)}
                  error="An error occured"
                  onRetry={() => console.log('retry')}
                />
              </Portlet>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Calling</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={async () => {
                const room = prompt('Room name');
                if (!room) return;
                const data = await this.run(`call/${room}`, 'POST');
                console.log(data);
                const { token } = data;
                localStorage.setItem(`devTwilioVideoToken-${room}`, token);
                window.open(
                  `/call?token=${token}&room=${room}`,
                  '_blank',
                  'location'
                );
              }}>
              begin test call
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={async () => {
                const room = prompt('Room name');
                if (!room) return;
                let token = localStorage.getItem(`devTwilioVideoToken-${room}`);
                if (!token) {
                  console.log('no token found, retrieving');
                  token = (await this.run(`call/${room}/join`, 'POST')).token;
                  localStorage.setItem(`devTwilioVideoToken-${room}`, token);
                } else {
                  console.log('found token, using');
                }

                window.open(
                  `/call?token=${token}&room=${room}`,
                  '_blank',
                  'location'
                );
              }}>
              join test call
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                localStorage.clear();
                enqueueSnackbar('Cleared local storage');
              }}>
              clear local storage
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            {/* placeholder */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Bookings</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={async () => {
                await this.run(`bookings/clear`, 'POST');
                enqueueSnackbar('Bookings cleared', { variant: 'success' });
              }}
              fullWidth>
              clear bookings
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={async () => {
                await this.run(`onLessonStart`, 'POST');
              }}
              fullWidth>
              on lesson start
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={async () => {
                await this.run(`onLessonEnd`, 'POST');
              }}
              fullWidth>
              on lesson end
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={async () => {
                await this.run(`onLessonReminder`, 'POST');
              }}
              fullWidth>
              on lesson reminder
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Authentication</Typography>
            <Typography>USER: {auth.user.uid}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button variant="contained" color="primary" size="large" fullWidth>
              Copy auth token
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled>
              Copy uid
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            {/* placeholder */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Payments</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                const uid = prompt('Enter UID');
                this.run(`invoices/${uid}`, 'GET');
              }}
              fullWidth>
              get student invoices
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                this.run(`payments/bookings/reset`, 'POST');
              }}
              fullWidth>
              reset status all bookings
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            {/* placeholder */}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            {/* placeholder */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Emails</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                this.run('mail/verify', 'POST');
              }}
              fullWidth>
              send verification email
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                this.run('mail/passreset', 'POST');
              }}
              fullWidth>
              send pass reset email
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            {/* placeholder */}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            {/* placeholder */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">API</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel id="dev-select-method-label">Method</InputLabel>
              <Select
                labelId="dev-select-method-label"
                value={customMethod}
                fullWidth
                onChange={(e) => {
                  this.setState({
                    customMethod: e.target.value
                  });
                }}
                displayEmpty
                className={classes.selectEmpty}>
                <MenuItem value="GET">GET</MenuItem>
                <MenuItem value="POST">POST</MenuItem>
                <MenuItem value="PUT">PUT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant="outlined"
              fullWidth
              value={customEndpoint}
              onChange={(e) => {
                this.setState({
                  customEndpoint: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant="outlined"
              rows={6}
              multiline
              fullWidth
              value={customBody}
              onChange={(e) => {
                this.setState({
                  customBody: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                this.run(
                  customEndpoint,
                  customMethod,
                  customBody === '' ? {} : JSON.parse(customBody)
                );
              }}>
              call endpoint
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3">Other</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                enqueueSnackbar('Testing');
              }}>
              notification
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                history.push('/404');
              }}>
              404 link
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                const uid = prompt('Enter UID');
                const data = prompt('Enter DATA');
                firebase.firestore().doc(`tutors/${uid}`).set(JSON.parse(data));
              }}>
              set profile data
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                const url = prompt('Enter URL');
                firebase.auth().currentUser.updateProfile({ photoURL: url });
              }}>
              set user profile image
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  withSnackbar(withAuth(withStyles(styles)(Developer)))
);
