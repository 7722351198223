export default (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  icon: {
    width: 60,
    height: 60
  },
  dateControls: {
    padding: theme.spacing(2.5)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexDirection: 'row', // Default to row layout
    alignItems: 'center',
    justifyContent: 'space-between', // Add spacing between components
    flexWrap: 'wrap' // Allow items to wrap to the next line
  },
  formControl: {
    maxWidth: '40%', // Set maximum width
    width: '100%' // Set initial width
  },
  select: {
    width: '100%' // Set Select width to 100%
  },
  // paper: {
  //   overflowX: 'auto' // Use overflowX for horizontal scrolling
  // },
  // Media query for smaller screens
  '@media (max-width: 750px)': {
    container: {
      flexDirection: 'column', // Switch to column layout
      alignItems: 'stretch' // Stretch items vertically in column layout
    },
    formControl: {
      width: '100%', // Set width to 100% in column layout
      marginBottom: '10px', // Adjust margin for better spacing
      maxWidth: '100%'
    },
    select: {
      width: '100%'
    },
    button: {
      marginTop: '10px' // Adjust margin as needed
    }
  }
});
