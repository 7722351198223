import red from '@material-ui/core/colors/red';

export default (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  footer: {
    display: 'flex',
    justifyContent: 'center'
  },
  avatar: {
    width: 100,
    height: 100,
    transition: 'opacity 200ms cubic-bezier(0, 1, 1, 1)',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer'
    }
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 0
    }
  },
  rebooking: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(0.6),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      '& .MuiChip-root': {
        marginBottom: theme.spacing(0.6)
      }
    }
  },
  rebookBad: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1)
    }
  },
  cancelledBook: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1)
    },
    '&:hover': {
      backgroundColor: theme.palette.warning.main
    }
  },
  rebookNormal: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1)
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    }
  },
  cancel: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: red[300]
    }
  },
  gutterRight: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1)
    }
  },
  hideOnSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  actions: {
    display: 'flex',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  inelegible: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25)
  }
});
