import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Portlet, PortletContent } from 'components'; // Assuming Portlet and PortletContent are custom components
import { DateTime } from 'luxon'; // Import Luxon DateTime

import styles from './styles';
import { AuthContext } from 'services/auth';
import { getErrorMessage } from 'helpers';

const useStyles = makeStyles(styles);

const DesiredBooking = ({ enqueueSnackbar }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();

  // Initialize state with current day and time using Luxon
  const [scheduleInputs, setScheduleInputs] = useState([{ day: '', time: '' }]);

  // Function to find the closest time from the options
  const findClosestTime = (currentTime) => {
    let closestTime = timeOptions[0].value;
    let closestDiff = Number.MAX_VALUE;

    timeOptions.forEach((option) => {
      const optionDateTime = DateTime.fromFormat(option.value, 'h:mm a');
      const optionMillis = optionDateTime.toMillis();
      const currentMillis = DateTime.fromFormat(
        currentTime,
        'h:mm a'
      ).toMillis();
      const diff = Math.abs(optionMillis - currentMillis);

      if (diff < closestDiff) {
        closestDiff = diff;
        closestTime = option.value;
      }
    });

    return closestTime;
  };

  // Function to add a new day and time input
  const addDayTime = () => {
    const currentDateTime = DateTime.now();
    const currentDay = currentDateTime.toFormat('EEEE');
    const currentTime = currentDateTime.toFormat('h:mm a');
    const closestTime = findClosestTime(currentTime);

    const newInputs = [
      ...scheduleInputs,
      { day: currentDay, time: closestTime }
    ];
    setScheduleInputs(newInputs);
  };

  // Function to handle changes in day or time
  const handleInputChange = (index, name, value) => {
    const newInputs = [...scheduleInputs];
    newInputs[index][name] = value;
    setScheduleInputs(newInputs);
  };

  // Function to remove a day and time input
  const removeDayTime = (index) => {
    const newInputs = [...scheduleInputs];
    newInputs.splice(index, 1);
    setScheduleInputs(newInputs);
  };

  const submitSchedule = async () => {
    enqueueSnackbar(`Submitting schedule...`);

    try {
      // Perform the schedule submission
      await auth.api.bookings.submitSchedule(scheduleInputs, auth.user.uid); // Pass studentId to the function

      // Notify the user of a successful submission
      enqueueSnackbar(`Preferred day and time submitted!`, {
        variant: 'success'
      });

      const currentDateTime = DateTime.now();
      const currentDay = currentDateTime.toFormat('EEEE');
      const currentTime = currentDateTime.toFormat('h:mm a');
      const closestTime = findClosestTime(currentTime);

      const initialInput = {
        day: currentDay,
        time: closestTime
      };
      setScheduleInputs([initialInput]);
    } catch (error) {
      // Notify the user of an error during submission
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      console.error('Failed to submit schedule:', error);
    }
  };

  useEffect(() => {
    // Initialize the first input with current day and time
    const currentDateTime = DateTime.now();
    const currentDay = currentDateTime.toFormat('EEEE');
    const currentTime = currentDateTime.toFormat('h:mm a');
    const closestTime = findClosestTime(currentTime);

    const initialInput = {
      day: currentDay,
      time: closestTime
    };
    setScheduleInputs([initialInput]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Generate options for time selection in AM/PM format
  const timeOptions = [...Array(24 * 2)].map((_, i) => {
    const hour = Math.floor(i / 2);
    const minute = (i % 2) * 30;
    const period = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 === 0 ? 12 : hour % 12;
    const timeValue = `${displayHour}:${
      minute < 10 ? '0' + minute : minute
    } ${period}`;
    return {
      value: timeValue,
      label: timeValue
    };
  });

  return (
    <Portlet>
      <PortletContent>
        <Typography className={classes.title} variant="h4">
          Did you find the booking day and time you wanted? If not, let us know!
        </Typography>
        <Typography className={classes.subtitle}>
          Select your preferred days and times for lessons, and we will inform
          our teachers.
        </Typography>

        {scheduleInputs.map((input, index) => (
          <Grid container spacing={2} alignItems="center" key={index}>
            <Grid item xs={5} md={2}>
              <TextField
                id={`day-${index}`}
                label="Day"
                select
                variant="outlined"
                fullWidth
                value={input.day}
                onChange={(e) =>
                  handleInputChange(index, 'day', e.target.value)
                }>
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
                <MenuItem value="Sunday">Sunday</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={5} md={2}>
              <TextField
                id={`time-${index}`}
                label="Time"
                select
                variant="outlined"
                fullWidth
                value={input.time}
                onChange={(e) =>
                  handleInputChange(index, 'time', e.target.value)
                }>
                {timeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {index > 0 && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => removeDayTime(index)}>
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        ))}

        <Grid>
          <Button
            variant="outlined"
            color="primary"
            className={classes.addDayTimeButton}
            onClick={addDayTime}>
            Add Day and Time
          </Button>
        </Grid>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={submitSchedule}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </PortletContent>
    </Portlet>
  );
};

export default DesiredBooking;
