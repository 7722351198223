import {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext
} from 'react';

// Type definitions for twilio video
import { LocalTrack, LocalVideoTrack, LocalAudioTrack } from 'twilio-video';

// Material UI animations
import {
  Paper,
  CircularProgress,
  Fab,
  Avatar,
  Typography,
  Grow
} from '@material-ui/core';

// Material UI Icons
import {
  MicOffRounded,
  MicRounded,
  VideocamOffRounded,
  VideocamRounded
} from '@mui/icons-material';

// Auth context
import { AuthContext } from 'services/auth';

// Shared helpers
import { getInitials } from 'helpers';

// Style
import useStyles from './styles';
import AppStateProvider from 'state';
import AudioInputList from 'components/AudioList/AudioInputList/AudioInputList';
import AudioOutputList from 'components/AudioList/AudioOutputList/AudioOutputList';

export const LocalPreview = ({
  previewTracks
}: {
  previewTracks: LocalTrack[] | undefined;
}): ReactElement => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [update, forceUpdate] = useState<boolean>(false);

  // Generate component styles & get auth context
  const classes = useStyles();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (previewTracks) {
      const videoTrack = previewTracks?.find(
        (track) => track.kind === 'video'
      ) as LocalVideoTrack;
      const audioTrack = previewTracks?.find(
        (track) => track.kind === 'audio'
      ) as LocalAudioTrack;

      if (videoRef.current && videoTrack) videoTrack.attach(videoRef.current);
      if (audioRef.current && audioTrack) audioTrack.attach(audioRef.current);

      return () => {
        if (videoTrack) videoTrack.detach();
        if (audioTrack) audioTrack.detach();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewTracks]);

  const localVideoTrack = previewTracks?.find(
    (track) => track.kind === 'video'
  ) as LocalVideoTrack;
  const localAudioTrack = previewTracks?.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioTrack;

  const toggleVideo = useCallback(() => {
    if (localVideoTrack) {
      localVideoTrack.isEnabled
        ? localVideoTrack.disable()
        : localVideoTrack.enable();
      forceUpdate(!update);
    }
  }, [localVideoTrack, update]);

  const toggleAudio = useCallback(() => {
    if (localAudioTrack) {
      localAudioTrack.isEnabled
        ? localAudioTrack.disable()
        : localAudioTrack.enable();
      forceUpdate(!update);
    }
  }, [localAudioTrack, update]);

  return (
    <div className={classes.root}>
      <Paper className={classes.videoRoot} elevation={1}>
        {previewTracks ? (
          <>
            <video
              hidden={!localVideoTrack?.isEnabled}
              className={classes.video}
              ref={videoRef}
              autoPlay={true}
            />
            <div
              style={{ display: localVideoTrack?.isEnabled ? 'none' : 'flex' }}
              className={classes.loader}>
              <Avatar className={classes.avatar}>
                {getInitials(auth?.user?.displayName || '?')}
              </Avatar>
              <Typography variant="h3">
                {auth?.user?.displayName || '?'}
              </Typography>
            </div>
          </>
        ) : (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        <audio ref={audioRef} autoPlay={true} muted={true} />
        <div className={classes.content}>
          <Grow in={!localAudioTrack?.isEnabled}>
            <div className={classes.indicator}>
              <MicOffRounded />
            </div>
          </Grow>
        </div>
      </Paper>
      <div className={classes.controls} style={{ marginTop: 22 }}>
        <Fab
          onClick={toggleAudio}
          size="medium"
          color="primary"
          disabled={!localAudioTrack}>
          {localAudioTrack?.isEnabled ? <MicRounded /> : <MicOffRounded />}
        </Fab>
        <Fab
          onClick={toggleVideo}
          size="medium"
          color="secondary"
          disabled={!localVideoTrack}>
          {localVideoTrack?.isEnabled ? (
            <VideocamRounded />
          ) : (
            <VideocamOffRounded />
          )}
        </Fab>
      </div>
      <AudioInputList previewTracks={previewTracks} />
      <AppStateProvider>
        <AudioOutputList />
      </AppStateProvider>
    </div>
  );
};
