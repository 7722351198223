import { colors } from '@material-ui/core';

import { white } from '../../common/colors';

export default {
  type: 'light',
  common: {
    white,
    neutral: '#E4E7EB'
  },
  primary: {
    main: '#0767DB',
    light: 'rgba(7, 103, 219, 0.08)',
    dark: '#0B48A0'
  },
  secondary: {
    main: '#7d58ff',
    light: 'rgba(125, 88, 255, 0.08)',
    dark: '#37248F'
  },
  text: {
    primary: colors.blueGrey[800],
    secondary: colors.blueGrey[600],
    disabled: '#A6B1BB'
  },
  background: {
    default: '#f8fafc',
    dark: '#e6e9ed',
    paper: white
  }
};
