export default (theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2)
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  header: {
    marginBottom: theme.spacing(1.5)
  },
  adornment: {
    marginRight: theme.spacing(3)
  }
});
