import { PureComponent } from 'react';

// Material UI imports
import { Typography } from '@material-ui/core';

// DateTime helpers
import { DateTime } from 'luxon';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';

class AnnouncementDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false
    };

    // Bind component functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.close
    };
  }

  // Create a open function to hook into in
  // the parent component
  open(announcement) {
    this.setState({
      open: true,
      announcement
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({
      open: false
    });
  }

  render() {
    // Get the announcement from the state
    const { announcement, open } = this.state;

    // Return the announcement dialog
    return (
      <ResponsiveDialog
        title={announcement ? announcement.title : ''}
        subtitle={`Posted by ${
          announcement && announcement.from
        } ${DateTime.fromMillis(
          announcement ? announcement.when : 0
        ).toRelative()}`}
        events={this.events}
        open={open}
        hideCancelButton
        confirmText="ok">
        <Typography variant="subtitle1">
          {announcement ? announcement.message : ''}
        </Typography>
        <br />
      </ResponsiveDialog>
    );
  }
}

export default AnnouncementDialog;
