export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    height: 400
  },
  messages: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    maxHeight: 'calc(100% - 60px)',
    overflowY: 'auto'
  },
  actions: {
    margin: theme.spacing(2)
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 'calc(100% - 60px)',
    marginBottom: theme.spacing(2)
  },
  retryButton: {
    marginTop: theme.spacing(2)
  }
});
