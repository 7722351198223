import API from '.';

interface StudentRegister {
  email: string;
  password: string;
  displayName: string;
  about?: string;
  country?: string;
}

interface APIAuth {
  /**
   * API endpoint for student sign-up
   */
  register: (student: StudentRegister) => Promise<void>;
  /**
   * API endpoint for account verification
   */
  verify: () => Promise<void>;
  /**
   * API endpoint for password resetting
   */
  reset: (email: string) => Promise<void>;
}

function Auth(self: API): APIAuth {
  const register = async (student: StudentRegister): Promise<void> => {
    // Check whether we're the right role before making the request
    if (self.credentials !== null)
      throw new Error('You cannot create an account whilst logged in!');

    // Run the HTTP function
    return self._http<void>('auth/register', 'POST', { data: student }, false);
  };

  const verify = async (): Promise<void> => {
    // Check whether we're the right role before making the request
    if (self.credentials === null)
      throw new Error('You must be logged in to verify your email!');

    // Run the HTTP function
    return self._http<void>('auth/verify', 'POST', {}, false);
  };

  const reset = async (email: string): Promise<void> => {
    // Run the HTTP function
    return self._http<void>('auth/reset', 'POST', { data: { email } }, false);
  };

  // Return the endpoint functions
  return {
    register,
    verify,
    reset
  };
}

export default Auth;
