import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';

interface Progress {
  [key: string]: string[];
}

// interface Certificate {
//   url: string;
// }
interface Certificate {
  categoryName: string;
  studentName: string;
  courseName: string;
  name: string;
  courseAbbreviation: string;
  courseDescription: string;
  lessons: any[]; // Define the type for lessons as needed
}

interface APICertificates {
  getProgress: (material: string, store: boolean) => Promise<any>;
  // generateCertificate: () => Promise<Certificate>;
  generateCertificates: (uid: string) => Promise<Certificate[]>;
}

function Certificates(self: API): APICertificates {
  /*
    API endpoint for listing applications
  */
  const getProgress = async (
    material: string,
    store = false
  ): Promise<Progress> => {
    // Check whether we're the right role before making the request
    self.enforceRole('student');

    // Retrieve the data from firebase
    return self._firebase<Progress>(
      'certificatesGetProgress',
      async () => {
        return (
          await firebase
            .firestore()
            .collection('students')
            .doc(firebase.auth().currentUser?.uid)
            .collection('progress')
            .doc(material)
            .get()
        ).data();
      },
      store
    );
  };

  /*
    API endpoint for generating a certificate
  */
  // const generateCertificate = async (): Promise<Certificate> => {
  //   // Make the request and return the response
  //   return self._http<Certificate>(
  //     `certificates/${firebase.auth().currentUser?.uid}`,
  //     'GET',
  //     {},
  //     false
  //   );
  // };

  // const generateCertificates = async (uid: string): Promise<Certificate[]> => {
  //   try {
  //     const newCertificates: Certificate[] = [];
  //     // Fetch student's name from Firestore
  //     const studentDoc = await firebase
  //       .firestore()
  //       .collection('students')
  //       .doc(uid)
  //       .get();
  //     const studentData = studentDoc.data();
  //     const studentName = studentData?.profile.name || ''; // Assuming the student's name is stored under the 'name' field

  //     const progressRef = firebase
  //       .firestore()
  //       .collection('students')
  //       .doc(uid)
  //       .collection('progress');

  //     const progressSnapshot = await progressRef.get();

  //     for (const progressDoc of progressSnapshot.docs) {
  //       const categoryName = progressDoc.id;
  //       const categoryProgress = progressDoc.data();

  //       const categoryDoc = await firebase
  //         .firestore()
  //         .collection('materials')
  //         .doc(categoryName)
  //         .get();

  //       if (!categoryDoc.exists) {
  //         console.error(`Category document '${categoryName}' not found.`);
  //         continue; // Skip this category if document doesn't exist
  //       }

  //       const categoryData = categoryDoc.data();

  //       // Check if categoryData is undefined
  //       if (!categoryData) {
  //         console.error(
  //           `Category data not found for document '${categoryName}'.`
  //         );
  //         continue; // Skip this category if data is undefined
  //       }

  //       // Now you can safely access categoryData.name
  //       const categoryNameFromDoc = categoryData.name;
  //       const categoryCoursesSnapshot = await firebase
  //         .firestore()
  //         .collection('materials')
  //         .doc(categoryName)
  //         .collection('courses')
  //         .get();

  //       for (const courseDoc of categoryCoursesSnapshot.docs) {
  //         const courseName = courseDoc.id;
  //         const courseData = courseDoc.data();

  //         // Extract course information
  //         const { name, abbreviation, description, lessons } = courseData;

  //         const lessonsArray = lessons;

  //         // Check completion status for each lesson
  //         // Iterate over lessonsArray to maintain the order of lessons
  //         const completedLessonObjects = lessonsArray
  //           .map((lesson: any) => {
  //             const lessonId = lesson.id;
  //             const lessonInfo = categoryProgress[courseName][lessonId];
  //             if (lessonInfo) {
  //               // Merge the completion details with the lesson object
  //               return { ...lesson, completionDetails: lessonInfo };
  //             } else {
  //               return null;
  //             }
  //           })
  //           .filter(Boolean);

  //         const completedLessonsArray: Certificate = {
  //           categoryName: categoryName,
  //           studentName: studentName,
  //           courseName: name,
  //           name: categoryNameFromDoc,
  //           courseAbbreviation: abbreviation,
  //           courseDescription: description,
  //           lessons: completedLessonObjects
  //         };

  //         // if (completedLessonObjects.length === lessonsArray.length) {
  //         newCertificates.push(completedLessonsArray);
  //         // } else {
  //         //   console.log('not completed');
  //         // }
  //       }
  //     }

  //     // Once all certificates are generated, you can handle them as needed
  //     // console.log('Generated certificates:', newCertificates);
  //     // Return the generated certificates
  //     return newCertificates;
  //   } catch (error) {
  //     console.error('Error generating certificates:', error);
  //     return [];
  //   }
  // };
  const generateCertificates = async (uid: string): Promise<Certificate[]> => {
    try {
      // Retrieve the data from firebase using the _firebase method
      self.enforceRole('student');
      return self._firebase<Certificate[]>(
        'certificatesGenerate',
        async () => {
          // Check whether we're the right role before making the request
          const newCertificates: Certificate[] = [];

          // Fetch student's name from Firestore
          const studentDoc = await firebase
            .firestore()
            .collection('students')
            .doc(uid)
            .get();
          const studentData = studentDoc.data();
          const studentName = studentData?.profile.name || '';

          const progressRef = firebase
            .firestore()
            .collection('students')
            .doc(uid)
            .collection('progress');

          const progressSnapshot = await progressRef.get();

          for (const progressDoc of progressSnapshot.docs) {
            const categoryName = progressDoc.id;
            const categoryProgress = progressDoc.data();

            const categoryDoc = await firebase
              .firestore()
              .collection('materials')
              .doc(categoryName)
              .get();

            if (!categoryDoc.exists) {
              console.error(`Category document '${categoryName}' not found.`);
              continue; // Skip this category if document doesn't exist
            }

            const categoryData = categoryDoc.data();

            // Check if categoryData is undefined
            if (!categoryData) {
              console.error(
                `Category data not found for document '${categoryName}'.`
              );
              continue; // Skip this category if data is undefined
            }

            // Now you can safely access categoryData.name
            const categoryNameFromDoc = categoryData.name;
            const categoryCoursesSnapshot = await firebase
              .firestore()
              .collection('materials')
              .doc(categoryName)
              .collection('courses')
              .get();

            for (const courseDoc of categoryCoursesSnapshot.docs) {
              const courseName = courseDoc.id;
              const courseData = courseDoc.data();

              // Extract course information
              const { name, abbreviation, description, lessons } = courseData;

              const lessonsArray = lessons;

              // Check completion status for each lesson
              // Iterate over lessonsArray to maintain the order of lessons
              const completedLessonObjects = lessonsArray
                .map((lesson: any) => {
                  const lessonId = lesson.id;
                  const lessonInfo = categoryProgress[courseName][lessonId];
                  if (lessonInfo) {
                    // Merge the completion details with the lesson object
                    return { ...lesson, completionDetails: lessonInfo };
                  } else {
                    return null;
                  }
                })
                .filter(Boolean);

              const completedLessonsArray: Certificate = {
                categoryName: categoryName,
                studentName: studentName,
                courseName: name,
                name: categoryNameFromDoc,
                courseAbbreviation: abbreviation,
                courseDescription: description,
                lessons: completedLessonObjects
              };

              newCertificates.push(completedLessonsArray);
            }
          }

          // Return the generated certificates
          return newCertificates;
        },
        /* Specify whether to store the result based on your requirements */
        false // Example: Don't store the result
      );
    } catch (error) {
      console.error('Error generating certificates:', error);
      return [];
    }
  };

  // Return the endpoint functions
  return {
    getProgress,
    // generateCertificate,
    generateCertificates
  };
}

export default Certificates;
