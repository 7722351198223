export default (theme) => ({
  root: {
    // minHeight: '200px'
  },
  fullList: {
    flexGrow: 1
  },
  refreshButton: {
    margin: -theme.spacing(2)
  },
  content: {
    display: 'flex',
    flexGrow: 1
  },
  loadingInner: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '& .MuiSkeleton-text': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    }
  },
  skeletonRight: {
    marginLeft: theme.spacing(1.5),
    borderRadius: '19.2px'
  },
  live: {
    color: theme.palette.error.main
  },
  btnBookNow: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16
  }
});
