import { PureComponent } from 'react';

// Material UI imports
import { Typography, withStyles } from '@material-ui/core';

// Material Icons
import { TodayRounded as TodayIcon } from '@mui/icons-material';

// DateTime helpers
import { DateTime } from 'luxon';

// Portlet items
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
  // PortletFooter
} from 'components';

// Style
import styles from './styles';

class OverviewCard extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      time: DateTime.local()
    };
  }

  componentDidMount() {
    // Create a second interval that updates the clock on the card
    this.timeIntervalHandler = setInterval(() => {
      // Update the state with the new DateTime object
      this.setState({
        time: DateTime.fromMillis(Date.now())
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeIntervalHandler);
  }

  render() {
    // Grab the auth provider and classes from the component properties.
    const { auth, classes } = this.props;
    const { time } = this.state;

    // Get the formatted current time
    const timeFormatted = time.toLocaleString(
      DateTime.DATETIME_SHORT_WITH_SECONDS
    );

    // Grab the user's details
    let { displayName } = auth.user;

    // Make sure the user has set a display name.
    if (displayName !== null) {
      displayName = `, ${displayName.split(' ')[0]}`;
    } else {
      // If they haven't, return an empty string/
      displayName = '';
    }

    return (
      <Portlet>
        <PortletHeader>
          <PortletLabel
            icon={<TodayIcon />}
            title={timeFormatted}
            subtitle={time.offsetNameShort}
          />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <Typography variant="h4">{`Welcome back${displayName}!`}</Typography>
        </PortletContent>
      </Portlet>
    );
  }
}

export default withStyles(styles)(OverviewCard);
