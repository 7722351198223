export default (theme) => ({
  loadingInner: {
    flexGrow: 1
  },
  fullList: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  content: {
    display: 'flex'
  },
  skeletonWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  skeletonTop: {
    marginTop: theme.spacing(2.0),
    marginBottom: theme.spacing(0),
    height: 6,

    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  skeletonBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.4),
    height: 6,

    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});
