// Externals
import classNames from 'classnames';

// Material helpers
import { Typography, Avatar, withStyles } from '@material-ui/core';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

function MaterialsCard(props) {
  const { classes, className, material, loading } = props;

  const rootClassName = classNames(classes.root, className);

  const handleClick = () => {
    if (typeof material.dlink != 'undefined') window.open(material.dlink);
  };

  return (
    <Portlet className={rootClassName} onClick={handleClick}>
      <PortletContent className={classes.content} noPadding>
        <div className={classes.image}>
          {loading ? (
            <Skeleton variant="circle">
              <Avatar className={classes.icon} />
            </Skeleton>
          ) : (
            <Avatar
              alt="Topic Icon"
              className={classes.icon}
              src={material.icon}
            />
          )}
        </div>
        <div className={material?.title ? classes.container : classes.details}>
          <Typography variant="h4">
            {loading ? <Skeleton width="50%" /> : material.name}
          </Typography>
          <Typography className={classes.desc} variant="body1">
            {loading ? <Skeleton width="70%" /> : material.description}
          </Typography>
          {material?.title ? (
            <Typography variant="h5">
              {loading ? <Skeleton width="50%" /> : material.title}
            </Typography>
          ) : null}
        </div>
      </PortletContent>
    </Portlet>
  );
}

export default withStyles(styles)(MaterialsCard);
