import { PureComponent } from 'react';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Material UI components / helpers
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  withStyles
} from '@material-ui/core';

// Material UI icons
import { RefreshRounded as ReloadIcon } from '@mui/icons-material';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Authentication
import { withAuth } from 'services/auth';

// DateTime helper
import { DateTime } from 'luxon';

// Component styles
import styles from './styles';

class FeedbackInfo extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      bookings: undefined
    };

    // Bind the necessary functions
    this.retryAPICall = this.retryAPICall.bind(this);

    // Set the ready flag to true
    this.ready = true;
  }

  async componentDidMount(store = true) {
    // Grab the materials endpoint from the props
    const { auth, student } = this.props;

    // Wrap in a try-catch to handle any errors
    try {
      // Retrieve the materials from the API
      const bookings = await auth.api.bookings.getFeedbackHistory(
        student,
        store
      );

      // Update the state with the materials
      if (this.ready) {
        this.setState({
          bookings
        });
      }
    } catch (error) {
      // If we're mounted, update the state with the error
      if (this.ready) {
        this.setState({
          error: getErrorMessage(error)
        });
      }
    }
  }

  componentWillUnmount() {
    // Update the ready flag to false
    this.ready = false;
  }

  retryAPICall() {
    // Reset the state
    this.setState({
      bookings: undefined
    });

    // Call componentDidMount to call the API again.
    this.componentDidMount(false);
  }

  render() {
    // Destructure the props, state
    const { classes, onViewFeedback } = this.props;
    const { bookings, error } = this.state;

    // Ensure any errors have not occured
    if (error)
      return (
        <Typography variant="subtitle2" color="textSecondary">
          {error}
        </Typography>
      );

    // Render a skeleton list if we're waiting on the bookings
    if (!bookings) {
      return (
        <List
          className={classes.list}
          subheader={
            <ListSubheader
              className={classes.subheader}
              disableGutters
              component="div">
              Feedback History{' '}
              <IconButton disabled className={classes.btnReload}>
                <ReloadIcon />
              </IconButton>
            </ListSubheader>
          }>
          {[0, 1].map((index) => (
            <ListItem key={index}>
              <ListItemText>
                <Skeleton width="100%" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
    }

    // Render the booking information
    return (
      <List
        className={classes.list}
        subheader={
          <ListSubheader
            className={classes.subheader}
            disableGutters
            component="div">
            Feedback History{' '}
            <IconButton
              className={classes.btnReload}
              onClick={this.retryAPICall}>
              <ReloadIcon />
            </IconButton>
          </ListSubheader>
        }>
        {bookings.length === 0 && (
          <ListItem disabled>
            <ListItemText>No recorded feedback</ListItemText>
          </ListItem>
        )}
        {bookings.map((booking) => (
          <ListItem
            key={booking.id}
            onClick={() => {
              onViewFeedback(booking);
            }}
            button>
            <ListItemText
              primary={DateTime.fromMillis(booking.when).toFormat(
                'd LLL h:mm a'
              )}
            />
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withAuth(withStyles(styles)(FeedbackInfo));
