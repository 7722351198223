// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, Typography } from '@material-ui/core';

// Shared components
// import { SearchInput } from 'components';

// Component styles
import styles from './styles';

function MaterialsToolbar(props) {
  // const { classes, className, onLevelChange, onSearch } = this.props;
  const { classes, className /*, onSearch*/, isStudentSubscribed } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography className={classes.title} variant="h2">
        {!isStudentSubscribed ? 'Sample Lesson Materials' : 'Lesson Materials'}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {!isStudentSubscribed
          ? 'Please buy an e-English subscription to get full access to all the learning materials'
          : 'Search below to find materials covering a range of industries!'}
      </Typography>
      {/* <div className={`${classes.row} ${classes.end}`}>
        <SearchInput className={classes.searchInput} onChange={onSearch} placeholder="Search - E.G. Cars" />
      </div> */}
    </div>
  );
}

export default withStyles(styles)(MaterialsToolbar);
