import API from '.';

interface APIDeveloper {
  run: <T = any>(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: object,
    store: boolean
  ) => Promise<T>;
}

function Developer(self: API): APIDeveloper {
  const run = async <T = any>(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    data = {},
    store = false
  ) => {
    return self._http<T>(endpoint, method, { data }, store, true);
  };

  // Return the endpoint functions
  return {
    run
  };
}

export default Developer;
