import { ReactElement, useEffect, useRef } from 'react';

// Twilio video imports
import { LocalAudioTrack } from 'twilio-video';

// Material UI theme
import { useTheme, makeStyles } from '@material-ui/core';

// Material UI icons
import { MicRounded } from '@mui/icons-material';

// Poll audio level
import pollAudioLevel from './pollAudioLevel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  canvas: {
    backgroundColor: theme.palette.primary.dark,
    flexGrow: 1,
    height: 5,
    borderRadius: 2.5
  },
  canvasMuted: {
    backgroundColor: theme.palette.error.dark,
    flexGrow: 1,
    height: 5,
    borderRadius: 2.5
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

function AudioMeter({
  audioTrack
}: {
  audioTrack: LocalAudioTrack | null;
}): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    let currentLevel = 0;
    let targetLevel = 0;
    let nextRender = true;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    function draw() {
      if (canvas && ctx) {
        currentLevel += (targetLevel - currentLevel) * 0.05;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle =
          audioTrack && audioTrack.isEnabled
            ? theme.palette.primary.main
            : theme.palette.error.main;
        ctx.fillRect(0, 0, canvas.width * (currentLevel / 100), canvas.height);
      }

      if (nextRender) requestAnimationFrame(draw);
    }

    draw();

    pollAudioLevel(audioTrack, (level: number) => {
      targetLevel = level * 10;
    });

    return () => {
      nextRender = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTrack]);

  return (
    <div className={classes.root}>
      <MicRounded className={classes.icon} />
      <canvas
        className={
          audioTrack && audioTrack.isEnabled
            ? classes.canvas
            : classes.canvasMuted
        }
        ref={canvasRef}
      />
    </div>
  );
}

export default AudioMeter;
