import { PureComponent } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';

// Authentication provider
import { withAuth } from 'services/auth';

// Material UI items
import {
  Grid,
  Grow,
  Button,
  TextField,
  Link,
  Typography,
  withStyles,
  CircularProgress
} from '@material-ui/core';

// Cleanup exports
import compose from 'recompose/compose';

// Material icons
// import { Facebook as FacebookIcon, Google as GoogleIcon, Twitter as TwitterIcon } from 'assets/icons';

// Login schema
import schema from './schema';

// Import the component's styles
import styles from './styles';

class SignIn extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    };

    // Bind state-updating functions
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hasError = this.hasError.bind(this);
    this.authSignIn = this.authSignIn.bind(this);
    console.log('build 3.13');
  }

  handleChange(event) {
    // Get the values from the state
    const { values, touched } = this.state;

    // Get the newly changed values
    const newValues = {
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };

    // Validate the state's values
    const errors = validate(newValues, schema);

    // Update the state
    this.setState({
      values: newValues,
      touched: {
        ...touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    });
  }

  handleSubmit(event) {
    // Cancell the default action
    event.preventDefault();

    // Grab the values from the state
    const { values, isValid } = this.state;

    // If the form is valid, sign in
    if (isValid) {
      this.authSignIn(['email', values.email, values.password]);
    }
  }

  hasError(field) {
    // Get the errors from the state
    const { touched, errors } = this.state;
    return !!(touched[field] && errors[field]);
  }

  async authSignIn(provider) {
    // Grab the auth provider from the props
    const { auth } = this.props;

    // Login and get the outcome
    await auth.signIn(provider);
  }

  render() {
    // Get the values and errors from the state
    const { values, errors, isValid } = this.state;

    // Grab the classes from the props
    const { classes, auth } = this.props;

    // If we're waiting on the initial auth object,
    // just return a spinner
    if (auth.hasLoaded === false)
      return (
        <div className={classes.loading}>
          <img
            alt="e-English logo"
            className={classes.logo}
            src="/images/logos/new-logo.png"
          />
          <div>
            <CircularProgress />
          </div>
        </div>
      );

    // Render the sign in component
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <div className={classes.formHeader}>
                    <div>
                      <Typography variant="h2">Sign in</Typography>
                      <Typography
                        className={classes.subtitle}
                        color="textSecondary"
                        gutterBottom>
                        Sign in to e-English, or register with the links below.
                      </Typography>
                    </div>
                    <span className={classes.spacer} />
                    <Grow in={auth.user === undefined}>
                      <div>
                        <CircularProgress />
                      </div>
                    </Grow>
                  </div>
                  {auth.authError !== null ? (
                    <Typography
                      variant="subtitle2"
                      className={classes.errorText}>
                      {auth.authError}
                    </Typography>
                  ) : null}
                  {/* <Grid className={classes.socialButtons} container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.authSignIn(['facebook']);
                        }}
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={auth.user === undefined}
                      >
                        <FacebookIcon className={classes.socialIcon} />
                        Login with Facebook
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Button
                        onClick={() => {
                          this.authSignIn(['google']);
                        }}
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={auth.user === undefined}
                      >
                        <GoogleIcon className={classes.socialIcon} />
                        Login with Google
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        className={classes.twitterButton}
                        onClick={() => {
                          this.authSignIn(['twitter']);
                        }}
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={auth.user === undefined || true}
                      >
                        <TwitterIcon className={classes.socialIcon} />
                        Login with Twitter
                      </Button>
                    </Grid>
                  </Grid> */}
                  {/* <Typography align="center" className={classes.sugestion} color="textSecondary" variant="body1">
                    or use your email address
                  </Typography> */}
                  <TextField
                    className={classes.textField}
                    error={this.hasError('email')}
                    fullWidth
                    helperText={this.hasError('email') ? errors.email[0] : null}
                    label="Email address"
                    name="email"
                    onChange={this.handleChange}
                    type="text"
                    value={values.email || ''}
                    variant="outlined"
                    disabled={auth.user === undefined}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('password')}
                    fullWidth
                    helperText={
                      this.hasError('password') ? errors.password[0] : null
                    }
                    label="Password"
                    name="password"
                    onChange={this.handleChange}
                    type="password"
                    value={values.password || ''}
                    variant="outlined"
                    disabled={auth.user === undefined}
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!isValid || auth.user === undefined}
                    fullWidth
                    size="large"
                    variant="contained"
                    type="submit"
                    onClick={this.handleSubmit}>
                    Sign in
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    You can also:&nbsp;
                    <Link component={RouterLink} to="/signup" variant="h6">
                      Sign up
                    </Link>
                    ,&nbsp;
                    <Link component={RouterLink} to="/reset" variant="h6">
                      Reset Password
                    </Link>
                    &nbsp;or goto&nbsp;
                    <Link component={RouterLink} to="/application" variant="h6">
                      Teacher Application
                    </Link>
                  </Typography>
                  <br />
                </form>
              </div>
            </div>
          </Grid>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Welcome to e-English!
                </Typography>
                <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant="body1"
                    color="textSecondary">
                    e-English Team
                  </Typography>
                  {/* <Typography className={classes.bio} variant="body2">
                    The e-English Team
                  </Typography> */}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(withAuth, withRouter, withStyles(styles))(SignIn);
