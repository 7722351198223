import { Children, ReactElement, Ref, forwardRef } from 'react';

// Material helpers
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grow,
  Avatar,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';

// Transition props
import { TransitionProps } from '@material-ui/core/transitions';

// Define the dialog styles
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: theme.spacing(1.5)
  },
  avatar: {
    marginRight: theme.spacing(2.5),
    width: 60,
    height: 60
  },
  subtitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  red: {
    color: theme.palette.error.main
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: 900
  }
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  title?: string;
  subtitle?: string;
  children: ReactElement;
  events: any;
  confirmRed?: boolean;
  confirmDisabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  headerAvatar?: string;
  hideCancelButton?: boolean;
  close?: boolean;
  resendButton?: React.ReactNode;
}

function ResponsiveDialog(props: Props): ReactElement {
  // Get values from component props
  const {
    open,
    title,
    subtitle,
    resendButton,
    children,
    events,
    confirmRed,
    confirmDisabled,
    confirmText,
    cancelText,
    headerAvatar,
    hideCancelButton,
    close
  } = props;

  const theme = useTheme();
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
      open={open}
      onClose={events ? events.close : close}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth>
      <div className={classes.header}>
        {headerAvatar && (
          <Avatar className={classes.avatar} src={headerAvatar} />
        )}
        <div
          style={{
            width: '100%'
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography variant="h3">{title}</Typography>
            {resendButton ? resendButton : null}
          </div>
          {subtitle ? (
            <>
              <Typography className={classes.subtitle} variant="subtitle2">
                {subtitle}
              </Typography>
            </>
          ) : null}
        </div>
      </div>
      <DialogContent className={classes.content}>
        {Children.toArray(children)}
      </DialogContent>
      <DialogActions>
        {!hideCancelButton && (
          <Button onClick={events.close}>{cancelText || 'cancel'}</Button>
        )}
        <Button
          onClick={events ? events.confirm : close}
          color="primary"
          disabled={confirmDisabled || false}
          className={confirmRed ? classes.red : ''}>
          {confirmText || 'confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResponsiveDialog;
