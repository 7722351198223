// Component styles

// Get info about levels helper
import { getMaterialLevelInfo } from 'services/materials';

// MUI components
import { Chip, Typography, withStyles } from '@material-ui/core';
import styles from './styles';

function ChipRange(props) {
  const { classes, min, max } = props;

  // Get the level information
  const minLevelInfo = getMaterialLevelInfo(min);
  const maxLevelInfo = getMaterialLevelInfo(max);

  return (
    <div className={classes.root}>
      <Chip
        label={min}
        style={{
          backgroundColor: minLevelInfo.color,
          color: 'white'
        }}
        size="small"
      />
      <Typography className={classes.rangeText} variant="body2">
        {minLevelInfo.name}
      </Typography>
      <Typography className={classes.rangeSpacer}>-</Typography>
      <Chip
        label={max}
        style={{
          backgroundColor: maxLevelInfo.color,
          color: 'white'
        }}
        size="small"
      />
      <Typography className={classes.rangeText} variant="body2">
        {maxLevelInfo.name}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(ChipRange);
