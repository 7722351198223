import { ReactNode, FC, CSSProperties } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles, createStyles } from '@material-ui/core';

// Component styles
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      overflowY: 'auto',
      flexGrow: 1
    },
    noPadding: {
      padding: 0
    }
  })
);

interface Props {
  className?: string;
  children?: ReactNode;
  noPadding?: boolean;
  style?: CSSProperties;
}

const PortletContent: FC<Props> = (props) => {
  const { className, children, style, noPadding, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes?.root]: true,
      [classes.noPadding]: noPadding
    },
    className
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} style={style} className={rootClassName}>
      {children}
    </div>
  );
};

export default PortletContent;
