import { PureComponent, createRef } from 'react';

// Material UI imports
import {
  LinearProgress,
  Typography,
  Button,
  Zoom,
  withStyles
} from '@material-ui/core';

// Import firebase upload functionality
import firebase from 'firebase/app';
import 'firebase/storage';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Style
import styles from './styles';

class Upload extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      progress: 0,
      uploading: false,
      file: null,
      error: null
    };

    // Bind state-manipulating functions
    this.onInputChange = this.onInputChange.bind(this);

    // Create a reference to the file input
    this.inputRef = createRef();
  }

  async onInputChange() {
    const { path, maxSize, onUploadSuccess } = this.props;

    // Ensure that files were selected
    if (this.inputRef.current.files.length > 0) {
      const fileUpload = this.inputRef.current.files[0];

      if (typeof maxSize === 'number' && fileUpload.size > maxSize) {
        // Update the state to show the error
        this.setState({
          progress: 0,
          error: 'File is too large',
          uploading: false
        });
      }

      // Update the state
      this.setState(
        {
          progress: 0,
          uploading: true,
          file: fileUpload,
          error: null
        },
        () => {
          // Create a new upload task
          const uploadTask = firebase
            .storage()
            .ref(`${path}/${fileUpload.name}`)
            .put(fileUpload);

          // Listen for state changes
          const unsubscribeUpload = uploadTask.on(
            'state_changed',
            (snap) => {
              // Update the state with the upload progress
              this.setState({
                progress: Math.round(
                  (snap.bytesTransferred / snap.totalBytes) * 100
                )
              });
            },
            (error) => {
              // Log the error to the console and unsubscribe from the upload events
              console.error(error);
              unsubscribeUpload();

              // Update the state to show the error
              this.setState({
                progress: 0,
                error: getErrorMessage(error),
                uploading: false
              });
            },
            () => {
              // Update the state
              this.setState({
                progress: 100,
                uploading: false
              });

              // Unsubscribe from the upload listener
              unsubscribeUpload();

              // Call the onUploadSuccess function
              if (onUploadSuccess)
                onUploadSuccess(`${path}/${fileUpload.name}`);
            }
          );
        }
      );
    }
  }

  render() {
    // Grab the classes from the component's props
    const {
      classes,
      path,
      label,
      accept,
      disabled,
      uploadText,
      customizedStyle,
      useLabelOnComplete /* , multiple */,
      customizedText,
      bachelorDegreeCertificateText
    } = this.props;

    // Grab the state values
    const { uploading, progress, file, error } = this.state;

    return (
      <div className={classes.content}>
        <div className={classes.info}>
          <div
            style={{
              width: customizedStyle && customizedText ? '40%' : 'auto'
            }}>
            <Button
              className={classes.uploadButton}
              variant="contained"
              component="label"
              color="primary"
              disabled={uploading || !path || disabled}>
              {uploading
                ? 'Uploading'
                : error
                ? 'Retry'
                : uploadText || 'Select File'}
              <input
                ref={this.inputRef}
                multiple={false}
                accept={accept || '*'}
                type="file"
                style={{ display: 'none' }}
                onChange={this.onInputChange}
              />
            </Button>
          </div>
          <div>
            <Typography variant="subtitle2" className={classes.label}>
              {(() => {
                if (!path) return 'No upload path specified!';
                if (error !== null) return error;

                // Labels for the uploading text
                if (!uploading) {
                  if (progress === 100)
                    return useLabelOnComplete
                      ? label
                      : `File ${file.name} uploaded`;
                  return label || 'Select a file';
                }
                return `Uploading ${file.name} (${progress}%)`;
              })()}
            </Typography>
            {customizedStyle ? (
              <Typography className={classes.subtitle} gutterBottom>
                Head and shoulders photo with a white background. No avatar
                images will be accepted.
              </Typography>
            ) : null}
            {bachelorDegreeCertificateText ? (
              <Typography className={classes.subtitle} gutterBottom>
                Recent graduates without a Diploma, please provide transcript
              </Typography>
            ) : null}
          </div>
        </div>
        <Zoom in={uploading}>
          <LinearProgress
            className={classes.progress}
            variant="determinate"
            value={progress}
          />
        </Zoom>
      </div>
    );
  }
}

export default withStyles(styles)(Upload);
