import { DateTime } from 'luxon';

// Material UI imports
import { Typography, Paper, Avatar, makeStyles } from '@material-ui/core';

// Material UI lab
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
  TimelineContent
} from '@material-ui/lab';

// Material Icons
import {
  CallRounded as StartIcon,
  CallEndRounded as EndIcon,
  HelpRounded as HelpIcon,
  VideocamRounded as VideoIcon,
  VideocamOffRounded as VideoOffIcon,
  MicRounded as MicIcon,
  MicOffRounded as MicOffIcon,
  PersonAddRounded as JoinIcon,
  ExitToAppRounded as LeaveIcon
} from '@mui/icons-material';

// Shared helpers
import { getInitials } from 'helpers';

// Style
import styles from './styles';

const useStyles = makeStyles(styles);

const compareLogTimestamp = (a, b) => {
  return (
    DateTime.fromISO(a.Timestamp).toMillis() -
    DateTime.fromISO(b.Timestamp).toMillis()
  );
};

const formatLog = (event, lastEvent) => {
  const { StatusCallbackEvent, ParticipantIdentity, Timestamp, TrackKind } =
    event;
  let ParticipantName;

  // Safety to parse the participant name
  try {
    ParticipantName = JSON.parse(ParticipantIdentity)[1];
  } catch (error) {
    ParticipantName = ParticipantIdentity;
  }

  const eventType = ['room-created', 'room-ended'].includes(StatusCallbackEvent)
    ? 'room'
    : 'user';
  const time = DateTime.fromISO(Timestamp).toFormat('h:mm:ss a');
  let eventAction = '?';
  let eventIcon = <HelpIcon />;

  if (TrackKind === 'video') {
    switch (StatusCallbackEvent) {
      case 'track-added':
        eventAction = 'Started Video';
        eventIcon = <VideoIcon />;
        break;
      case 'track-removed':
        eventAction = 'Stopped Video';
        eventIcon = <VideoOffIcon />;
        break;
      case 'track-enabled':
        eventAction = 'Enabled Video';
        eventIcon = <VideoIcon />;
        break;
      case 'track-disabled':
        eventAction = 'Disabled Video';
        eventIcon = <VideoOffIcon />;
        break;
    }
  } else if (TrackKind === 'audio') {
    switch (StatusCallbackEvent) {
      case 'track-added':
        eventAction = 'Started Audio';
        eventIcon = <MicIcon />;
        break;
      case 'track-removed':
        eventAction = 'Stopped Audio';
        eventIcon = <MicOffIcon />;
        break;
      case 'track-enabled':
        eventAction = 'Unmuted';
        eventIcon = <MicIcon />;
        break;
      case 'track-disabled':
        eventAction = 'Muted';
        eventIcon = <MicOffIcon />;
        break;
    }
  } else {
    switch (StatusCallbackEvent) {
      case 'room-created':
        eventAction = 'Call Started';
        eventIcon = <StartIcon />;
        break;
      case 'room-ended':
        eventAction = 'Call Ended';
        eventIcon = <EndIcon />;
        break;
      case 'participant-connected':
        eventAction = 'Connected';
        eventIcon = <JoinIcon />;
        break;
      case 'participant-disconnected':
        eventAction = 'Disconnected';
        eventIcon = <LeaveIcon />;
        break;
    }
  }

  if (eventType === 'room') {
    return {
      action: eventAction,
      time: DateTime.fromISO(Timestamp).toFormat(DateTime.TIME_SIMPLE),
      icon: eventIcon
    };
  } else {
    return {
      action: eventAction,
      time,
      profile: getInitials(ParticipantName),
      skipProfile:
        lastEvent && lastEvent.profile === getInitials(ParticipantName),
      user: ParticipantName,
      icon: eventIcon
    };
  }
};

const formatLogs = (logs) => {
  const output = [];

  for (const logIndex in logs) {
    output[logIndex] = formatLog(logs[logIndex], output[logIndex - 1]);
  }

  return output;
};

function CallTimeline(props) {
  const { booking } = props;
  // Grab the classes from the component's props
  const classes = useStyles();

  if (!booking || !booking.logs) return null;

  return (
    <Timeline align="alternate">
      {formatLogs(booking.logs.sort(compareLogTimestamp)).map(
        (event, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <Typography
                variant="body2"
                color="textSecondary"
                className={
                  event.profile && !event.skipProfile
                    ? classes.timeProfileMargin
                    : classes.timeIconMargin
                }>
                {event.time}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator className={classes.separator}>
              {event.profile && !event.skipProfile ? (
                <Avatar className={classes.avatar}>{event.profile}</Avatar>
              ) : (
                <TimelineDot>{event.icon}</TimelineDot>
              )}
              {index !== booking.logs.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  {event.action}
                </Typography>
                {event.user && <Typography>{event.user}</Typography>}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        )
      )}
    </Timeline>
  );
}

export default CallTimeline;
