// HTTP REST library
import axios, { AxiosRequestConfig } from 'axios';

// Import the API scopes
import {
  Admin,
  Auth,
  Announcements,
  Applications,
  Bookings,
  Certificates,
  Developer,
  Materials,
  Payments,
  Profile,
  Chat
} from './_exports';

// Import authprovider state type
import { State as AuthProviderState } from '../provider';

// Sotre and LogRocket imports
import Store from './_store';
import AppConfig from 'config';

class API {
  credentials: AuthProviderState | null;
  store: Store;
  version: string;
  _bookings: Bookings;
  _profile: Profile;

  constructor() {
    // Set the credentials for the class
    this.credentials = null;
    this.store = new Store();
    this.version = 'v1';

    // Bind call to access the 'this' reference
    this._http = this._http.bind(this);

    // Create instances of live classes
    this._bookings = new Bookings(this);
    this._profile = new Profile(this);
  }

  // Internal method for making HTTP requests
  async _http<T = any>(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    options: AxiosRequestConfig,
    store = false,
    dev = false
  ): Promise<T> {
    const NO_AUTH = ['auth/register', 'auth/reset', 'applications'];
    if (this.credentials != null || NO_AUTH.includes(endpoint)) {
      // Build the API url
      const requestEndpoint = dev
        ? `${AppConfig.functionsUrl}/api/dev/${endpoint}`
        : `${AppConfig.functionsUrl}/api/${this.version}/${endpoint}`;
      if (AppConfig.IS_DEV_BUILD) {
        console.log(`API ${method} ${requestEndpoint}`, options);
      }

      // Build the store endpoint
      const storeEndpoint = `${method.toLowerCase()}-${endpoint}`;

      // If the value exists, return it
      const storeValue = this.store.get(storeEndpoint);
      if (store && storeValue) return storeValue;

      try {
        // Return the Promise of the request
        const response = await axios({
          url: requestEndpoint,
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: !NO_AUTH.includes(endpoint)
              ? this.credentials?.token
              : undefined
          },
          timeout: 20000,
          timeoutErrorMessage: `We can't reach e-English at the moment. Please wait and try again.`,
          ...options
        }).catch((error) => {
          throw error;
        });

        // Update the reponse in the store
        if (method === 'GET') {
          this.store.set(storeEndpoint, response.data);
        }

        // Parse the reponse into json before returning it
        return response.data as T;
      } catch (error: any) {
        if (error.response) {
          console.error(error.response.data);

          // Re-throw the error so the component can handle it in it's own way
          throw error.response.data;
        } else {
          console.error(error);
          throw error;
        }
      }
    }

    // Make sure that we actually have credentials stored before making an API call.
    throw new Error(
      `Tried to make API call (/${endpoint}) but credentials were null.`
    );
  }

  // Internal method for storing with direct firebase requests
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async _firebase<T = any>(
    name: string,
    func: (thisRef: API) => Promise<any>,
    store: boolean
  ): Promise<T> {
    try {
      // Get the value of the store entry
      const storeKey = `firebase-${name}`;
      const storeValue = this.store.get(storeKey);

      // If the entry exists in the store, return it
      if (storeValue && store) return storeValue;

      // Make the firebase request and store the data
      const data = await func(this);
      this.store.set(storeKey, data);

      // Return it
      return data as T;
    } catch (error) {
      // Log the error
      console.error(error);

      // Re-throw the error so the component can handle it in it's own way
      throw error;
    }
  }

  // Function for settings the credentials of the API client.
  setCredentials(credentials: AuthProviderState | null): void {
    // Initialize the credentials
    this.credentials = credentials;

    if (credentials !== null && credentials !== undefined) {
      this._bookings.mount();
      this._profile.mount();
    }

    // If we're clearing the credentials (i.e. we're logging out), clear the store for the next user
    if (credentials === null) {
      // Unmount the booking class
      if (this._bookings) {
        this._bookings.unmount();
      }

      // Unmount the profile class class
      if (this._profile) {
        this._profile.unmount();
      }

      // Clear the store
      this.store.clear();
    }
  }

  // Middleware for checking roles
  enforceRole(role: 'student' | 'tutor' | 'admin' = 'admin'): void {
    // Check whether we're the right role before making the request
    if (!['admin', role].includes(this.credentials?.role || 'student')) {
      throw new Error(`This functionality is only available to ${role}s!`);
    }
  }

  // Admin API endpoint
  get admin(): ReturnType<typeof Admin> {
    return Admin(this);
  }

  // Authentication API endpoint
  get auth(): ReturnType<typeof Auth> {
    return Auth(this);
  }

  // Announcements API endpoint
  get announcements(): ReturnType<typeof Announcements> {
    return Announcements(this);
  }

  // Applications API endpoint
  get applications(): ReturnType<typeof Applications> {
    return Applications(this);
  }

  // Certificate API endpoint
  get certificates(): ReturnType<typeof Certificates> {
    return Certificates(this);
  }

  // Developer API endpoint
  get developer(): ReturnType<typeof Developer> {
    return Developer(this);
  }

  // Bookings API endpoint
  get bookings(): Bookings {
    return this._bookings;
  }

  // Materials API endpoint
  get materials(): ReturnType<typeof Materials> {
    return Materials(this);
  }

  // Payments API endpoint
  get payments(): ReturnType<typeof Payments> {
    return Payments(this);
  }

  // Profile API endpoint
  get profile(): Profile {
    return this._profile;
  }

  // Authentication API endpoint
  get chat(): ReturnType<typeof Chat> {
    return Chat(this);
  }
}

export default API;
