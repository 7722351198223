import { PureComponent } from 'react';

// React router components
import { Link as RouterLink, withRouter } from 'react-router-dom';

import countryList from 'react-select-country-list';
// Validation helper
import validate from 'validate.js';

// Import material components
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Grow,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Authentication provider
import { withAuth } from 'services/auth';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Cleanup exports
import compose from 'recompose/compose';

// Component styles
import styles from './styles';

// Import the schema
import schema from './schema';

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.options = countryList().getData();

    // Initialize the component's state
    this.state = {
      isValid: false,
      creatingUser: false,
      values: {},
      touched: {},
      errors: {},
      options: this.options
    };

    // Bind the required functions
    this.handleChange = this.handleChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.hasError = this.hasError.bind(this);

    console.log(props.auth.api);
  }

  handleChange(event) {
    // Grab the values from the state
    const { values, touched } = this.state;

    // Get the newly changed values
    const newValues = {
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };

    // Validate the state's values
    const errors = validate(newValues, schema);

    // Update the state
    this.setState({
      values: newValues,
      touched: {
        ...touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    });
  }

  handleBack() {
    // Grab the history provider from the props
    const { history } = this.props;

    // Navigate back
    history.push('/signin');
  }

  async handleSignUp(event) {
    // Prevent an actual form submit from occuring
    event.preventDefault();

    // Grab the auth provider from the props and state info
    const { isValid, values } = this.state;
    const { auth, enqueueSnackbar } = this.props;

    // Check whether the sign up form is valid
    if (isValid) {
      try {
        // Disable the form elements
        this.setState({ creatingUser: true });

        // Tell the user that we're creating the account
        enqueueSnackbar('Creating your account...');
        console.log(auth.api.auth);

        // Create the user account
        await auth.api.auth.register({
          email: values.email,
          password: values.password,
          displayName: `${values.firstName} ${values.lastName}`,
          about: values.about,
          country: values.country,
          offerCode: values?.offerCode
        });

        // Send the verification email
        await auth.signIn(['email', values.email, values.password], true);
      } catch (error) {
        // Log the error to the console
        console.error(error);

        // Re-enable the form elements
        this.setState({ creatingUser: false });

        // Show an error snackbar
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error'
        });
      }
    }
  }

  hasError(field) {
    // Grab the touched and errors state info
    const { touched, errors } = this.state;
    return !!(touched[field] && errors[field]);
  }

  render() {
    // Grab the component's classes from its props
    const { classes } = this.props;

    // Grab the state info
    const { creatingUser, isValid, errors, values, options } = this.state;

    // Render the sign up page
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack} disabled={creatingUser}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleSignUp}>
                  <div className={classes.formHeader}>
                    <div>
                      <Typography variant="h2">Create new account</Typography>
                      <Typography
                        className={classes.subtitle}
                        color="textSecondary"
                        gutterBottom>
                        Use your email to create new account
                      </Typography>
                    </div>
                    <span className={classes.spacer} />
                    <Grow in={creatingUser}>
                      <div>
                        <CircularProgress />
                      </div>
                    </Grow>
                  </div>
                  <TextField
                    className={classes.textField}
                    error={this.hasError('firstName')}
                    fullWidth
                    helperText={
                      this.hasError('firstName') ? errors.firstName[0] : null
                    }
                    label="First name"
                    name="firstName"
                    onChange={this.handleChange}
                    type="text"
                    value={values.firstName || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('lastName')}
                    fullWidth
                    helperText={
                      this.hasError('lastName') ? errors.lastName[0] : null
                    }
                    label="Last name"
                    name="lastName"
                    onChange={this.handleChange}
                    type="text"
                    value={values.lastName || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('email')}
                    fullWidth
                    helperText={this.hasError('email') ? errors.email[0] : null}
                    label="Email address"
                    name="email"
                    onChange={this.handleChange}
                    type="text"
                    value={values.email || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('confirmEmail')}
                    fullWidth
                    helperText={
                      this.hasError('confirmEmail')
                        ? 'Please enter the same email as above'
                        : null
                    }
                    label="Confirm email address"
                    name="confirmEmail"
                    onChange={this.handleChange}
                    type="text"
                    value={values.confirmEmail || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('password')}
                    fullWidth
                    helperText={
                      this.hasError('password') ? errors.password[0] : null
                    }
                    label="Password"
                    name="password"
                    onChange={this.handleChange}
                    type="password"
                    value={values.password || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />

                  <TextField
                    className={classes.textField}
                    error={this.hasError('confirmPassword')}
                    fullWidth
                    helperText={
                      this.hasError('confirmPassword')
                        ? 'Please enter the same password as above'
                        : null
                    }
                    label="Confirm password"
                    name="confirmPassword"
                    onChange={this.handleChange}
                    type="password"
                    value={values.confirmPassword || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />
                  {/* <Typography className={classes.aboutHeader}>
                    <b>Now tell us a bit about yourself (hobbies, skills, etc)</b>
                  </Typography>
                  <TextField
                    className={classes.textFieldTall}
                    error={this.hasError('about')}
                    fullWidth
                    helperText={this.hasError('about') ? errors.about[0] : null}
                    label="Short Introduction"
                    name="about"
                    onChange={this.handleChange}
                    type="text"
                    value={values.about || ''}
                    variant="outlined"
                    disabled={creatingUser}
                    multiline
                  /> */}
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Offer Code"
                    name="offerCode"
                    onChange={this.handleChange}
                    type="text"
                    value={values.offerCode || ''}
                    variant="outlined"
                    disabled={creatingUser}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('country')}
                    fullWidth
                    select
                    helperText={
                      this.hasError('country') ? errors.country[0] : null
                    }
                    label="Country"
                    name="country"
                    onChange={this.handleChange}
                    type="country"
                    value={values.country || ''}
                    variant="outlined"
                    MenuProps={{ style: { height: 100 } }}>
                    {options.map((item) => (
                      <MenuItem key={item.label} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className={classes.policy}>
                    <Checkbox
                      checked={values.policy || false}
                      className={classes.policyCheckbox}
                      color="primary"
                      name="policy"
                      onChange={this.handleChange}
                      disabled={creatingUser}
                    />
                    <Typography
                      className={classes.policyText}
                      color="textSecondary"
                      variant="body1">
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      I have read the e-English app
                      <div
                        className="policy-text"
                        onClick={() =>
                          window
                            .open(
                              'https://e-english.com.au/terms-of-use/',
                              '_blank'
                            )
                            .focus()
                        }>
                        Terms and Conditions
                      </div>
                    </Typography>
                  </div>
                  {this.hasError('policy') && (
                    <FormHelperText error>{errors.policy[0]}</FormHelperText>
                  )}
                  <Button
                    className={classes.signUpButton}
                    color="primary"
                    disabled={!isValid || creatingUser}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Sign up now
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    Have an account?{' '}
                    <Link component={RouterLink} to="/signin" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Welcome to e-English!
                </Typography>
                <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant="body1"
                    color="textSecondary">
                    e-English Team
                  </Typography>
                  {/* <Typography className={classes.bio} variant="body2">
                    The e-English Team
                  </Typography> */}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withAuth,
  withRouter,
  withSnackbar,
  withStyles(styles)
)(SignUp);
