export default {
  root: {
    height: '50px',
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '14px',
    '@media (min-width: 660px)': {
      minWidth: '160px'
    },
    '&$selected': {
      fontWeight: 500
    },
    '&:first-child': {
      borderTopLeftRadius: 16
    },
    '&:last-child': {
      borderTopRightRadius: 16
    }
  }
};
