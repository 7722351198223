export default {
  root: {
    '& :first-child': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    },
    '& :last-child': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    }
  }
};
