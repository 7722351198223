export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexGrow: 1
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  actionsDivider: {
    marginBottom: theme.spacing(4)
  },
  hideSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  reminderAlert: {
    marginBottom: theme.spacing(1.5)
  },
  dateControls: {
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(4)
  }
});
