export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  divider: {
    marginBottom: theme.spacing(1.5)
  }
});
