export default (theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(3)
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    color: theme.palette.text.secondary,
    fontWeight: '400'
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  end: {
    marginBottom: theme.spacing(3)
  },
  sliderWrapper: {
    width: '200px'
  },
  spacer: {
    flexGrow: 1
  },
  errorMessage: {
    fontWeight: '400',
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary
  }
});
