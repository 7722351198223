'use strict';
import moment from 'moment';
import * as React from 'react';
import { DateTime } from 'luxon';
import { Typography } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import './styles.scss';
import { MenuItem } from '@mui/material';

const Heading = ({ date, changeMonth, resetDate }) => (
  <nav className="calendar--nav">
    <a onClick={() => changeMonth(date.month() - 1)}>&#8249;</a>
    <h1 onClick={() => resetDate()}>
      {date.format('MMMM')} {date.format('YYYY')}
    </h1>
    <a onClick={() => changeMonth(date.month() + 1)}>&#8250;</a>
  </nav>
);

const Day = ({ currentDate, date, startDate, endDate, onClick }) => {
  let className = [];
  const twoMonthsAgo = moment().subtract(2, 'months').startOf('month');

  if (moment().isSame(date, 'day')) {
    className.push('active');
  }

  if (date.isSame(startDate, 'day')) {
    className.push('start');
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }
  const isDisabled = date.isBefore(twoMonthsAgo, 'day');
  if (isDisabled) {
    className.push('disabled');
  }

  return (
    <span
      // onClick={() => onClick(date)}
      onClick={() => !isDisabled && onClick(date)}
      currentDate={date}
      className={className.join(' ')}>
      {date.date()}
    </span>
  );
};

const Days = ({ date, startDate, endDate, onClick }) => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextsMonth = moment(date).add(1, 'month');
  let days = [];
  let labels = [];

  for (let i = 1; i <= 7; i++) {
    labels.push(<span className="label">{moment().day(i).format('ddd')}</span>);
  }

  for (let i = firstDayDate.day(); i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);

    days.push(
      <Day
        key={moment(previousMonth).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(previousMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    days.push(
      <Day
        key={moment(thisDate).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  const daysCount = days.length;
  for (let i = 1; i <= 42 - daysCount; i++) {
    nextsMonth.date(i);
    days.push(
      <Day
        key={moment(nextsMonth).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(nextsMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  return (
    <nav className="calendar--days">
      {labels.concat()}
      {days.concat()}
    </nav>
  );
};

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      startDate: this.props.startDate ? this.props.startDate : moment(),
      endDate: this.props.endDate ? this.props.endDate : moment(),
      isDisplay: 'none'
    };
  }

  resetDate() {
    this.setState({
      date: moment()
    });
  }

  changeMonth(month) {
    const { date } = this.state;

    date.month(month);

    this.setState(date);
  }

  changeDate(date) {
    let { startDate, endDate } = this.state;

    if (
      startDate === null ||
      date.isBefore(startDate, 'day') ||
      !startDate.isSame(endDate, 'day')
    ) {
      startDate = moment(date);
      endDate = moment(date);
    } else if (date.isSame(startDate, 'day') && date.isSame(endDate, 'day')) {
      startDate = null;
      endDate = null;
    } else if (date.isAfter(startDate, 'day')) {
      endDate = moment(date);
    }

    this.setState({
      startDate,
      endDate
    });
    this.props.onDateChange(
      DateTime.fromISO(
        moment(startDate.format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString()
      ),
      DateTime.fromISO(
        moment(endDate.format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString()
      )
    );
  }

  onClickDate(value) {
    this.setState({ isDisplay: value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSearch !== this.props.isSearch) {
      if (this.props.isSearch === false) {
        this.setState({
          startDate: this.props.startDate,
          endDate: this.props.endDate
        });
      }
    }
  }
  // Helper function to format time with AM/PM
  formatTime(hour, minute, period) {
    const displayHour = hour % 12 === 0 ? 12 : hour % 12; // Adjust hour 0 to 12
    const formattedMinute = minute < 10 ? '0' + minute : minute;
    return `${displayHour}:${formattedMinute} ${period}`;
  }

  // Generate times in AM/PM order
  generateTimeOptions() {
    const hours = Array.from({ length: 12 }, (_, i) => i + 1); // Hours 1-12 for 12-hour format
    const minutes = [0, 30];
    const anytimeOption = 'anytime';
    const amTimes = hours.flatMap((hour) =>
      minutes.map((minute) => ({
        value: `${this.formatTime(hour, minute, 'AM')}`,
        label: this.formatTime(hour, minute, 'AM')
      }))
    );
    const pmTimes = hours.flatMap((hour) =>
      minutes.map((minute) => ({
        value: `${this.formatTime(hour, minute, 'PM')}`,
        label: this.formatTime(hour, minute, 'PM')
      }))
    );
    return [anytimeOption, ...amTimes, ...pmTimes];
  }

  render() {
    const { date, startDate, endDate } = this.state;
    const { showTime } = this.props;
    const timeOptions = this.generateTimeOptions();

    return (
      <>
        <TextField
          id="standard-basic"
          style={{ width: 120, color: 'blue' }}
          label="Start Date"
          value={
            startDate
              ? startDate.isSame(endDate, 'day')
                ? startDate.format('DD - MMM - YYYY')
                : `${startDate.format('DD - MMM - YYYY')}`
              : 'Select Date'
          }
          variant="standard"
          InputProps={{
            readOnly: true
          }}
          onClick={() => this.onClickDate('block')}
          sx={{ input: { color: this.props.isSearch ? 'blue' : 'inherit' } }}
        />
        <Typography className="calendar-text" variant="h5">
          To
        </Typography>
        <TextField
          id="standard-basic"
          style={{ width: 120 }}
          label="End Date"
          value={
            endDate
              ? startDate.isSame(endDate, 'day')
                ? endDate.format('DD - MMM - YYYY')
                : `${endDate.format('DD - MMM - YYYY')}`
              : 'Select Date'
          }
          variant="standard"
          InputProps={{
            readOnly: true
          }}
          onClick={() => this.onClickDate('block')}
          sx={{ input: { color: this.props.isSearch ? 'blue' : 'inherit' } }}
        />
        {showTime && (
          <TextField
            id="start-time"
            style={{ width: 160, marginInline: '10px' }}
            label="Time"
            select
            variant="standard"
            color="primary"
            value={this.props.selectedTime}
            onChange={this.props.onTimeChange}
            InputLabelProps={{
              shrink: true
            }}>
            {timeOptions.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value || option} // Handle anytimeOption and formatted times
              >
                {option.label || option}
              </MenuItem>
            ))}
          </TextField>
        )}
        <div
          className="calendar-modal"
          onClick={() => this.onClickDate('none')}
          style={{ display: this.state.isDisplay }}>
          <div
            className="calendar"
            id="calendar"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Heading
              date={date}
              changeMonth={(month) => this.changeMonth(month)}
              resetDate={() => this.resetDate()}
            />

            <Days
              onClick={(date) => this.changeDate(date)}
              date={date}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Calendar;
