import { PureComponent } from 'react';

// Material UI imports
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  Chip,
  withStyles
} from '@material-ui/core';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Luxon date helpers
import { DateTime } from 'luxon';

import { DateRangeRounded as DateIcon } from '@mui/icons-material';

// Portlet items
import {
  Portlet,
  PortletHeader,
  // PortletToolbar,
  PortletLabel,
  PortletContent,
  AsyncStatus,
  PortletFooter
} from 'components';

// To handle error messages
import { getErrorMessage, getInitials } from 'helpers';

// To clean up the export
import compose from 'recompose/compose';

// Authentication & API provider
import { withAuth } from 'services/auth';

// Router provider
import { Link, withRouter } from 'react-router-dom';

// Style
import styles from './styles';

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />;
}

class UpcomingLiveCard extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      bookings: undefined,
      error: undefined
    };

    // Bind the required functions
    this.onSnapData = this.onSnapData.bind(this);
    this.onSnapError = this.onSnapError.bind(this);
    this.retryHook = this.retryHook.bind(this);
    this.gotoSchedule = this.gotoSchedule.bind(this);

    // Set the ready flag to true
    this.ready = true;
  }

  componentDidMount() {
    // Grab the auth provider from the props
    const { auth } = this.props;

    // Retrive the current user's bookings from the API.
    this.bookingsHook = auth.api.bookings.hook(
      'bookingsMyLiveBookings',
      this.onSnapData,
      this.onSnapError,
      'UpcomingLiveCard'
    );
  }

  componentWillUnmount() {
    // If the unsubscribe function is valid, unsubscribe
    if (this.bookingsHook) this.bookingsHook();

    // Set the ready flag to false
    this.ready = false;
  }

  onSnapData(snap) {
    // Update the component's state with the bookings.
    if (this.ready) {
      this.setState({
        bookings: snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
      });
    }
  }

  onSnapError(error) {
    // Update the component's state to show an error
    if (this.ready) {
      this.setState({
        error: `${getErrorMessage(error)} (try reloading the page)`
      });

      // Log the error to the console.
      console.error(error);
    }
  }

  retryHook() {
    // Reset the state
    this.setState({
      bookings: undefined,
      error: undefined
    });

    // Unsubscribe from the bookings hook
    if (this.bookingsHook) this.bookingsHook();

    // Call componentDidMount to call the API again.
    this.componentDidMount();
  }

  gotoSchedule() {
    // Grab the history provider from the props
    const { auth, history } = this.props;

    // Redirect the user to the bookings/schedule page.
    history.push(
      auth.role === 'student' ? '/student/bookings' : '/tutor/schedule'
    );
  }

  renderBookings() {
    const { classes, auth } = this.props;
    const { error } = this.state;
    let { bookings } = this.state;

    // Get the opposite role
    const roleSwitch = auth.role === 'student' ? 'tutor' : 'student';

    if (error) {
      // Display the error message and retry button
      return <AsyncStatus error={error} onRetry={this.retryHook} />;
    }
    if (bookings === undefined) {
      return (
        // Return the loading animation
        <div className={classes.loadingInner}>
          <List className={classes.fullList} disablePadding>
            {[0, 1, 2].map((index) => (
              <ListItem divider={index !== 2} key={index} disableGutters>
                <Skeleton width="100%" height={6} />
                <Skeleton width="75px" className={classes.skeletonRight} />
              </ListItem>
            ))}
          </List>
        </div>
      );
    }

    // Ensure only booked bookings are rendered
    bookings = bookings.filter((booking) => booking.status === 'booked');

    if (bookings.length === 0) {
      // Notify the user that they have no upcoming lessons.
      return (
        <AsyncStatus error="You have no upcoming lessons" textVariant="body1" />
      );
    }
    // Return a rendered list of the items.
    return (
      <List className={classes.fullList} disablePadding>
        {bookings.map((booking, index) => (
          <ListItemLink
            to={`/${auth.role}/bookings?view=upcoming`}
            divider={index !== bookings.length - 1}
            key={booking.id}>
            <ListItemAvatar>
              <Avatar
                alt={booking.profile[roleSwitch].name}
                src={booking.profile[roleSwitch].image}>
                <Typography variant="h5" style={{ color: 'white' }}>
                  {getInitials(booking.profile[roleSwitch].name || '')}
                </Typography>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={DateTime.fromMillis(booking.when).toFormat(
                'dd LLL h:mm a'
              )}
              secondary={`with ${booking.profile[roleSwitch].name}`}
            />
            <ListItemSecondaryAction>
              <Chip label={DateTime.fromMillis(booking.when).toRelative()} />
            </ListItemSecondaryAction>
          </ListItemLink>
        ))}
      </List>
    );
  }

  render() {
    const { auth, classes } = this.props;

    return (
      <Portlet className={classes.root}>
        <PortletHeader>
          <PortletLabel icon={<DateIcon />} title="Upcoming Lessons" />
          {/* <PortletToolbar>
            <Typography className={classes.live} variant="subtitle2">
              &#9679; LIVE
            </Typography>
          </PortletToolbar> */}
        </PortletHeader>
        <PortletContent
          style={{
            maxHeight:
              this.state.bookings && this.state.bookings.length > 0
                ? `${Math.min(300, this.state.bookings.length * 50)}px`
                : 'auto',
            minHeight: 'auto'
          }}
          className={classes.content}
          noPadding>
          {this.renderBookings()}
        </PortletContent>
        <PortletFooter noPadding>
          <Button
            className={classes.btnBookNow}
            fullWidth
            color="primary"
            onClick={this.gotoSchedule}>
            {auth.role === 'student' ? 'Book Now' : 'Go To Schedule'}
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

export default compose(
  withAuth,
  withRouter,
  withStyles(styles)
)(UpcomingLiveCard);
