import { useCallback, useState } from 'react';

// Date picker
import { DatePicker } from '@material-ui/pickers';

// Material UI imports
import { TextField, Grid } from '@material-ui/core';

// Luxon utils
import { DateTime } from 'luxon';

interface Props {
  disabled?: boolean;
  values?: [DateTime, DateTime];
  onChange?: (values: [DateTime, DateTime]) => void;
}

function DateRangePicker(props: Props): JSX.Element {
  const { values, onChange } = props;
  const [startDate, setStartDate] = useState(
    values ? values[0] : DateTime.local().minus({ months: 1 })
  );
  const [endDate, setEndDate] = useState(values ? values[1] : DateTime.local());

  const updateStartDate = useCallback(
    (newDate: DateTime | null) => {
      if (newDate) {
        setStartDate(newDate || startDate);
        if (onChange) onChange([newDate, endDate]);
      }
    },
    [endDate, onChange, startDate]
  );

  const updateEndDate = useCallback(
    (newDate: DateTime | null) => {
      if (newDate) {
        setEndDate(newDate || endDate);
        if (onChange) onChange([startDate, newDate]);
      }
    },
    [endDate, onChange, startDate]
  );

  return (
    <Grid container spacing={2}>
      <Grid
        className={'date-range-start'}
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        xl={6}>
        <DatePicker
          disabled={props.disabled}
          label="Start Date"
          inputFormat="dd/MM/yyyy"
          value={startDate}
          maxDate={endDate.minus({ days: 1 })}
          onChange={(newDate) => setStartDate(newDate || startDate)}
          onAccept={updateStartDate}
          renderInput={(props) => (
            <TextField fullWidth {...props} variant="filled" />
          )}
        />
      </Grid>
      <Grid
        className={'date-range-end'}
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        xl={6}>
        <DatePicker
          disabled={props.disabled}
          label="End Date"
          inputFormat="dd/MM/yyyy"
          value={endDate}
          minDate={startDate.plus({ days: 1 })}
          onChange={(newDate) => setEndDate(newDate || endDate)}
          onAccept={updateEndDate}
          renderInput={(props) => (
            <TextField fullWidth {...props} variant="filled" />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default DateRangePicker;
