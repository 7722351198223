import { useState, useEffect, useContext, useCallback } from 'react';

// Material UI imports
import { makeStyles } from '@material-ui/core';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Snackbar provicer
import { useSnackbar } from 'notistack';

// Authentication context
import { AuthContext } from 'services/auth';

// AsyncStatus
import { AsyncStatus } from 'components';

// Style
import styles from './styles';
import { ReactPdf } from './components/ReactPdf';
import useWindowSize from 'hooks/useWindowSize';

const useStyles = makeStyles(styles);

export const MaterialsPanel = (props) => {
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(false);
  const { path, info } = props;

  const [width] = useWindowSize();
  const snackbar = useSnackbar();
  const auth = useContext(AuthContext);
  const classes = useStyles();

  const getUrl = async (path) => {
    // Retireve the URL of the lesson document
    // eslint-disable-next-line no-unreachable
    const url = await auth.api.materials.getLessonDocument(
      snackbar.enqueueSnackbar,
      path
    );

    // Update the state
    setUrl(url);
  };

  // Retrieve the material URL
  useEffect(() => {
    async function getMaterialUrl() {
      try {
        /** Loading moc doc. for tarine teachers */
        // if (info.is_mock) {
        //   return getUrl('materials/mock/Demo Lesson Plan - Tutor Training.pdf');
        // }

        if (path) {
          getUrl(path);
        } else if (info) {
          // Retrieve the materials from the API
          const allCourses = await auth.api.materials.getCourses(
            info.topic.material
          );

          const course = allCourses.find(
            (entry) => entry.id === info.topic.course
          );

          // Find the lesson
          const lesson = course.lessons.find(
            (lesson) => lesson.id === info.topic.lesson
          );

          getUrl(lesson[`${auth.role}Resource`]);
        }
      } catch (error) {
        // Update the error message
        setError(error);
      }
    }

    getMaterialUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, retry]);

  // Helper function for retrying the API call
  const retryAPICall = useCallback(() => {
    setError(null);
    setRetry(!retry);
  }, [retry]);

  // IFrame load handler
  const onFrameLoad = () => setFrameLoaded(true);
  const loaded = frameLoaded && url;

  // Render the error component
  if (error) {
    return (
      <div className={classes.status}>
        <AsyncStatus error={error} onRetry={retryAPICall} retry />
      </div>
    );
  }

  return (
    <div className={width > 900 ? classes.root : classes.rootResponsive}>
      {width < 900 ? (
        <div
          className={'pdf-container'}
          style={{
            height: url ? '50vh' : '64vh',
            minWidth: width < 500 ? width - 10 : width
          }}>
          {url && <ReactPdf url={url} />}
        </div>
      ) : (
        <>
          <Skeleton
            variant="rect"
            className={url && loaded ? classes.hidden : classes.viewer}
          />
          <iframe
            className={url && loaded ? classes.viewer : classes.hidden}
            title="Lesson Materials"
            src={url}
            frameBorder="0"
            onLoad={onFrameLoad}
          />
        </>
      )}
    </div>
  );
};
