const IS_PROD_BRANCH = process.env.REACT_APP_BRANCH === 'main';
const IS_DEV_BUILD =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const branchName: { [key: string]: string } = {
  none: 'nobranch',
  development: 'dev',
  main: 'prod'
};

export default {
  buildId: IS_DEV_BUILD
    ? 'local'
    : `${branchName[process.env.REACT_APP_BRANCH || 'none']}-b${
        process.env.REACT_APP_BUILD || 'nobuild'
      }`,
  stripeKey: IS_PROD_BRANCH
    ? 'pk_live_EFQ59BUsEFzdTaooTvYcsoM500RVMheAn0'
    : 'pk_test_Sz8Qttvq5lNHpmIRVafXwk1s000LtqgIfq',
  firebase: IS_PROD_BRANCH
    ? {
        apiKey: 'AIzaSyCP7Y9tEVGifydrdbwFAviSZ8f-2OAlqvw',
        authDomain: 'eng-tutor-app.firebaseapp.com',
        databaseURL: 'https://eng-tutor-app.firebaseio.com',
        projectId: 'eng-tutor-app',
        storageBucket: 'eng-tutor-app.appspot.com',
        messagingSenderId: '311467479368',
        appId: '1:311467479368:web:9284c007dab94a9e',
        measurementId: 'G-LCBRSC9FGN'
      }
    : {
        apiKey: 'AIzaSyBzYs0q-8njNYpfl-njMKGxB3m_B3cj1bU',
        authDomain: 'eng-tutor-app-dev.firebaseapp.com',
        databaseURL: 'https://eng-tutor-app-dev.firebaseio.com',
        projectId: 'eng-tutor-app-dev',
        storageBucket: 'eng-tutor-app-dev.appspot.com',
        messagingSenderId: '238472138069',
        appId: '1:238472138069:web:d48285ec8e9df2af',
        measurementId: 'G-LB6M5G303R'
      },
  functionsUrl: IS_DEV_BUILD
    ? `http://${window.location.hostname}:5000/eng-tutor-app-dev/asia-east2`
    : `https://asia-east2-${
        IS_PROD_BRANCH ? 'eng-tutor-app' : 'eng-tutor-app-dev'
      }.cloudfunctions.net`,
  IS_DEV_BUILD,
  IS_PROD_BRANCH
};
