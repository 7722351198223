import { PureComponent, Suspense, lazy } from 'react';
import { importRetry as retry } from 'helpers';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

// Auth service
import { withAuth } from 'services/auth';

// Status component
import { Status } from 'views/shared';

// Page loader component
import { PageLoader } from 'components';

import AuthRoutes from './AuthRoutes';

// Sign in, sign up and tutor application
const SignIn = lazy(() => retry(() => import('views/shared/SignIn')));
const SignUp = lazy(() => retry(() => import('views/shared/SignUp')));
const Application = lazy(() => retry(() => import('views/shared/Application')));
const PasswordReset = lazy(() =>
  retry(() => import('views/shared/PasswordReset'))
);

class Routes extends PureComponent {
  constructor(props) {
    super(props);

    // Set the initial route property
    this.initial = this.props.location;
  }

  render() {
    const { auth, currentTheme, toggleDarkTheme } = this.props;

    const VerifyRoute = () => {
      return (
        <Switch>
          <Route
            component={() => {
              return (
                <Status
                  title="Account Verification"
                  message="An account verification email has been sent. Please check your email for the verification link and close this page."
                  showVerifyEmailBtn
                  showSignOutBtn
                />
              );
            }}
            exact
            path="/verify"
          />
          <Redirect to="/verify" />
        </Switch>
      );
    };

    const UpdateRoute = () => {
      const searchParams = new URLSearchParams(window.location.href);

      if (searchParams.has('id') && searchParams.has('t')) {
        return (
          <Switch>
            <Route
              component={() => {
                return (
                  <Status
                    title="Updating Profile..."
                    message="Thank you for the confirmation"
                    id={searchParams.get('id') || ''}
                    displayName={searchParams.get('name')}
                    email={searchParams.get('email')?.replace(' ', '+')}
                    expireTime={searchParams.get('t')}
                  />
                );
              }}
              exact
              path="/updateProfile"
            />
          </Switch>
        );
      } else {
        return <Redirect to="/signin" />;
      }
    };

    if (
      this.initial &&
      this.initial.pathname?.indexOf('/updateProfile') !== -1 &&
      window.location.href?.indexOf('/updateProfile') !== -1
    ) {
      return <UpdateRoute auth={auth} />;
    }

    // If we're logged in.
    if (auth.user) {
      if (auth.role) {
        // If the user has verified their email, we can render the
        // authenticated routes
        if (auth.user.emailVerified) {
          return (
            <AuthRoutes
              auth={auth}
              initial={this.initial}
              currentTheme={currentTheme}
              toggleDarkTheme={toggleDarkTheme}
            />
          );
        }

        // If the user's email has not been verified, show the verification screen (see component above)
        return <VerifyRoute auth={auth} />;
      }

      // If the user does not have a role (auth.role === undefined), something has definitely gone wrong
      // with the sign-up process. Show an error status.
      return (
        <Status
          showSignOutBtn
          title="Woah there!"
          message="Looks like we had trouble signing you in. Please reload the page and try again."
        />
      );
    }

    // If there is no user, return the sign in route.
    return (
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route component={SignIn} exact path="/signin" />
          <Route component={SignUp} exact path="/signup" />
          <Route component={Application} exact path="/application" />
          <Route component={PasswordReset} exact path="/reset" />
          <Redirect to="/signin" />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(withAuth(Routes));
