export default (theme) => ({
  paper: {
    padding: '6px 16px',
    backgroundColor: theme.palette.background.main
  },
  timeIconMargin: {
    marginTop: theme.spacing(1.6)
  },
  timeProfileMargin: {
    marginTop: theme.spacing(1.1)
  },
  avatar: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '1.25em'
  },
  separator: {
    color: theme.palette.divider
  }
});
