export default (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh'
  },
  rootHome: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  home: {
    display: 'flex',
    justifyContent: 'center'
  },
  homeButton: {
    marginTop: theme.spacing(4.5)
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  contentSubmitted: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 600,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(5)
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5)
    }
  },
  formHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  formProfile: {
    display: 'flex',
    justifyContent: 'center'
  },
  avatar: {
    width: 100,
    height: 100
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyBottom: {
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  applyButton: {
    margin: theme.spacing(2, 0)
  },
  spacer: {
    flexGrow: 1
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3.5)
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary
  },
  image: {
    marginBottom: theme.spacing(4)
  },
  intructions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  header: {
    marginTop: theme.spacing(3.5)
  },
  applyErrors: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  applyErrorText: {
    color: theme.palette.error.main
  },
  bulletContainer: {
    marginLeft: theme.spacing(3)
  },
  subheading: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
    textAlign: 'center'
  },
  subheadingIntro: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary
  }
});
