import { PureComponent } from 'react';

// Snackbar notifications
import { withSnackbar, ProviderContext } from 'notistack';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Load stripe
import { loadStripe, Stripe } from '@stripe/stripe-js';

// Firebase libraries
import firebase from 'firebase/app';
import 'firebase/auth';

// LogRocket logging
import LogRocket from 'logrocket';

import API from './api/index';
import AppConfig from 'config';

// Authentication react context
import AuthContext from './context';

type Props = ProviderContext;

interface State {
  user?: firebase.User | null;
  token?: string;
  role?: 'student' | 'tutor' | 'admin';
  hasLoaded: boolean;
  authError: firebase.auth.Error | null;
}

class AuthProvider extends PureComponent<Props, State> {
  // Class types
  config: typeof AppConfig;
  api: API;
  tokenRefreshInterval: NodeJS.Timeout | null;
  authListener?: firebase.Unsubscribe;
  stripe?: Stripe | null;

  constructor(props: Props) {
    super(props);

    // Authentication state
    this.state = {
      user: undefined,
      token: undefined,
      role: undefined,
      hasLoaded: false,
      authError: null
    };

    // Load the project configuration
    this.config = AppConfig;

    // Initialize Firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(this.config.firebase);
    }

    // Bind event handlers & functions
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.onAuthError = this.onAuthError.bind(this);
    this.onAuthSuccess = this.onAuthSuccess.bind(this);
    this.authSignIn = this.authSignIn.bind(this);
    this.authSignOut = this.authSignOut.bind(this);
    this.resetState = this.resetState.bind(this);

    // Create a new instance of the API class
    this.api = new API();

    // Define the token refresher interval
    this.tokenRefreshInterval = null;
  }

  async componentDidMount() {
    // Grab the enqueueSnackbar function from the props
    const { enqueueSnackbar } = this.props;

    try {
      // Listen for any Firebase authentication changes.
      this.authListener = firebase
        .auth()
        .onAuthStateChanged(this.onAuthStateChanged, this.onAuthError);
      this.stripe = await loadStripe(this.config.stripeKey);
    } catch (error) {
      // Show the error in a snackar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  componentWillUnmount() {
    // If we're listening for authentication, stop listening
    if (this.authListener) this.authListener();

    // If we're listening for token refreshes, stop listening.
    if (this.tokenRefreshInterval) clearInterval(this.tokenRefreshInterval);
  }

  // Handle any auth errors that may occur
  async onAuthError(error: firebase.auth.Error) {
    // Log the error
    console.error(error);

    // Get the enqueueSnackbar helper from the props
    const { enqueueSnackbar } = this.props;

    // Grab the message from the error
    const errorMessage = getErrorMessage(error);

    // Notify the user of the error
    enqueueSnackbar(errorMessage, {
      variant: 'error'
    });

    // Update the state to logout the user
    await this.resetState(null, errorMessage);

    // Log the user out
    await firebase.auth().signOut();
  }

  async setTimeZoneHandler(role: string, userId: string) {
    if (userId) {
      let userRole = role;
      if (userRole === 'student') {
        userRole = 'students'; // Change 'student' to 'students'
      } else if (userRole === 'tutor') {
        userRole = 'tutors'; // Change 'tutor' to 'tutors'
      }

      if (userRole === 'students' || userRole === 'tutors') {
        // Get the user's time zone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Set the user's time zone using Firestore
        const userDocRef = firebase
          .firestore()
          .collection(userRole)
          .doc(userId);

        try {
          await userDocRef.set(
            {
              profile: {
                ...(userTimeZone ? { timeZone: userTimeZone } : {})
              }
            },
            { merge: true }
          );
          console.log('Time zone set successfully');
        } catch (error) {
          console.error('Error setting time zone:', error);
          // Handle the error as needed
        }
      } else if (userRole === 'admin') {
        console.log('User is an admin. No need to set the time zone.');
      } else {
        console.error('Unknown user role:', userRole);
      }
    } else {
      console.error('User not authenticated.');
    }
  }

  async onAuthSuccess(
    user: firebase.User,
    tokenResult: firebase.auth.IdTokenResult
  ) {
    // Identify the user on LogRocket
    LogRocket.identify(user.uid, {
      name: user.displayName || 'no-name',
      email: user.email || 'no-email',
      emailVerified: user.emailVerified,
      phone: user.phoneNumber || 'no-phone',
      role: tokenResult.claims.role || 'no-role'
    });

    // Create a new state object based on the tokenResult
    const newState = {
      user,
      token: `Bearer ${tokenResult.token}`,
      role: tokenResult.claims.role || 'student',
      hasLoaded: true,
      authError: null
    };

    await this.setTimeZoneHandler(tokenResult.claims.role, user.uid);

    // this.api.auth.setTimeZone

    // Set the credentials of the API client & update the state
    this.api.setCredentials(newState);
    this.setState(newState, async () => {
      // Create an interval that refreshes the firebase token
      // every 50 mins
      this.tokenRefreshInterval = setInterval(async () => {
        // We need to get the user's firebase id token in order to use custom claims
        const newTokenResult = await user.getIdTokenResult(true);

        // Set the credentials of the API client
        this.api.setCredentials({
          ...this.state,
          token: `Bearer ${newTokenResult.token}`
        });
      }, 3000000);
    });
  }

  async onAuthStateChanged(newUser: firebase.User | null): Promise<any> {
    // Grab the user from the state
    const { user } = this.state;

    // Log the current authentication status to the console.
    console.log(
      'AuthProvider',
      `AUTH STATE: Logged ${newUser != null ? 'IN' : 'OUT'} (Firebase user is ${
        newUser == null ? 'NULL' : 'NOT NULL'
      })`
    );

    // Clear the token refresh interval
    if (this.tokenRefreshInterval) clearInterval(this.tokenRefreshInterval);

    // Check whether the user is authenticated.
    if (newUser != null) {
      try {
        // We need to get the user's firebase id token in order to use custom claims
        const tokenResult = await newUser.getIdTokenResult(true);

        // Trigger the authentication success
        this.onAuthSuccess(newUser, tokenResult);

        return;
      } catch (loginError) {
        // Log the error to the console.
        console.error(loginError);

        // Handle the authentication error
        this.onAuthError({
          code: 'auth/unknown',
          message:
            (loginError as firebase.auth.Error).message ||
            'Sorry - there was an error logging in, please try again.'
        });
      }
    } else {
      // If the user isn't null, reset the state
      if (user !== null) {
        this.resetState(null);
      }
    }
  }

  // Sign in and sign out methods to be exposed
  async authSignIn(credentials: any) {
    // Reset the state to show the loading animation
    await this.resetState();

    const provider: any = {
      // Email auth
      email: async (data: any) =>
        firebase.auth().signInWithEmailAndPassword(data[1], data[2]),

      // Google auth
      google: async () =>
        firebase
          .auth()
          .signInWithRedirect(new firebase.auth.GoogleAuthProvider()),

      // Facebook auth
      facebook: async () =>
        firebase
          .auth()
          .signInWithRedirect(new firebase.auth.FacebookAuthProvider()),

      // Twitter auth
      twitter: async () =>
        firebase
          .auth()
          .signInWithRedirect(new firebase.auth.TwitterAuthProvider())
    };

    // Wrap in a try-catch to handle any errors
    try {
      // Login the user
      await provider[credentials[0]](credentials);
    } catch (error) {
      // Log the error to the console
      console.error(error as firebase.auth.Error);

      // Some errors don't need to be treated as such (i.e. users closing the login popoup)
      if (
        !['auth/popup-closed-by-user'].includes(
          (error as firebase.auth.Error).code
        )
      ) {
        // Trigger an auth error
        this.onAuthError(error as firebase.auth.Error);
      } else {
        // Else just reset the state
        this.resetState(null);
      }
    }
  }

  // Sign in and sign out methods to be exposed
  async authSignOut() {
    await this.resetState(null);
    await firebase.auth().signOut();
  }

  resetState(user: null | undefined = undefined, error = null) {
    return new Promise<void>((resolve) => {
      // Update the API client's credentials as well
      this.api.setCredentials(null);

      // Reset the state back to it's original auth status
      this.setState(
        {
          user,
          token: undefined,
          role: undefined,
          hasLoaded: true,
          authError: error
        },
        () => {
          resolve();
        }
      );
    });
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          signIn: this.authSignIn,
          signOut: this.authSignOut,
          api: this.api,
          stripe: this.stripe,
          isDevBuild: this.config.IS_DEV_BUILD,
          ...this.state
        }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export type { State };
export default withSnackbar(AuthProvider);
