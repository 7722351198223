// Externals
import classNames from 'classnames';

// Shared helpers
import { getInitials } from 'helpers';

// Material helpers
import { Avatar, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1.5),
    width: 40,
    height: 40
  },
  title: {
    fontWeight: 500
  },
  subtitle: {
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  }
});

const PortletLabelAvatar = (props) => {
  const { classes, className, avatar, name, title, subtitle, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div {...rest} className={rootClassName}>
      <Avatar src={avatar} alt={name} className={classes.avatar}>
        <Typography variant="h5" style={{ color: 'white' }}>
          {getInitials(name || '')}
        </Typography>
      </Avatar>
      {title && (
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography className={classes.subtitle} variant="subtitle2">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(PortletLabelAvatar);
