import { PureComponent, ReactNode, ReactNodeArray } from 'react';
import { Status } from 'views/shared';

interface Props {
  children: ReactNode | ReactNodeArray;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: Error, errorInfo: any): void {
    console.error(error, errorInfo);
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Status
          title="Woah there!"
          message="The application crashed. Please reload the page to try again!"
          showReloadBtn
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
