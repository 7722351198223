import { useContext } from 'react';

// Material UI imports
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
  Avatar,
  Chip,
  makeStyles
} from '@material-ui/core';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Luxon date helpers
import { DateTime } from 'luxon';

// History provider
import { useHistory } from 'react-router-dom';

// Auth context for useContext hook
import { AuthContext } from 'services/auth';

// To handle error messages
import { getInitials } from 'helpers';

// Portlet items
import {
  Portlet,
  PortletContent,
  AsyncStatus,
  PortletHeader,
  PortletLabel
} from 'components';
import { getParamByParam } from 'iso-country-currency';

// Style
import styles from './styles';
const useStyles = makeStyles(styles);

function BookingsCard(props) {
  const { bookings, tutor } = props;
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const onLessonClick = (uid) => {
    if (auth.role === 'tutor') {
      history.push({
        pathname: '/tutor/bookings',
        state: { isHistory: true }
      });
    } else {
      history.push(`/admin/users/${uid}`);
    }
  };

  return (
    <Portlet className={classes.root}>
      <PortletContent className={classes.content} noPadding>
        <PortletHeader>
          <PortletLabel title="List of Included Lessons" />
        </PortletHeader>
        {(() => {
          if (!bookings) {
            return (
              // Return the loading animation
              <div className={classes.loadingInner}>
                <List className={classes.fullList} disablePadding>
                  {[0, 1, 2].map((index) => (
                    <ListItem divider={index !== 2} key={index} disableGutters>
                      <ListItemAvatar>
                        <Skeleton width={40} height={40} variant="circle" />
                      </ListItemAvatar>
                      <Skeleton width="100%" />
                    </ListItem>
                  ))}
                </List>
              </div>
            );
          } else if (bookings.length === 0) {
            // Notify the user that they have no unpaid lessons.
            return <AsyncStatus error="You have no unpaid lessons" />;
          }
          // Return a rendered list of the items.
          return (
            <List className={classes.fullList} disablePadding>
              {bookings.map((booking, index) => (
                <ListItem
                  divider={index !== bookings.length - 1}
                  key={booking.id}
                  button
                  onClick={() => onLessonClick(booking.student)}>
                  <ListItemAvatar>
                    <Avatar
                      alt={booking.profile.student.name}
                      src={booking.profile.student.image}>
                      <Typography variant="h5" style={{ color: 'white' }}>
                        {getInitials(booking.profile.student.name || '')}
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={booking.profile.student.name}
                    secondary={DateTime.fromMillis(booking.when).toFormat(
                      'dd LLL h:mm a'
                    )}
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      label={
                        booking.payment
                          ? `${
                              tutor?.profile?.currency
                                ? getParamByParam(
                                    'currency',
                                    tutor?.profile?.currency,
                                    'symbol'
                                  )
                                : 'Currency Not Found'
                            } ${booking.payment.rate.toFixed(2)}`
                          : `${
                              tutor?.profile?.currency
                                ? getParamByParam(
                                    'currency',
                                    tutor?.profile?.currency,
                                    'symbol'
                                  )
                                : 'Currency Not Found'
                            } ?`
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          );
        })()}
      </PortletContent>
    </Portlet>
  );
}

export default BookingsCard;
