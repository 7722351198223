import {
  ReactElement,
  useCallback,
  useContext,
  useState,
  useEffect
} from 'react';

// Material helpers
import { Grid, Typography, Button } from '@material-ui/core';

// Authentication context
import { AuthContext } from 'services/auth';

// Snackbar helper
import { useSnackbar } from 'notistack';

// To handle error messages
import { getErrorMessage } from 'helpers';
import firebase from 'firebase/app';
// Component styles
import useStyles from './styles';

interface Props {
  title?: string;
  message?: string;
  image?: string;
  imageAlt?: string;
  hideImage?: boolean;
  showSignOutBtn?: boolean;
  showVerifyEmailBtn?: boolean;
  showReloadBtn?: boolean;
  id?: string;
  displayName?: string;
  email?: string;
  expireTime?: string;
}

const Status = (props: Props): ReactElement => {
  const [canVerify, setCanVerify] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useContext(AuthContext);
  const classes = useStyles();

  useEffect(() => {
    const checkUserDetails = async () => {
      if (props.expireTime) {
        const user = firebase.auth().currentUser;
        const id: string = props.id ? props.id : '';
        const updatedDisplayName: string = props.displayName || '';
        const updatedEmail: string = props.email || '';

        if (user?.uid === props?.id) {
          const updatedUser = {
            uid: id,
            displayName: updatedDisplayName,
            email: updatedEmail,
            providerId: '',
            phoneNumber: '',
            photoURL: ''
          };
          const expireTime = props.expireTime ? props.expireTime : '0';

          try {
            const postStudentUpdate =
              auth &&
              (await auth.api.profile.confirmUpdateStudentProfile(
                updatedUser,
                expireTime
              ));

            if (postStudentUpdate) {
              enqueueSnackbar('Profile has been updated successfully', {
                variant: 'success'
              });
              setTimeout(() => {
                window.location.href = '/';
              }, 1000);
            } else {
              enqueueSnackbar('Something went wrong, please try again', {
                variant: 'error'
              });
            }
          } catch (error) {
            // Log the error to the console
            console.error(error);

            // Show an error snackbar
            enqueueSnackbar(getErrorMessage(error), {
              variant: 'error'
            });
          }
        }
      }
    };
    checkUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyEmail = useCallback(async () => {
    // Wrap in try-catch to handle errors
    try {
      setCanVerify(false);
      enqueueSnackbar('Re-sending verification email...');

      // Send the verification email
      await auth?.api.auth.verify();
      enqueueSnackbar('Verification email re-sent!', { variant: 'success' });

      // Re-enable the verirication button
      setCanVerify(true);
    } catch (error) {
      // Log the error to the console
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">{props.title || 'Woah there!'}</Typography>
            <Typography variant="h5" className={classes.sub}>
              {props.message ||
                'Welp. Looks like something went wrong. Please logout and try again.'}
            </Typography>
            <div className={classes.actions}>
              {props.showSignOutBtn ? (
                <Button
                  className={classes.signOut}
                  onClick={auth?.signOut}
                  variant="contained"
                  color="primary">
                  Sign Out
                </Button>
              ) : null}
              {props.showReloadBtn ? (
                <Button
                  className={classes.signOut}
                  onClick={() => location.reload()}
                  variant="contained"
                  color="primary">
                  Reload
                </Button>
              ) : null}
              {props.showVerifyEmailBtn ? (
                <Button
                  className={classes.verifyEmail}
                  disabled={!canVerify}
                  onClick={handleVerifyEmail}
                  variant="contained"
                  color="primary">
                  resend verification email
                </Button>
              ) : null}
            </div>
            {!props.hideImage && (
              <img
                alt={props.imageAlt || 'Status Image'}
                className={classes.image}
                src={props.image || '/images/not_found.png'}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Status;
