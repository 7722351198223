// Material UI imports
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(6.5)
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    width: 125,
    height: 125,
    marginBottom: theme.spacing(2.5),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontWeight: 700
  },
  videoRoot: {
    display: 'flex',
    position: 'relative',
    width: 500,
    height: 275,
    [theme.breakpoints.down('sm')]: {
      width: 350,
      height: 175
    }
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 16
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
    '& .MuiFab-root': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  content: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(2)
  },
  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    color: 'white',
    background: 'rgba(0,0,0,0.7)'
  }
}));
