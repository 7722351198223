function prettyBookingStatus(status, role) {
  switch (status) {
    default:
      return '';
    case 'pending':
      return 'Unbooked';
    case 'booked':
      return 'Upcoming';
    case 'in-progress':
      return 'In Progress';
    case 'tutor-absent':
      return `Teacher Absent${role === 'student' ? ' (Refunded)' : ''}`;
    case 'student-absent':
      return 'Student Absent';
    case 'pending-feedback':
      return 'No Feedback';
    case 'tutor-present':
      return 'Teacher Present';
    case 'tutor-cancelled':
      return 'Teacher Cancelled';
    case 'student-cancelled':
      return 'Student Cancelled';
    case 'completed':
      return 'Completed';
  }
}

export default prettyBookingStatus;
