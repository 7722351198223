import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderTop: `1px solid ${theme.palette.divider}`
    },
    rootResponsive: {
      position: 'fixed',
      bottom: 0
    },
    hangUpBtn: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    btnControl: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down(370)]: {
        marginLeft: 4,
        marginRight: 4
      }
    }
  });
