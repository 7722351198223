/* eslint-disable react/jsx-props-no-spreading */

// Material helpers
import { useTheme, useMediaQuery } from '@material-ui/core';

// Dialog
import Dialog from './Dialog';

function ResponsiveDialogWrapper(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return <Dialog fullScreen={fullScreen} {...props} />;
}

export default function ResponsiveDialog(props) {
  return <ResponsiveDialogWrapper {...props} />;
}
