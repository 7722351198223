export default (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    transition: 'all 200ms cubic-bezier(0, 0, 0, 0.98)',
    '&:hover': {
      opacity: '0.6',
      transform: 'scale(1.04)',
      '&:active': {
        opacity: '0.8',
        transform: 'scale(1.02)'
      }
    },
    minHeight: '130px'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginBottom: theme.spacing(2)
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    maxHeight: '100%'
  },
  icon: {
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  details: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'right',
    justifyContent: 'right',
    wordWrap: 'break-word',
    textAlign: 'right'
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    wordWrap: 'break-word',
    textAlign: 'center'
  },
  desc: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1)
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1.5)
  }
});
