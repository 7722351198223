// Material helpers
import {
  Typography,
  Button,
  CircularProgress,
  TypographyVariant,
  makeStyles
} from '@material-ui/core';

// To handle error messages
import getErrorMessage from 'helpers/getErrorMessage';

interface Props {
  loading?: boolean;
  error?: string;
  textVariant?: TypographyVariant;
  retry?: boolean;
  retrySize?: 'medium' | 'large' | 'small';
  retryText?: string;
  gutterTop?: boolean;
  gutterBottom?: boolean;
  fill?: boolean;
  onRetry?: () => void;
}

// Component styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  fill: {
    flexGrow: 1
  },
  retryButton: {
    marginTop: theme.spacing(2)
  },
  gutterTop: {
    marginTop: theme.spacing(1.5)
  },
  gutterBottom: {
    marginBottom: theme.spacing(1.5)
  }
}));

function AsyncStatus(props: Props): JSX.Element {
  const {
    loading,
    error,
    textVariant,
    retry,
    retrySize,
    retryText,
    gutterTop,
    gutterBottom,
    fill = true,
    onRetry
  } = props;
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div
      className={`${classes.root} ${fill ? classes.fill : ''} ${
        gutterTop ? classes.gutterTop : ''
      } ${gutterBottom ? classes.gutterBottom : ''}`}>
      {loading && (
        <div>
          <CircularProgress />
        </div>
      )}
      {error && (
        <Typography color="textSecondary" variant={textVariant || 'h5'}>
          {getErrorMessage(error)}
        </Typography>
      )}
      {retry && (
        <Button
          className={classes.retryButton}
          size={retrySize || 'small'}
          variant="outlined"
          color="primary"
          onClick={onRetry}>
          {retryText || 'retry'}
        </Button>
      )}
    </div>
  );
}

export default AsyncStatus;
