import { PureComponent } from 'react';

// Material helpers
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  // CircularProgress,
  withStyles,
  // Grid,
  Typography
} from '@material-ui/core';

// Define the component styels
const styles = () => ({
  content: {
    padding: '0!important',
    overflow: 'hidden',
    '& iframe': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    }
    // minHeight: '80vh'
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  contentSpeedTest: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '50px'
  },
  link: {
    fontSize: '20px'
  }
});

class ResponsiveDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      // loaded: false
    };
  }

  render() {
    // Get the classes from component props
    const { classes, open, onClose, fullScreen } = this.props;
    // const { loaded } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        fullWidth
        maxWidth="lg">
        <DialogContent className={classes.content}>
          {/* <iframe
            onLoad={() => {
              this.setState({ loaded: true });
            }}
            title="Speed Test"
            frameBorder="0"
            src="https://icae.speedtestcustom.com"
          /> */}
          {/* {loaded ? null : (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          )} */}

          <div className={classes.contentSpeedTest}>
            <a
              className={classes.link}
              target="_blank"
              rel="noreferrer"
              href="https://www.speedtest.net/">
              https://www.speedtest.net/
            </a>
            <Typography variant="h2">
              Use the above speedtest link to test your internet speed.
            </Typography>
            <Typography variant="h2">
              We recommend min 1Mbps upload and download speed for smooth video
              connection.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ResponsiveDialog);
