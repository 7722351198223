export default () => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  rootResponsive: {
    display: 'flex'
  },
  viewer: {
    width: '100%',
    height: '100%'
  },
  hidden: {
    display: 'none'
  },
  status: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justfiyContent: 'center'
  }
});
