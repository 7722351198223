import validate from 'validate.js';

validate.validators.checked = (value, options) => {
  if (value !== true) return options.message || 'must be checked';
  return null;
};

export default {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 48
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 48
    }
  },
  dob: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      maximum: 64
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    equality: 'password'
  },

  gender: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  introduction: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 200,
      maximum: 600
    }
  },
  // video: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 64
  //   }
  // },

  // video: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   format: {
  //     pattern:
  //       '^(https?://)?(www\\.)?(youtube\\.com/watch\\?v=|youtu\\.be/)[a-zA-Z0-9_-]{11}$'
  //   }
  // },
  video: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      // pattern: '^(https?://)?(www\\.)?youtube\\.com/watch\\?v=.+$'
      pattern: '^(https?://)?(www\\.)?(youtube\\.com/watch\\?v=|youtu\\.be/).+$'
    }
  },

  nationality: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  school: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 48
    }
  },
  image: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  cv: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  degree: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  policy: {
    presence: { allowEmpty: false, message: ' ' },
    checked: true
  },
  tutorPolicy: {
    presence: { allowEmpty: false, message: ' ' },
    checked: true
  }
};
