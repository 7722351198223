export default {
  pronunciation: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  vocabulary: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  grammar: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  listening: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  behaviour: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  }
};
