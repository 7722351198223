import { PureComponent, createRef } from 'react';

// Material UI components / helpers
import { withStyles } from '@material-ui/core';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Authentication
import { withAuth } from 'services/auth';

// Tab container
import { TabContainer } from 'components';

// Export cleanup
import compose from 'recompose/compose';

// Import the tab views
import {
  HistoryBookingsView,
  UpcomingBookingsView,
  ViewFeedbackDialog
} from './components';

// Bookings search component
import SearchView from 'views/student/Schedule';

// Router props
import { withRouter } from 'react-router';

// So we can parse the initial view prop
import queryString from 'query-string';

// Component styles
import styles from './styles';

class Bookings extends PureComponent {
  constructor(props) {
    super(props);

    // Grab the auth provider from the props
    const { auth, location } = props;

    // View feedback dialog ref
    this.viewFeedbackDialog = createRef();

    // Create an array of the tab names to populate the tab container
    const tabs = ['upcoming', 'history'];
    if (auth.role === 'student') tabs.unshift('search');

    // Parse the query string, and see if the supplied view exists with the tab
    const tabIndex = location.state?.isHistory
      ? 1
      : tabs.indexOf(queryString.parse(location.search).view);

    // Initialize bookings state
    this.state = {
      tabIndex: tabIndex > 0 ? tabIndex : 0
    };
  }

  render() {
    const { auth, classes } = this.props;
    const { tabIndex } = this.state;

    // Define the TabContainer tabs
    const tabs = [
      {
        label: 'upcoming',
        component: (
          <UpcomingBookingsView
            onUpdateTab={(index) => this.setState({ tabIndex: index })}
            onViewFeedback={(booking) => {
              this.viewFeedbackDialog.current.open(booking);
            }}
          />
        )
      },
      {
        label: 'history',
        component: (
          <HistoryBookingsView
            onUpdateTab={(index) => this.setState({ tabIndex: index })}
            onViewFeedback={(booking) => {
              this.viewFeedbackDialog.current.open(booking);
            }}
          />
        )
      }
    ];

    // If we're a student, add the bookings search component
    if (auth.role === 'student') {
      tabs.unshift({
        label: 'search',
        component: (
          <SearchView
            onUpdateTab={(index) => this.setState({ tabIndex: index })}
          />
        )
      });
    }

    return (
      <>
        <ViewFeedbackDialog auth={auth} ref={this.viewFeedbackDialog} />
        <div className={classes.root}>
          <TabContainer
            tabs={tabs}
            index={tabIndex}
            onChange={(newIndex) => this.setState({ tabIndex: newIndex })}
          />
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  withSnackbar,
  withAuth,
  withStyles(styles)
)(Bookings);
