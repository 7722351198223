import validate from 'validate.js';

validate.validators.checked = (value, options) => {
  if (value !== true) return options.message || 'must be checked';
  return null;
};

export default {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  confirmEmail: {
    equality: 'email'
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      maximum: 128
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    equality: 'password'
  },
  // about: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     minimum: 15,
  //     maximum: 128,
  //   },
  // },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};
