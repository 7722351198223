export default (theme) => ({
  entries: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  info: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    fontWeight: 'bold',
    color: theme.palette.text.secondary
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});
