export default (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(5),
    textAlign: 'center'
  },
  sub: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2)
  },
  steps: {
    marginTop: theme.spacing(2)
  },
  step: {
    marginBottom: theme.spacing(0.5)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      marginLeft: theme.spacing(2)
    }
  },
  actions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiButtonBase-root': {
      display: 'inline-block'
    }
  },
  confirmButton: {
    marginRight: theme.spacing(1)
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  }
});
