import { PureComponent } from 'react';

// React router components
import { Link as RouterLink, withRouter } from 'react-router-dom';

// Validation helper
import validate from 'validate.js';

// Import material components
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Grow,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Authentication provider
import { withAuth } from 'services/auth';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Error message helper
import { getErrorMessage } from 'helpers';

// Cleanup exports
import compose from 'recompose/compose';

// Component styles
import styles from './styles';

// Import the schema
import schema from './schema';

class PasswordReset extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      isValid: false,
      resetting: false,
      values: {},
      touched: {},
      errors: {}
    };

    // Bind the required functions
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.hasError = this.hasError.bind(this);
  }

  handleChange(event) {
    // Grab the values from the state
    const { values, touched } = this.state;

    // Get the newly changed values
    const newValues = {
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };

    // Validate the state's values
    const errors = validate(newValues, schema);

    // Update the state
    this.setState({
      values: newValues,
      touched: {
        ...touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    });
  }

  handleBack() {
    // Grab the history provider from the props
    const { history } = this.props;

    // Navigate back
    history.goBack();
  }

  async handleReset(event) {
    // Prevent an actual form submit from occuring
    event.preventDefault();

    // Grab the auth provider from the props and state info
    const { isValid, values } = this.state;
    const { auth, enqueueSnackbar } = this.props;

    // Check whether the sign up form is valid
    if (isValid) {
      try {
        // Disable the form elements
        this.setState({ resetting: true });

        // Notify the user of the password reset
        enqueueSnackbar('Sending password reset email...');

        // Delete the user
        await auth.api.auth.reset(values.email);

        // Re-enable the form elements
        this.setState({ resetting: false, values: {} });

        // Notify the user of the password reset success
        enqueueSnackbar('Password reset email sent!', { variant: 'success' });
      } catch (error) {
        // Log the error to the console.
        console.error(error);

        // Show an error snackbar
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      }
    }
  }

  hasError(field) {
    // Grab the touched and errors state info
    const { touched, errors } = this.state;
    return !!(touched[field] && errors[field]);
  }

  render() {
    // Grab the component's classes from its props
    const { classes } = this.props;

    // Grab the state info
    const { resetting, isValid, errors, values } = this.state;

    // Render the sign up page
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack} disabled={resetting}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleReset}>
                  <div className={classes.formHeader}>
                    <div>
                      <Typography variant="h2">Reset your password</Typography>
                      <Typography
                        className={classes.subtitle}
                        color="textSecondary"
                        gutterBottom>
                        Don&apos;t worry! We&apos;re all a little forgetful
                        sometimes.
                      </Typography>
                    </div>
                    <span className={classes.spacer} />
                    <Grow in={resetting}>
                      <div>
                        <CircularProgress />
                      </div>
                    </Grow>
                  </div>
                  <TextField
                    className={classes.textField}
                    error={this.hasError('email')}
                    fullWidth
                    helperText={this.hasError('email') ? errors.email[0] : null}
                    label="Email address"
                    name="email"
                    onChange={this.handleChange}
                    type="text"
                    value={values.email || ''}
                    variant="outlined"
                    disabled={resetting}
                  />
                  <Button
                    className={classes.signUpButton}
                    color="primary"
                    disabled={!isValid || resetting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    send reset email
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    Just remembered it?{' '}
                    <Link component={RouterLink} to="/signin" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Welcome to e-English!
                </Typography>
                <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant="body1"
                    color="textSecondary">
                    e-English Team
                  </Typography>
                  {/* <Typography className={classes.bio} variant="body2">
                    The e-English Team
                  </Typography> */}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withAuth,
  withSnackbar,
  withRouter,
  withStyles(styles)
)(PasswordReset);
