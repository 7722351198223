export default (theme) => ({
  list: {
    minWidth: 160
  },
  subheader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  btnReload: {
    marginLeft: theme.spacing(0.5)
  }
});
