/* eslint-disable react/jsx-props-no-spreading */
import { lazy } from 'react';
import { View } from '..';

import { importRetry as retry } from 'helpers';

// Icons
import {
  DashboardRounded as DashboardIcon,
  LibraryBooksRounded as LibraryIcon,
  // AccountBoxRounded as AccountBoxIcon,
  // SettingsRounded as SettingsIcon,
  CalendarTodayRounded as ScheduleIcon,
  CollectionsBookmarkRounded as BookingIcon,
  AccountBalanceRounded as MoneyIcon,
  FormatListBulleted as TrainingIcon
} from '@mui/icons-material';

// View components
const Dashboard = lazy(() => retry(() => import('views/tutor/Dashboard')));
const Schedule = lazy(() => retry(() => import('views/tutor/Schedule')));
const Courses = lazy(() => retry(() => import('views/tutor/Courses')));
const Training = lazy(() => retry(() => import('views/tutor/Training')));
// const Account = lazy(() => retry(() => import('views/tutor/Account'));
// const Settings = lazy(() => retry(() => import('views/tutor/Settings'));

// Shared view components
const Materials = lazy(() => retry(() => import('views/shared/Materials')));
const Bookings = lazy(() => retry(() => import('views/shared/Bookings')));
const Announcements = lazy(() =>
  retry(() => import('views/shared/Announcements'))
);
const Income = lazy(() => retry(() => import('views/shared/Income')));
// const UnderDevelopment = lazy(() => retry(() => import('views/shared/UnderDevelopment')));

const views: View[] = [
  {
    route: '/tutor/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />,
    component: Dashboard
  },
  {
    route: '/tutor/dashboard/announcements',
    component: Announcements
  },
  {
    route: '/tutor/materials',
    text: 'Materials',
    icon: <LibraryIcon />,
    component: Materials
  },
  {
    route: '/tutor/materials/:material',
    component: Courses
  },
  {
    route: '/tutor/schedule',
    text: 'Schedule',
    icon: <ScheduleIcon />,
    component: Schedule
  },
  {
    route: '/tutor/bookings',
    text: 'Bookings',
    icon: <BookingIcon />,
    component: Bookings
  },
  {
    route: '/tutor/income',
    text: 'Income',
    icon: <MoneyIcon />,
    // eslint-disable-next-line react/display-name
    component: () => <Income padding />
  },
  {
    route: '/tutor/training',
    text: 'Training',
    icon: <TrainingIcon />,
    component: Training
  }
  /* ,
  {
    route: '/tutor/account',
    text: 'Account',
    icon: <AccountBoxIcon />,
    component: props => {
      return <Account {...props} />;
    }
  },
  {
    route: '/tutor/settings',
    text: 'Settings',
    icon: <SettingsIcon />,
    component: props => {
      return <Settings {...props} />;
    }
  } */
];

export default views;
