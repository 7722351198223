import { createStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) =>
  createStyles({
    refreshButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    currencyText: {
      marginRight: theme.spacing(0.5)
    },
    currencyItem: {
      display: 'flex',
      flexDirection: 'row'
    },
    icon: {
      width: 24,
      height: 24
    },
    listBottom: {
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    }
  });
