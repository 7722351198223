interface Dictionary {
  [key: string]: any;
}

export default class Store {
  store: Dictionary;
  constructor(initialValue: Dictionary | null = null) {
    this.store = initialValue || {};
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: string, value: any): void {
    this.store[key] = value;
  }

  get(key: string): any {
    return this.store[key];
  }

  clear(): void {
    this.store = {};
  }
}
