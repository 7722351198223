// Palette
import palette from '../palette';

export default {
  root: {
    borderRadius: 8
  },
  contained: {
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral
    }
  }
};
