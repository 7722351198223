import React, { useEffect, useRef, useState } from 'react';
import { Paper } from 'components';
// Style
import useStyles from './styles';
import useWindowSize from 'hooks/useWindowSize';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';

export const ScreenShare = ({ screenShareTrack }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const screenShareRef = useRef(null);
  // Generate component styles
  const classes = useStyles();
  const [width] = useWindowSize();

  useEffect(() => {
    if (screenShareTrack && screenShareRef.current) {
      screenShareTrack.attach(screenShareRef.current);
      return () => {
        screenShareTrack.detach();
      };
    }
  }, [screenShareTrack]);

  if (!screenShareTrack) {
    return null; // Do not render anything if there's no screen share track
  }

  // Add a condition to check if the window width is between 500 and 900
  const marginTopStyle =
    width >= 500 && width <= 900 ? { marginTop: '10px', padding: '8px' } : {};

  const widthandHeightStyle =
    width >= 500 && width <= 900 ? { width: '30px', height: '30px' } : {};

  const widthandHeightofIconStyle =
    width >= 500 && width <= 900 ? { width: '20px', height: '20px' } : {};

  return (
    <Paper
      className={
        width > 900
          ? classes.videoRoot
          : width >= 500 && width <= 900
          ? classes.videoRootLandscape
          : classes.videoRootResponsive
      }
      style={{
        width: isFullScreen
          ? width < 500
            ? 280
            : width >= 500 && width <= 900
            ? 250 // Set the width for full-screen between 500 and 900
            : 310
          : width < 500
          ? 230
          : width >= 500 && width <= 900
          ? 250
          : 310,
        height: isFullScreen
          ? width < 500
            ? 158
            : width >= 500 && width <= 900
            ? 130 // Set the height for full-screen between 500 and 900
            : 175
          : width < 500
          ? 130
          : width >= 500 && width <= 900
          ? 58
          : 175
      }}
      elevation={1}>
      <video ref={screenShareRef} className={classes.video} autoPlay={true} />
      {width < 900 && (
        <div className={classes.contentFullScreen} style={marginTopStyle}>
          <div
            className={classes.indicatorFullScreen}
            style={widthandHeightStyle}
            onClick={() => setIsFullScreen(!isFullScreen)}>
            {isFullScreen ? (
              <FullscreenExit style={widthandHeightofIconStyle} />
            ) : (
              <Fullscreen style={widthandHeightofIconStyle} />
            )}
          </div>
        </div>
      )}
    </Paper>
  );
};
