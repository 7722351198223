export default (theme) => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: '1',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3.5),
    color: theme.palette.text.secondary,
    fontWeight: '400'
  }
});
