import { Children } from 'react';

// Externals
import classNames from 'classnames';

// Material components
import { Paper, withStyles } from '@material-ui/core';

// Component styles
const styles = (theme) => {
  return {
    squared: {
      borderRadius: 0
    },
    outlined: {
      border: `1px solid ${theme.palette.divider}`
    }
  };
};

const CustomPaper = (props) => {
  const { classes, className, outlined, squared, children, ...rest } = props;

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.squared]: squared,
      [classes.outlined]: outlined
    },
    className
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Paper elevation={2} {...rest} className={rootClassName}>
      {Children.toArray(children)}
    </Paper>
  );
};

export default withStyles(styles)(CustomPaper);
