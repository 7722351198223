import { StrictMode } from 'react';

import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
// import firebase from 'firebase/app';
import 'firebase/firestore';

// Service worker
import * as serviceWorker from './common/serviceWorker';

// App
import App from './App';
import AppConfig from './config';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

if (AppConfig.IS_DEV_BUILD) {
  // firebase.auth().useEmulator('http://localhost:9099/');
  // firebase.firestore().useEmulator('localhost', 8080);
} else {
  // Initialize LogRocket
  LogRocket.init('bb1y0p/e-english');
  setupLogRocketReact(LogRocket);
}

console.log(
  `%c${AppConfig.buildId}`,
  'font-size: 2em; font-weight: 800',
  `\n${JSON.stringify(AppConfig, null, 2)}`
);

// Initialize the service worker
serviceWorker.unregister();
