import { useState, useContext, useCallback, useEffect } from 'react';

// Material UI imports
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles
} from '@material-ui/core';

// Material UI icons
import {
  PaymentRounded as PaymentIcon,
  RefreshRounded as RefreshIcon
} from '@mui/icons-material';

// Material UI Lab
import { Skeleton } from '@material-ui/lab';

// Luxon date helpers
import { DateTime } from 'luxon';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  AsyncStatus
} from 'components';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Authentication & API provider
import { AuthContext } from 'services/auth';
import type { BillingHistoryItem } from 'services/auth/api/payments';

// Payment card brands icon
import {
  Visa as VisaIcon,
  Mastercard as MastercardIcon,
  Amex as AmexIcon
} from 'assets/icons';

// Style
import styles from './styles';
const useStyles = makeStyles(styles);

const getCardIcon = (brand: string) => {
  switch (brand) {
    default:
      return <PaymentIcon />;
    case 'mastercard':
      return <MastercardIcon />;
    case 'visa':
      return <VisaIcon />;
    case 'amex':
      return <AmexIcon />;
  }
};

interface Props {
  uid: string;
}

function BillingHistoryCard(props: Props): JSX.Element {
  // Setup constants
  const classes = useStyles();
  const auth = useContext(AuthContext);

  // Setup hook states
  const [history, setHistory] = useState<BillingHistoryItem[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [retry, setRetry] = useState<boolean>(false);

  useEffect(() => {
    async function retrieveBillingHistory() {
      try {
        // Retrive the current user's booking history from the API.
        const history = await auth?.api.payments.getBillingHistory(props.uid);

        // Update the component's state with the billing history.
        setHistory(history || null);
      } catch (error) {
        // Update the component's state to show an error
        setError(error as Error);

        // Log the error to the console.
        console.error(error);
      }
    }

    retrieveBillingHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  // API Retry callback
  const retryAPICall = useCallback(() => {
    setHistory(null);
    setError(null);
    setRetry(!retry);
  }, [retry]);

  // Return a rendered list of the items.
  return (
    <Portlet>
      <PortletHeader>
        <PortletLabel title="Billing History" />
        <PortletToolbar>
          <IconButton disabled={!history} onClick={retryAPICall}>
            <RefreshIcon />
          </IconButton>
        </PortletToolbar>
      </PortletHeader>
      <PortletContent style={{ height: 325 }} noPadding>
        <List disablePadding>
          {(() => {
            if (error) {
              // Display the error message and retry button
              return (
                <AsyncStatus
                  error={getErrorMessage(error)}
                  onRetry={retryAPICall}
                  retry
                />
              );
            }

            // If we haven't received our billing history yet, return a skeleton
            if (!history)
              return [0, 1, 2, 3].map((entry) => (
                <ListItem key={entry} divider={entry !== 3}>
                  <ListItemIcon>
                    <Skeleton width={22} height={22} variant="circle" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Skeleton>
                        <Typography>Lesson Tokens</Typography>
                      </Skeleton>
                    }
                    secondary={
                      <Skeleton>
                        <Typography variant="subtitle2">
                          $10.00 AUD, **** **** ****
                        </Typography>
                      </Skeleton>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Skeleton>
                      <Typography>Purchase Date</Typography>
                    </Skeleton>
                  </ListItemSecondaryAction>
                </ListItem>
              ));

            // If our request didn't return any billing history
            if (history.length === 0) {
              // Notify the user that they have no upcoming sessions.
              return (
                <AsyncStatus
                  error="No transactions found"
                  textVariant="body1"
                />
              );
            }

            // Return the rendered history list
            return history.map((entry, index) => (
              <ListItem
                key={entry.id}
                component="a"
                button
                divider={index !== history.length - 1}
                href={entry.receipt}
                target="_blank"
                className={
                  history.length - 1 === index ? classes.listBottom : ''
                }>
                <ListItemIcon>{getCardIcon(entry.card.brand)}</ListItemIcon>
                <ListItemText
                  primary={entry.plan || 'Lesson Tokens'}
                  secondary={
                    <div className={classes.currencyItem}>
                      <Typography
                        variant="subtitle2"
                        className={classes.currencyText}>
                        ends with {entry.card.last4},{' '}
                        <b>${(entry.amount / 100).toFixed(2)}</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        {entry.currency.toUpperCase()}
                      </Typography>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <Typography>
                    {DateTime.fromMillis(entry.created * 1000).toLocaleString(
                      DateTime.DATE_SHORT
                    )}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ));
          })()}
        </List>
      </PortletContent>
    </Portlet>
  );
}

export default BillingHistoryCard;
