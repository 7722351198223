import { PureComponent } from 'react';

// Material UI imports
import { Typography, withStyles } from '@material-ui/core';

// Tab container
import { TabContainer } from 'components';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';

// Behaviour view
import BehaviourView from '../../_views/BehaviourView';

// Component styles
import styles from './styles';

class ViewFeedbackDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false,
      booking: undefined
    };

    // Bind component functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.confirm
    };
  }

  // Create a open function to hook into in
  // the parent component
  open(booking) {
    this.setState({
      open: true,
      booking
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({
      open: false
    });
  }

  confirm() {
    // Close the modal
    this.close();
    const { booking } = this.state;
    const { onViewFeedback } = this.props;
    if (onViewFeedback) {
      onViewFeedback(booking);
    }
  }

  render() {
    // Grab the component's state
    const { open, booking } = this.state;
    const feedback = booking && booking.feedback;

    // Grab the auth provider from the props
    const { classes, auth } = this.props;

    // Define the tabs to be rendered
    const tabs = [
      {
        label: 'feedback',
        component: (
          <>
            <Typography variant="h5">Pronunciation</Typography>
            <Typography>{feedback && feedback.pronunciation}</Typography>
            <Typography variant="h5" className={classes.header}>
              Vocabulary
            </Typography>
            <Typography>{feedback && feedback.vocabulary}</Typography>
            <Typography variant="h5" className={classes.header}>
              Grammar
            </Typography>
            <Typography>{feedback && feedback.grammar}</Typography>
            <Typography variant="h5" className={classes.header}>
              Listening
            </Typography>
            <Typography>{feedback && feedback.listening}</Typography>
          </>
        )
      }
    ];

    // Add the hidden behaviour tab if we're a tutor
    if (auth.role === 'tutor' || auth.role === 'admin') {
      tabs.push({
        label: 'behaviour',
        component: <BehaviourView booking={booking} />
      });
    }

    // Render the dialog
    return (
      <ResponsiveDialog
        title="Lesson Feedback"
        events={this.events}
        open={open}
        confirmText="okay"
        hideCancelButton>
        <TabContainer tabs={tabs} />
      </ResponsiveDialog>
    );
  }
}

export default withStyles(styles)(ViewFeedbackDialog);
