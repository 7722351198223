import { Fragment } from 'react';

import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material components
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';

// DateTime helpers
import { DateTime } from 'luxon';

// Material icons
import { ArrowForwardIosRounded as ArrowForwardIosIcon } from '@mui/icons-material';

// Delete confirm dialog
import { AsyncStatus } from 'components';

// Announcement icon helper
import { getAnnouncementIcon } from 'helpers';

// Component styles
import styles from './styles';

const useStyles = makeStyles(styles);

function AnnouncementList(props) {
  const {
    className,
    announcements,
    announcementsError,
    role,
    onSelect,
    onRetry
  } = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  if (announcementsError)
    return <AsyncStatus error={announcementsError} onRetry={onRetry} />;
  if (!announcements) return <AsyncStatus loading />;

  return (
    <div className={rootClassName}>
      {announcements.length > 0 ? (
        <>
          <div className={classes.header}>
            <Typography variant="h6">Announcements</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              {announcements.length} new announcement
              {announcements.length > 1 && 's'}
            </Typography>
          </div>
          <div className={classes.content}>
            <List component="div">
              {announcements.map((announcement) => {
                const icon = getAnnouncementIcon(announcement.icon);
                return (
                  <Fragment key={announcement.id}>
                    <ListItem
                      className={classes.listItem}
                      component="div"
                      onClick={() => {
                        onSelect(announcement);
                      }}>
                      <ListItemIcon className={classes.listItemIcon}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        classes={{ secondary: classes.listItemTextSecondary }}
                        primary={announcement.title}
                        secondary={DateTime.fromMillis(
                          announcement.when || 0
                        ).toRelative()}
                      />
                      <ArrowForwardIosIcon className={classes.arrowForward} />
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>
            <div className={classes.footer}>
              <Button
                color="primary"
                component={Link}
                size="small"
                to={
                  role === 'admin'
                    ? '/admin/announcements'
                    : `/${role}/dashboard/announcements`
                }
                variant="contained"
                onClick={() => onSelect()}>
                See all
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.empty}>
          <div className={classes.emptyImageWrapper}>
            <img
              alt="Empty list"
              className={classes.emptyImage}
              src="/images/empty.png"
            />
          </div>
          <Typography variant="h4">No new announcements</Typography>
        </div>
      )}
    </div>
  );
}

export default AnnouncementList;
