export default {
  topic: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  notes: {
    length: {
      maximum: 400
    }
  }
};
