import { Children } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex'
  }
});

const PortletToolbar = (props) => {
  const { classes, className, children, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} className={rootClassName}>
      {Children.toArray(children)}
    </div>
  );
};

export default withStyles(styles)(PortletToolbar);
