import { PureComponent } from 'react';
import { Link } from 'react-router-dom';

// Material helpers
import { Grid, Container, withStyles } from '@material-ui/core';

// Auth provider
import { withAuth } from 'services/auth';

// Compose for export cleanup
import compose from 'recompose/compose';

// To handle error messages
import { similar, getErrorMessage } from 'helpers';

// Async status
import { AsyncStatus } from 'components';

// Custom components
import { MaterialsToolbar, MaterialsCard } from './components';
import config from 'config';

// Component styles
import styles from './styles';

class Materials extends PureComponent {
  constructor(props) {
    super(props);

    // Set the ready flag to true
    this.ready = true;

    // Initialize the component's state
    this.state = {
      materials: undefined,
      searchMaterials: undefined,
      error: undefined,
      student: undefined,
      bucketName: ''
    };

    // Bind any event handlers
    this.handleSearch = this.handleSearch.bind(this);
    this.filterResults = this.filterResults.bind(this);
  }

  async componentDidMount() {
    // Grab the materials endpoint from the props
    const { auth } = this.props;

    const isProdBranch = config.IS_PROD_BRANCH;

    const bucketName = isProdBranch
      ? 'eng-tutor-app.appspot.com'
      : 'eng-tutor-app-dev.appspot.com';

    this.setState({ bucketName });

    // Wrap in a try-catch to handle any errors
    try {
      let materials = [];
      const studentData = await auth.api.profile.getStudentProfile(
        auth?.user?.uid
      );

      // Tutor's are allowed full access
      if (studentData?.stripe?.subscription || auth?.role == 'tutor') {
        // Retrieve the materials from the API
        materials = await auth.api.materials.getMaterials();
      } else {
        // Initialize sampleMaterials after bucketName is set
        this.sampleMaterials = [
          {
            description: 'This industry includes Massage Therapy',
            icon: '/images/materials/logo.png',
            id: 'health-&-lifestyle',
            name: 'Health & Lifestyle',
            visible: true,
            url: `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/materials%2Fhealth-%26-lifestyle%2Fsample%2FHealth%20%26%20Lifestyle%20_%20Lesson%20sample%20.pdf?alt=media&token=2fee1c2b-df79-40de-bf78-98ef5d4d3c8f`
          },
          {
            description: 'This industry includes Food & Beverage and Cookery',
            icon: '/images/materials/logo.png',
            id: 'hospitality',
            name: 'Hospitality',
            visible: true,
            url: `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/materials%2Fhospitality%2Fsample%2FHospitality%20_%20Lesson%20sample%20.pdf?alt=media&token=fc147214-a8ac-4015-98e7-bb401b6d95bc`
          },
          {
            description: 'This industry includes Tour Guide and Chauffeur',
            icon: '/images/materials/logo.png',
            id: 'tourism',
            name: 'Tourism',
            visible: true,
            url: `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/materials%2Ftourism%2Fsample%2FTourism%20_%20Lesson%20sample%20.pdf?alt=media&token=720d721e-6a80-4c6f-9dd4-6f9420457bb7`
          }
        ];
        materials = this.sampleMaterials;
      }

      // Update the state with the materials
      if (this.ready) {
        this.setState({
          materials,
          searchMaterials: materials,
          student: studentData
        });
      }
    } catch (error) {
      // If we're mounted, update the state with the error
      if (this.ready) {
        this.setState({
          error: getErrorMessage(error)
        });
      }
    }
  }

  componentWillUnmount() {
    // Reset the signal flag to false
    // to say that we're not mounted
    this.ready = false;
  }

  filterResults(searchText) {
    // Grab the materials from the state
    const { materials } = this.state;

    // Update the state
    this.setState({
      searchMaterials:
        searchText === ''
          ? materials
          : materials.filter((material) => similar(searchText, material.name))
    });
  }

  // Triggered when the user searches
  handleSearch(event) {
    this.filterResults(event.target.value);
  }

  renderMaterialItems() {
    const { auth, classes } = this.props;
    const { materials, searchMaterials, error } = this.state;

    if (error) return <AsyncStatus error={error} />;
    if (!materials) {
      return (
        <Grid container spacing={3}>
          {[0].map((index) => (
            <Grid item key={index} lg={4} md={6} xs={12}>
              <MaterialsCard loading />
            </Grid>
          ))}
        </Grid>
      );
    }
    if (materials.length === 0)
      return <AsyncStatus error="No materials retrived" />;
    return (
      <Grid container spacing={3}>
        {searchMaterials.map((material) => (
          <Grid item key={material.id} lg={4} md={6} xs={12}>
            {material?.url ? (
              <div
                className={classes.material}
                onClick={() => window.open(material.url, '_blank')}>
                <MaterialsCard material={material} />
              </div>
            ) : (
              <Link
                to={{
                  pathname: `/${auth.role}/materials/${material.id}`,
                  state: { material }
                  // eslint-disable-next-line react/jsx-closing-bracket-location
                }}>
                <MaterialsCard material={material} />
              </Link>
            )}
          </Grid>
        ))}
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { student } = this.state;
    const { auth } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <MaterialsToolbar
            onSearch={this.handleSearch}
            isStudentSubscribed={
              auth.role == 'tutor'
                ? true
                : student?.stripe?.subscription
                ? true
                : false
            }
          />
          <div className={classes.content}>{this.renderMaterialItems()}</div>
        </Container>
      </div>
    );
  }
}

export default compose(withAuth, withStyles(styles))(Materials);
