// Externals
import classNames from 'classnames';

// Material components
import { Divider, Typography, withStyles } from '@material-ui/core';

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  company: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  }
});

function Footer(props) {
  const { classes, className } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Divider />
      <Typography className={classes.company} variant="body1">
        &copy; e-English Pty Ltd, 2024
      </Typography>
    </div>
  );
}

export default withStyles(styles)(Footer);
