/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Authentication react context
import AuthContext from './context';

export default (WrappedComponent: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <AuthContext.Consumer>
      {(auth) => {
        return <WrappedComponent auth={auth} {...props} />;
      }}
    </AuthContext.Consumer>
  );
};
