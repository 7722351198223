import { LocalAudioTrack, LocalTrack } from 'twilio-video';
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid
} from '@material-ui/core';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import AudioMeter from 'views/shared/Call/components/LocalPreview/components/AudioMeter';

const AudioInputList = ({
  previewTracks
}: {
  previewTracks: LocalTrack[] | undefined;
}) => {
  const { audioInputDevices } = useDevices();

  const localAudioTrack = previewTracks?.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <AudioMeter audioTrack={localAudioTrack} />
      <Typography variant="subtitle2" gutterBottom style={{ marginTop: 20 }}>
        Audio Input
      </Typography>
      <Grid
        container
        style={{ justifyContent: 'center', alignItems: 'center' }}>
        <div className="inputSelect" style={{ width: '100%' }}>
          {audioInputDevices.length > 0 ? (
            <FormControl fullWidth>
              <Select
                onChange={(e) => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined">
                {audioInputDevices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </div>
      </Grid>
    </div>
  );
};

export default AudioInputList;
