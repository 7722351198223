// Material components
import { SvgIcon } from '@material-ui/core';

export default function Visa(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 36 24">
      <rect width="36" height="24" fill="#FFF" rx="4" />
      <path
        fill="#2A2A6C"
        fillRule="nonzero"
        d="M14.7,0.16 L12.7,9.41 L10.3,9.41 L12.3,0.16 L14.7,0.16 Z M24.76,6.16 L26.02,2.69 L26.75,6.16 L24.76,6.16 Z M27.43,9.44 L29.65,9.44 L27.71,0.19 L25.71,0.19 C25.2699622,0.194024697 24.8755073,0.462254034 24.71,0.87 L21.12,9.44 L23.64,9.44 L24.13,8.06 L27.14,8.06 L27.43,9.44 Z M21.19,6.44 C21.2,3.95 17.84,3.77 17.84,2.77 C17.84,2.44 18.16,2.09 18.84,2 C19.6492985,1.91992921 20.4651284,2.06166236 21.2,2.41 L21.62,0.41 C20.9036162,0.141357926 20.1450934,0.00252117226 19.38,3.55271368e-15 C17.02,3.55271368e-15 15.38,1.26 15.38,3.06 C15.38,4.39 16.57,5.13 17.47,5.57 C18.37,6.01 18.72,6.32 18.71,6.72 C18.71,7.34 17.97,7.62 17.28,7.63 C16.4288533,7.64095758 15.5890007,7.43443644 14.84,7.03 L14.41,9.03 C15.2574838,9.36084413 16.1602552,9.52714412 17.07,9.52 C19.58,9.52 21.23,8.28 21.24,6.36 M11.33,0.129990595 L7.45,9.38 L4.84,9.38 L2.93,1.99 C2.89644443,1.63826013 2.6797584,1.33033788 2.36,1.18 C1.60926521,0.821788549 0.816338092,0.559719418 3.55271368e-15,0.4 L0.06,0.129990595 L4.13,0.129990595 C4.68080612,0.127758691 5.15148141,0.52634858 5.24,1.07 L6.24,6.42 L8.73,0.129990595 L11.33,0.129990595 Z"
        transform="translate(3 8)"
      />
    </SvgIcon>
  );
}
