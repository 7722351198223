import { useContext, useEffect, useCallback, useState } from 'react';

// Authentication
import { AuthContext } from 'services/auth';

// Material UI components / helpers
import {
  Grid,
  makeStyles,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';

// Luxon date helpers
import { DateTime } from 'luxon';

// AsyncStatus
import { AsyncStatus } from 'components';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Local components
import { FigureCard, BookingsChart, BookingsListCard } from './components';
import { getParamByParam } from 'iso-country-currency';
import { Refresh } from '@mui/icons-material';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles);

const paymentReducer = (last, current) =>
  last + (current.payment ? current.payment.rate : 0);

function Income(props) {
  const auth = useContext(AuthContext);
  const classes = useStyles();

  // Setup component state
  const [bookings, setBookings] = useState(null);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(false);
  const [tutor, setTutor] = useState(null);
  const [selectedValue, setSelectedValue] = useState(1); // Set an initial value (e.g., 1)
  const [selectedPeriod, setSelectedPeriod] = useState('');
  // Define the state to store the current date for periodic updates
  const [currentDate, setCurrentDate] = useState(
    DateTime.local().setZone('Australia/Darwin')
  );

  // Callback function for retrieving bookings
  const getBookings = useCallback(
    async (startAt, endAt) => {
      try {
        setBookings(null);
        console.log('startAtA', startAt);
        console.log('endAtA', endAt);

        const updateBookings = (newBookings) => {
          console.log('newBookings', newBookings);
          setBookings(newBookings);
        };

        await auth.api._bookings.getCompletedBookings(
          props.uid || auth.user.uid,
          startAt,
          endAt,
          true,
          updateBookings
        );
      } catch (error) {
        setError(getErrorMessage(error));
      }
    },
    [auth.api._bookings, auth.user.uid, props.uid]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(DateTime.local().setZone('Australia/Darwin'));
    }, 1000 * 60 * 60); // Check every hour, adjust as needed

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    async function getTutorProfile() {
      try {
        // Get the tutor profile
        const profile = await auth.api.profile.getTutorProfile(
          auth.user.uid,
          false
        );
        if (profile === null) {
          setError('The teacher profile was not found');
        } else {
          handleRefreshCurrentWeek();
          setTutor(profile);
        }
      } catch (error) {
        // Update the error state
        setError(error);
      }
    }

    getTutorProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  const retryAPICall = useCallback(() => {
    setError(null);
    setRetry(!retry);
  }, [retry]);

  // Display the error if an error occured
  if (error) {
    return <AsyncStatus retry gutterTop error={error} onRetry={retryAPICall} />;
  }

  const paidBookings =
    bookings &&
    bookings.filter(
      (booking) => booking.payment && booking.payment.paid === true
    );
  const unpaidBookings =
    bookings &&
    bookings.filter(
      (booking) => booking.payment && booking.payment.paid === false
    );
  const amountPaid = paidBookings && paidBookings.reduce(paymentReducer, 0);
  const amountUnpaid =
    unpaidBookings && unpaidBookings.reduce(paymentReducer, 0);

  const generateDateRanges = () => {
    const dateRanges = [];
    const currentDayOfWeek = currentDate.weekday; // 1 (Monday) to 7 (Sunday)

    for (let i = 0; i < 6; i++) {
      // Calculate the days to subtract to reach the Monday of each full week
      const daysToSubtract = currentDayOfWeek + i * 7;

      const startDate = currentDate
        .minus({ days: daysToSubtract })
        .startOf('week')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const endDate = startDate.plus({ days: 6 }).endOf('day'); // Set to the end of the day

      const formattedStartDate = startDate.toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true // Use 12-hour clock
      });

      const formattedEndDate = endDate.toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true // Use 12-hour clock
      });

      const dateRangeLabel = `Payment Period [${formattedStartDate} to ${formattedEndDate} ACST]`;

      dateRanges.push({
        startDate: startDate.toMillis(),
        endDate: endDate.toMillis(),
        label: dateRangeLabel,
        value: i + 1
      });
    }

    return dateRanges;
  };

  const handleMenuItemClick = (startDate, endDate, value, label) => {
    const periodString = label;
    setSelectedValue(value);
    setSelectedPeriod(periodString);
    getBookings(startDate, endDate);
  };

  const handleRefreshCurrentWeek = () => {
    // Calculate the start date of the previous week (Monday)
    const startDate = currentDate
      .minus({ weeks: 1 })
      .startOf('week')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    // Calculate the end date of the previous week (Sunday)
    const endDate = startDate.plus({
      days: 6,
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999
    });

    // Reset the selected value
    const previousWeekIndex = dateRanges.findIndex(
      (range) =>
        range.startDate === startDate.toMillis() &&
        range.endDate === endDate.toMillis()
    );

    const selectedValue =
      previousWeekIndex !== -1
        ? dateRanges[previousWeekIndex].value
        : dateRanges[0].value;

    const selectedLabel =
      previousWeekIndex !== -1
        ? dateRanges[previousWeekIndex].label
        : dateRanges[0].label;

    setSelectedValue(selectedValue);
    setSelectedPeriod(selectedLabel);

    // Call getBookings with the previous week's date range
    getBookings(startDate.toMillis(), endDate.toMillis());
  };

  const dateRanges = generateDateRanges();

  return (
    <div className={props.padding ? classes.root : ''}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.dateControls}>
            <div className={classes.container}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  Previous Payment Records
                </InputLabel>
                <Select
                  className={classes.select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Previous Payment Records"
                  value={selectedValue}
                  MenuProps={{
                    classes: {
                      paper: classes.paper
                    }
                  }}
                  onChange={(event) => setSelectedValue(event.target.value)}>
                  {dateRanges.map((range) => {
                    return (
                      <MenuItem
                        key={range.value}
                        value={range.value}
                        onClick={() =>
                          handleMenuItemClick(
                            range.startDate,
                            range.endDate,
                            range.value,
                            range.label
                          )
                        }>
                        {range.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                className={classes.button}
                // disabled={true}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleRefreshCurrentWeek()}
                startIcon={<Refresh />}>
                Refresh Current Payment Period
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <FigureCard
            name="Paid Lessons"
            value={bookings && paidBookings.length.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <FigureCard
            name="Paid Income"
            value={
              bookings &&
              `${
                tutor?.profile?.currency
                  ? getParamByParam(
                      'currency',
                      tutor?.profile?.currency,
                      'symbol'
                    )
                  : 'Currency Not Found'
              } ${amountPaid.toFixed(2).toString()}`
            }
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={3}>
          <FigureCard
            name="Unpaid Lessons"
            value={bookings && unpaidBookings.length.toString()}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={3}>
          <FigureCard
            name="Unpaid Income"
            value={
              bookings &&
              `${
                tutor?.profile?.currency
                  ? getParamByParam(
                      'currency',
                      tutor?.profile?.currency,
                      'symbol'
                    )
                  : 'Currency Not Found'
              } ${amountUnpaid.toFixed(2).toString()}`
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
          <BookingsChart
            error={error}
            tutor={tutor}
            bookings={bookings}
            selectedPeriod={selectedPeriod}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
          <BookingsListCard bookings={bookings} tutor={tutor} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
      </Grid>
    </div>
  );
}

export default Income;
