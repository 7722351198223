import firebase from 'firebase/app';
import 'firebase/firestore';

function Applications(self) {
  /*
    API endpoint for submitting an application
  */
  const submitApplication = async (application) => {
    // Make the request and return the response
    return self._http('applications', 'POST', { data: application }, false);
  };

  // --- ADMIN ENDPOINTS ---

  /*
    ADMIN API endpoint for listing applications
  */
  const adminSearchApplications = async (
    status,
    orderBy,
    search,
    searchType
  ) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Retrieve the data from firebase
    return self._firebase(
      `applicationsAdminSearchApplications`,
      async () => {
        // Create the initial query
        let query = firebase
          .firestore()
          .collection('applications')
          .where('status', 'in', status)
          .orderBy('when', orderBy || 'asc')
          .limit(125);

        // Add the optional params
        if (search) query = query.where(searchType, '==', search);

        // Return the query
        return (await query.get()).docs.map((application) => ({
          id: application.id,
          ...application.data()
        }));
      },
      false
    );
  };

  /*
    ADMIN API endpoint for accepting an application
  */
  const adminAcceptApplication = async (application) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http(`applications/${application}/accept`, 'PUT', {}, false);
  };

  /*
    ADMIN API endpoint for confirming an application
  */
  const adminConfirmApplication = async (application) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http(`applications/${application}/confirm`, 'PUT', {}, false);
  };

  /*
    ADMIN API endpoint for training of an application
  */
  const adminTrainingApplication = async (application) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http(`applications/${application}/training`, 'PUT', {}, false);
  };

  /*
    ADMIN API endpoint for rejecting an application
  */
  const adminRejectApplication = async (application, email, name, reason) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http(
      `applications/${application}/reject`,
      'PUT',
      { data: { email, name, reason } },
      false
    );
  };

  const adminRejectServiceOrTraining = async (
    application,
    email,
    name,
    reason,
    tutorId
  ) => {
    // Check whether we're the right role before making the request
    self.enforceRole();
    // Prepare the request data
    const requestData = {
      email,
      name,
      reason
    };

    // Include tutorId if it exists
    if (tutorId) {
      requestData.tutorId = tutorId;
    }

    // Make the request and return the response
    return self._http(
      `applications/${application}/rejectserviceortraining`, // Unique endpoint
      'PUT',
      { data: requestData },
      false
    );
  };

  const adminUpdateTutorCVApplication = async (application, cvLink) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request to update the CV link
    await self._http(
      `applications/${application}/updateCV`,
      'PUT',
      { data: { cvLink } },
      false
    );
  };

  // Function to update degree link
  const adminUpdateTutorDegree = async (applicationId, degreeLink) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request to update the degree link
    await self._http(
      `applications/${applicationId}/updateDegree`,
      'PUT',
      { data: { degreeLink } },
      false
    );
  };

  // Function to resend verification email
  const adminResendVerificationEmail = async (name, email) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    const postData = {
      name,
      email
    };
    // Make the request to resend verification email
    return self._http(
      `applications/resendVerificationEmail`,
      'POST',
      { data: postData },
      false
    );
  };

  const adminDeleteApplication = async (
    application,
    email,
    name,
    reason,
    tutorId
  ) => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http(
      `applications/${application}/delete`,
      'DELETE',
      { data: { email, name, reason, tutorId } },
      false
    );
  };

  // Return the endpoint functions
  return {
    submitApplication,
    // ADMIN ENDPOINTS
    adminSearchApplications,
    adminAcceptApplication,
    adminRejectApplication,
    adminConfirmApplication,
    adminTrainingApplication,
    adminRejectServiceOrTraining,
    adminUpdateTutorCVApplication,
    adminUpdateTutorDegree,
    adminResendVerificationEmail,
    adminDeleteApplication
  };
}

export default Applications;
