import { Children } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Component styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px'
  },
  noDivider: {
    borderTop: 'none'
  },
  noPadding: {
    padding: 0
  }
}));

const PortletFooter = (props) => {
  const { className, noDivider, noPadding, children, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.noPadding]: noPadding
    },
    className
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} className={rootClassName}>
      {Children.toArray(children)}
    </div>
  );
};

export default PortletFooter;
