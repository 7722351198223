export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    '& > div': {
      display: 'flex'
    },
    '& > div > div.message': {
      backgroundColor: theme.palette.background.dark,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      marginBottom: theme.spacing(0.25),
      borderRadius: 20.5,
      '& > p': {
        userSelect: 'none'
      }
    },
    '& > div > div.message.first': {
      borderBottomLeftRadius: 6
    },
    '& > div > div.message.last': {
      borderTopLeftRadius: 6
    },
    // RIGHT side CSS declarations
    '&.right': {
      alignItems: 'flex-end',
      '& > span': {
        paddingLeft: 0,
        paddingRight: theme.spacing(1)
      },
      '& > div': {
        justifyContent: 'flex-end'
      },
      '& > div > div.message': {
        color: 'white',
        backgroundColor: `${theme.palette.primary.main}`
      },
      '& > div > div.message.first': {
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 20.5 // overwrite old definition
      },
      '& > div > div.message.last': {
        borderTopRightRadius: 6,
        borderTopLeftRadius: 20.5 // overwrite old definition
      }
    }
  }
});
