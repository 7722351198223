// Material UI imports
import {
  Divider,
  Typography,
  Button,
  withStyles,
  Grid,
  Avatar,
  Chip,
  Popover,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';

import {
  AssignmentRounded as LessonIcon,
  WarningRounded as WarningIcon
} from '@mui/icons-material';

import firebase from 'firebase/app';

// DateTime helpers
import { DateTime } from 'luxon';

// Portlet items
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  BookingInfo
} from 'components';

import { Link } from 'react-router-dom';
import { getInitials } from 'helpers';

// Style
import styles from './styles';
import { useEffect, useState } from 'react';

function AnnouncementCard(props) {
  // Grab some values of of the component's properties
  const { classes, announcement, auth } = props;
  const [bookingDoc, setBookingDoc] = useState(null);
  const [tutorDoc, setTutorDoc] = useState(null);

  // Initialize a popover state
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  // Grab the booking information.
  const { from, message, title, when, bookingId, tutor, admin, adminPost } =
    announcement;

  const dismissNotification = async () => {
    announcement.is_viewed = true;

    if (announcement.admin) {
      let old = await firebase
        .firestore()
        .doc(`${auth.role}s/${auth?.user?.uid}`)
        .get();

      old = old.data()?.data?.announcements;
      // Assuming old is an array, simply push the new announcement into it
      if (old && Array.isArray(old)) {
        old.push(announcement);

        // Now you can update Firestore with the updated announcements array
        firebase
          .firestore()
          .doc(`${auth.role}s/${auth?.user?.uid}`)
          .update({ data: { announcements: old } });
      }
    } else {
      const docRef = firebase
        .firestore()
        .doc(`${auth.role}s/${auth?.user?.uid}`);

      const snapshot = await docRef.get();

      if (snapshot.exists) {
        const data = snapshot.data().data;

        if (data && data.announcements && Array.isArray(data.announcements)) {
          const announcements = data.announcements;

          // Find the announcement with matching ID
          const announcementToUpdate = announcements.find(
            (announcementItem) =>
              announcementItem.announcementId === announcement.announcementId
          );

          if (announcementToUpdate) {
            // Update is_viewed property
            announcementToUpdate.is_viewed = true;

            // Update Firestore document with the modified data
            await docRef.update({ data });
            console.log('Notification dismissed successfully.');
          } else {
            console.log(
              "Announcement not found or it's an admin announcement."
            );
          }
        } else {
          console.log('Data or announcements array not found.');
        }
      } else {
        console.log('User document not found.');
      }
    }
    // handleViewed(announcement);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (tutor || auth.role == 'tutor') {
          let id = tutor;
          if (auth.role == 'tutor') {
            id = auth.user.uid;
          }

          const doc = await firebase
            .firestore()
            .collection(`tutors`)
            .doc(id)
            .collection(`bookings`)
            .doc(bookingId)
            .get();

          // Get Tutor Data
          const tDoc = await firebase
            .firestore()
            .collection(`tutors`)
            .doc(id)
            .get();
          setTutorDoc(tDoc.data());
          setBookingDoc(doc.data());
        }
      } catch (error) {
        console.log('Error: ', error.message);
      }
    }
    fetchData();
  }, [bookingId, tutor, auth.role, auth.user.uid]);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <Portlet>
        <PortletHeader>
          <PortletLabel
            title={title}
            subtitle={DateTime.fromMillis(when).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          />
        </PortletHeader>
        <PortletContent>
          {admin || adminPost ? (
            <>
              <Grid
                container
                spacing={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '30px'
                }}>
                <Typography className={classes.message}>{message}</Typography>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    style={{
                      background: '#0070ff',
                      color: 'white',
                      padding: '3px 53px',
                      borderRadius: '20px',
                      fontSize: '22px'
                    }}
                    size="small"
                    className={classes.gutterRight}
                    variant="contained"
                    onClick={dismissNotification}>
                    {' '}
                    Ok{' '}
                  </Button>
                </div>
              </Grid>
            </>
          ) : (
            <div className="my-1">
              {bookingDoc && tutorDoc && (
                <div className={classes.root}>
                  <Grid container spacing={4} style={{ position: 'relative' }}>
                    <Grid
                      item
                      className={classes.avatarWrapper}
                      xs={12}
                      sm="auto"
                      md="auto"
                      lg="auto"
                      xl="auto"
                      style={{
                        // padding: '7px',
                        display: 'grid',
                        justifyItems: isXs ? 'left' : 'center',
                        gap: isXs ? '10px' : '0px'
                      }}>
                      <Tooltip title="Goto Profile" aria-label="Go to profile">
                        <Link to={`/student/profile/${bookingDoc.tutor}`}>
                          <Avatar
                            src={
                              auth.role == 'student'
                                ? tutorDoc.profile.image
                                : bookingDoc.profile.student.image
                            }
                            alt={
                              auth.role == 'student'
                                ? tutorDoc.profile.image
                                : bookingDoc.profile.student.image
                            }
                            className={classes.avatar}>
                            <Typography variant="h3">
                              {getInitials(
                                (auth.role == 'student'
                                  ? tutorDoc.profile.name
                                  : bookingDoc.profile.student.name) || ''
                              )}
                            </Typography>
                          </Avatar>
                        </Link>
                      </Tooltip>
                      <Typography variant="h6">
                        {(auth.role == 'student'
                          ? tutorDoc.profile.name
                          : bookingDoc.profile.student.name) || ''}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.info}
                      xs={12}
                      sm="auto"
                      md="auto"
                      lg="auto"
                      xl="auto">
                      {/* <Typography variant="h3" style={{ fontSize: '22px' }}>
                        {auth.role == 'student'
                          ? tutorDoc.profile.name
                          : bookingDoc.profile.student.image}
                      </Typography> */}

                      <div style={{ display: 'flex' }}>
                        {/* <div className={classes.inelegible}> */}
                        <Chip
                          icon={<WarningIcon />}
                          label={`${bookingDoc.status}`}
                        />
                        {/* </div> */}

                        <Typography
                          color="textSecondary"
                          style={{ display: 'flex', alignItems: 'center' }}>
                          {
                            `${DateTime.fromMillis(
                              bookingDoc.when || 0
                            ).toFormat('d LLL h:mm a')}`
                            //  ${DateTime.fromMillis(
                            //   announcement.when || 0
                            // ).toRelative()}
                          }
                        </Typography>
                      </div>

                      <Popover
                        id={popoverAnchor ? 'simple-popover' : undefined}
                        open={Boolean(popoverAnchor)}
                        anchorEl={popoverAnchor}
                        onClose={() => {
                          setPopoverAnchor(null);
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}>
                        <BookingInfo info={bookingDoc.info} />
                      </Popover>
                      {auth.role === 'student' && (
                        <div
                          className={classes.actions}
                          style={{ marginBottom: '27px', marginTop: '8px' }}>
                          <Button
                            size="small"
                            className={classes.gutterRight}
                            variant="contained"
                            style={{ padding: '4px 4px' }}
                            onClick={(event) => {
                              setPopoverAnchor(event.currentTarget);
                            }}
                            startIcon={<LessonIcon />}>
                            view details
                          </Button>
                        </div>
                      )}
                      {auth.role === 'tutor' && (
                        <div
                          className={classes.actions}
                          style={{ marginBottom: '27px', marginTop: '8px' }}>
                          <Button
                            size="small"
                            className={classes.gutterRight}
                            variant="contained"
                            style={{ padding: '4px 4px' }}
                            onClick={(event) =>
                              setPopoverAnchor(event.currentTarget)
                            }
                            startIcon={<LessonIcon />}>
                            view details
                          </Button>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '29%'
                      }}>
                      <Button
                        style={{
                          background: '#0070ff',
                          color: 'white',
                          padding: '3px 53px',
                          borderRadius: '20px',
                          fontSize: '22px'
                        }}
                        size="small"
                        className={classes.gutterRight}
                        variant="contained"
                        onClick={dismissNotification}>
                        {' '}
                        Ok{' '}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          )}
          <Divider />
          <Typography variant="subtitle2" className={classes.from}>
            Posted by: {from}
          </Typography>
        </PortletContent>
      </Portlet>
    </>
  );
}

export default withStyles(styles)(AnnouncementCard);
