export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexGrow: 1
  },
  actions: {
    marginBottom: theme.spacing(2.5)
  },
  reminderAlert: {
    marginBottom: theme.spacing(1.5)
  }
});
