import { Fragment, PureComponent, createRef } from 'react';

// Authentication provider
import { withAuth } from 'services/auth';

// Authentication provider
import { withRouter } from 'react-router-dom';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Material UI components / helpers
import { Paper, Grid, Button, Divider, withStyles } from '@material-ui/core';

// Mateirla UI Lab
import { Alert } from '@material-ui/lab';

// Luxon date helpers
import { DateTime } from 'luxon';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Export cleanup
import compose from 'recompose/compose';

// Async status
import { AsyncStatus, BookingCard, DateRangePicker } from 'components';

// Submit feedback dialog
import { SubmitFeedbackDialog } from '../..';

// Component styles
import styles from './styles';

class HistoryBookingsView extends PureComponent {
  constructor(props) {
    super(props);

    // Initialzie the state
    this.state = {
      bookings: null,
      error: null
    };

    // Create a React ref for the cancel confirm dialog
    this.cancelConfirmDialog = createRef();
    this.submitFeedbackDialog = createRef();

    // Bind the callback functions
    this.retryAPICall = this.retryAPICall.bind(this);
    this.gotoSchedule = this.gotoSchedule.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onSubmitFeedback = this.onSubmitFeedback.bind(this);

    // Initialize the ready flag
    this.ready = true;
  }

  async componentDidMount(dateRange, store = true) {
    try {
      // Setup references to the API endpoints and
      // the uid of the current user
      const { auth } = this.props;
      const [startDate, endDate] = dateRange || [
        DateTime.local().minus({ months: 1 }),
        DateTime.local()
      ];

      // Retrive the current user's bookings from the API.
      const data = await auth.api.bookings.getMyBookingHistory(
        startDate.startOf('day').toMillis(),
        endDate.endOf('day').toMillis(),
        store
      );

      // Update the component's state with the bookings.
      if (this.ready) {
        this.setState({
          bookings: data.bookings.filter(
            (booking) =>
              !['pending', 'in-progress', 'booked'].includes(booking.status)
          )
        });
      }
    } catch (error) {
      // Update the component's state to show an error
      if (this.ready) {
        this.setState({
          error: getErrorMessage(error)
        });
      }

      // Log the error to the console.
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.ready = false;
  }

  async onSubmitFeedback(booking, values) {
    // Retrive the enqueueSnackbar function and auth provider from props
    const { auth, enqueueSnackbar } = this.props;

    try {
      enqueueSnackbar(`Submitting feedback...`);

      // Delete the material
      await auth.api.bookings.submitFeedback(booking.id, values);

      // Update the state
      this.setState({
        bookings: this.state.bookings.map((stateBooking) =>
          booking.id === stateBooking.id
            ? {
                ...stateBooking,
                status: 'completed',
                feedback: values
              }
            : stateBooking
        )
      });

      // Notify the user of the deletion
      enqueueSnackbar(`Feedback submitted!`, { variant: 'success' });
    } catch (error) {
      // Log the error to the console.
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  retryAPICall() {
    // Reset the state
    this.setState({
      bookings: null,
      error: null
    });

    // Call componentDidMount to call the API again.
    this.componentDidMount(null, false);
  }

  gotoSchedule() {
    // Grab the history provider from the props
    const { auth, history, onUpdateTab } = this.props;

    // Redirect the user to the bookings/schedule page.
    if (auth.role === 'student') {
      onUpdateTab(0);
    } else {
      history.push('/tutor/schedule');
    }
  }

  onDateChanged(dateRange) {
    this.setState({ bookings: null, error: null });
    this.componentDidMount(dateRange, false);
  }

  render() {
    const { bookings, error } = this.state;
    const { classes, auth, onViewFeedback } = this.props;

    // Return a rendered list of the items.
    return (
      <>
        {auth.role === 'tutor' && (
          <SubmitFeedbackDialog
            ref={this.submitFeedbackDialog}
            onConfirm={this.onSubmitFeedback}
          />
        )}
        <Alert
          className={classes.reminderAlert}
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={this.retryAPICall}>
              Refresh Bookings
            </Button>
          }>
          <b>Reminder: </b>{' '}
          {auth.role === 'tutor'
            ? 'Click on student profiles to view feedback history'
            : 'Click on teacher photos to navigate to their profile'}
        </Alert>
        <Paper className={classes.dateControls}>
          <DateRangePicker
            initialValues={[
              DateTime.local().minus({ months: 1 }),
              DateTime.local()
            ]}
            onChange={this.onDateChanged}
          />
        </Paper>
        {(() => {
          if (error) {
            // Display the error message and retry button
            return (
              <AsyncStatus error={error} onRetry={this.retryAPICall} retry />
            );
          }

          // If we haven't received any booking yet, show a loading animation
          if (!bookings) {
            return <AsyncStatus loading />;
          } else if (bookings.length === 0) {
            // If no bookings were retireved, show an error message
            return (
              <AsyncStatus
                error="No bookings found for time period"
                onRetry={this.gotoSchedule}
                retryText={`go to ${
                  auth.role === 'student' ? 'bookings search' : 'schedule'
                }`}
                retrySize="medium"
                textVariant="h4"
                retry
              />
            );
          } else {
            return (
              <Grid container spacing={2}>
                {bookings.map((booking, index) => (
                  <Fragment key={booking.id}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <BookingCard
                        auth={auth}
                        booking={booking}
                        history
                        onSubmitFeedback={() => {
                          if (auth.role === 'tutor')
                            this.submitFeedbackDialog.current.open(booking);
                        }}
                        onViewFeedback={(bookingFeedback) => {
                          onViewFeedback(bookingFeedback);
                        }}
                      />
                    </Grid>
                    {index !== bookings.length - 1 && (
                      <Grid
                        key={booking.id}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}>
                        <Divider />
                      </Grid>
                    )}
                  </Fragment>
                ))}
              </Grid>
            );
          }
        })()}
      </>
    );
  }
}

export default compose(
  withSnackbar,
  withRouter,
  withAuth,
  withStyles(styles)
)(HistoryBookingsView);
