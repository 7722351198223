import light from './light';
import dark from './dark';
import useDarkTheme from './useDarkTheme';

const themes = {
  light,
  dark
};

export { themes, useDarkTheme };
