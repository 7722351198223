import { lazy } from 'react';
import { View } from '..';

import { importRetry as retry } from 'helpers';

// Icons
import {
  DashboardRounded as DashboardIcon,
  LibraryBooksRounded as LibraryIcon,
  AccountBoxRounded as AccountBoxIcon,
  // CalendarTodayRounded as ScheduleIcon,
  ShoppingCartRounded as SubscriptionIcon,
  CollectionsBookmarkRounded as BookingIcon,
  HelpRounded as HelpIcon
} from '@mui/icons-material';

// View components
const Dashboard = lazy(() => retry(() => import('views/student/Dashboard')));
const Profile = lazy(() => retry(() => import('views/student/Profile')));
const Courses = lazy(() => retry(() => import('views/student/Courses')));
const Subscription = lazy(() =>
  retry(() => import('views/student/Subscription'))
);
const Account = lazy(() => retry(() => import('views/student/Account')));
const Help = lazy(() => retry(() => import('views/student/Help')));

// Shared view components
const Materials = lazy(() => retry(() => import('views/shared/Materials')));
const Bookings = lazy(() => retry(() => import('views/shared/Bookings')));
const Announcements = lazy(() =>
  retry(() => import('views/shared/Announcements'))
);

const views: View[] = [
  {
    route: '/student/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />,
    component: Dashboard
  },
  {
    route: '/student/dashboard/announcements',
    component: Announcements
  },
  {
    route: '/student/materials',
    text: 'Materials',
    icon: <LibraryIcon />,
    component: Materials
  },
  {
    route: '/student/materials/:material',
    component: Courses
  },
  {
    route: '/student/profile/:uid',
    component: Profile
  },
  {
    route: '/student/bookings',
    text: 'Bookings',
    icon: <BookingIcon />,
    component: Bookings
  },
  {
    route: '/student/subscription',
    text: 'Subscription',
    icon: <SubscriptionIcon />,
    component: Subscription
  },
  {
    route: '/student/account',
    text: 'Account',
    icon: <AccountBoxIcon />,
    component: Account
  },
  {
    route: '/student/help',
    text: 'Help',
    icon: <HelpIcon />,
    component: Help
  }
];

export default views;
