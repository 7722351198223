import { PureComponent } from 'react';

// Material components
import { Button, Grid, Typography, withStyles } from '@material-ui/core';

// Speed test dialog
import SpeedTestDialog from './components/SpeedTestDialog';

// Component styles
import styles from './styles';

class SpeedTest extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      dialogOpen: false
    };
  }

  render() {
    // Grab our component props
    const { classes, onSuccess } = this.props;
    const { dialogOpen } = this.state;

    return (
      <>
        <SpeedTestDialog
          open={dialogOpen}
          onClose={() => {
            this.setState({ dialogOpen: false });
          }}
        />
        <div className={classes.root}>
          <Grid container justify="center" spacing={4}>
            <Grid item lg={6} xs={12}>
              <div className={classes.content}>
                <Typography variant="h1">
                  Internet Speed Acknowledgement
                </Typography>
                <Typography variant="h5" className={classes.sub}>
                  By clicking the button below, you are stating that your
                  internet speed is sufficient for video conferencing, and
                  agreeing to these terms.
                </Typography>
                <div className={classes.actions}>
                  <Button
                    className={classes.confirmButton}
                    onClick={onSuccess}
                    variant="contained"
                    color="primary">
                    I agree
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ dialogOpen: true });
                    }}
                    variant="contained"
                    color="primary">
                    run speedtest
                  </Button>
                </div>
                <img
                  alt="Under development"
                  className={classes.image}
                  src="/images/not_found.png"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SpeedTest);
