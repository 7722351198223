// Material components
import { Typography, withStyles } from '@material-ui/core';

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing(10),
    display: 'flex'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },
  image: {
    maxWidth: '100%',
    width: '450px'
  },
  imageWrapper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center'
  },
  sub: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary
  }
});

function NotFound(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1">404</Typography>
        <Typography variant="subtitle2" className={classes.sub}>
          Press the back button in your browser, or simply refresh.
        </Typography>
        <div className={classes.imageWrapper}>
          <img
            alt="Under development"
            className={classes.image}
            src="/images/not_found.png"
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(NotFound);
