// Material UI imports
import { useTheme, makeStyles } from '@material-ui/core';

// Material UI icons
import { HistoryRounded as HistoryIcon } from '@mui/icons-material';

// Luxon date helpers
import { DateTime } from 'luxon';

// Recharts helper
import {
  ResponsiveContainer,
  BarChart,
  Tooltip,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis
} from 'recharts';

// Portlet items
import {
  AsyncStatus,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';
import { getParamByParam } from 'iso-country-currency';

// Style
import styles from './styles';
const useStyles = makeStyles(styles);

function groupByDate(bookings) {
  const out = {};
  bookings.forEach((booking) => {
    const key = DateTime.fromMillis(booking.when).toLocaleString(
      DateTime.DATE_SHORT
    );
    const pTotal =
      booking.payment && booking.payment.paid ? 'Paid Total' : 'Unpaid Total';
    const pCount =
      booking.payment && booking.payment.paid ? 'Paid Count' : 'Unpaid Count';
    out[key] = {
      ...out[key],
      [pTotal]:
        (out[key] && out[key][pTotal] ? out[key][pTotal] : 0) +
        (booking.payment ? booking.payment.rate : 65),
      [pCount]: (out[key] && out[key][pCount] ? out[key][pCount] : 0) + 1
    };
  });
  return Object.entries(out).map(([key, value]) => ({ key, ...value }));
}

function BookingsChart(props) {
  // Destructure our props
  const { error, bookings, tutor, selectedPeriod } = props;
  const classes = useStyles();
  const theme = useTheme();

  // Render the bookings
  return (
    <Portlet>
      <PortletHeader>
        <PortletLabel icon={<HistoryIcon />} title={selectedPeriod} />
      </PortletHeader>
      <PortletContent style={{ height: 332 }}>
        {(() => {
          if (error) {
            // Display the error message and retry button
            // return <AsyncStatus error={error} onRetry={this.retryAPICall} retry />;
            return <AsyncStatus error={error} />;
          }

          // If we haven't receive our bookings yet, return a loading symbol
          if (!bookings) return <AsyncStatus loading />;

          // If our request didn't return any bookings
          if (bookings.length === 0) {
            // Notify the user that they have no upcoming sessions.
            return (
              <AsyncStatus error="No completed bookings found within this time period" />
            );
          }

          // Render the booking cards
          return (
            <ResponsiveContainer
              className={`${classes.graphContainer}`}
              width="100%"
              height={300}>
              <BarChart
                margin={{
                  top: 20,
                  right: 10,
                  bottom: 10,
                  left: 15
                }}
                data={groupByDate(bookings)}
                barSize={10}>
                <Tooltip
                  isAnimationActive={false}
                  formatter={(val, name) => [
                    `${
                      tutor?.profile?.currency
                        ? getParamByParam(
                            'currency',
                            tutor?.profile?.currency,
                            'symbol'
                          )
                        : 'Currency Not Found'
                    } ${val.toFixed(2)}`,
                    name
                  ]}
                  labelFormatter={(label, data) =>
                    `${label} (${
                      (data[0] && data[0].payload['Paid Count']) || 0
                    } Paid, ${
                      (data[0] && data[0].payload['Unpaid Count']) || 0
                    } Unpaid)`
                  }
                  cursor={{ fill: theme.palette.background.paper }}
                  contentStyle={{
                    borderRadius: 8,
                    border: 'none',
                    backgroundColor: theme.palette.background.default,
                    padding: theme.spacing(2)
                  }}
                  labelStyle={{
                    fontWeight: 600,
                    fontSize: '1rem',
                    marginTop: 4,
                    marginBottom: 8
                  }}
                  itemStyle={{ color: theme.palette.text.secondary }}
                />
                <CartesianGrid
                  vertical={false}
                  strokeDasharray="3 3"
                  opacity={theme.palette.type === 'light' ? 1 : 0.2}
                />
                <XAxis
                  axisLine={false}
                  tick={{ fill: theme.palette.text.secondary }}
                  tickLine={false}
                  tickMargin={15}
                  dataKey="key"
                />
                <YAxis
                  axisLine={false}
                  tick={{ fill: theme.palette.text.secondary }}
                  tickFormatter={(val) =>
                    `${
                      tutor?.profile?.currency
                        ? getParamByParam(
                            'currency',
                            tutor?.profile?.currency,
                            'symbol'
                          )
                        : 'Currency Not Found'
                    } ${val.toFixed(2)}`
                  }
                  tickLine={false}
                  dx={-10}
                />
                <Bar
                  dataKey="Paid Total"
                  radius={[4, 4, 0, 0]}
                  fill={theme.palette.primary.main}
                />
                <Bar
                  dataKey="Unpaid Total"
                  radius={[4, 4, 0, 0]}
                  fill={theme.palette.primary.dark}
                />
              </BarChart>
            </ResponsiveContainer>
          );
        })()}
      </PortletContent>
    </Portlet>
  );
}

export default BookingsChart;
