// Material components
import { SvgIcon } from '@material-ui/core';

export default function Mastercard(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 36 24">
      <rect width="10.21" height="16.68" x="12.069" y="2.27" fill="#F26122" />
      <path
        fill="#EA1D25"
        d="M13.1686965,10.6101654 C13.1518317,7.36369968 14.6264036,4.28921728 17.1686965,2.27016538 C12.8189479,-1.15053977 6.57064452,-0.65381455 2.81599051,3.41117128 C-0.938663503,7.47615711 -0.938663503,13.7441736 2.81599051,17.8091595 C6.57064452,21.8741453 12.8189479,22.3708705 17.1686965,18.9501654 C14.6264036,16.9311135 13.1518317,13.8566311 13.1686965,10.6101654 Z"
      />
      <path
        fill="#F69E1E"
        d="M34.3286965,10.6101654 C34.3260834,14.6695096 32.0075475,18.3715635 28.3565636,20.1459927 C24.7055797,21.9204219 20.3621615,21.456171 17.1686965,18.9501654 C21.7687535,15.328122 22.5653496,8.66446152 18.9486965,4.06016538 C18.430247,3.39343545 17.8325115,2.79234186 17.1686965,2.27016538 C20.3621615,-0.235840209 24.7055797,-0.700091182 28.3565636,1.07433806 C32.0075475,2.8487673 34.3260834,6.55082113 34.3286965,10.6101654 L34.3286965,10.6101654 Z"
      />
    </SvgIcon>
  );
}
