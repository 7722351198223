import { BrowserRouter } from 'react-router-dom';

// Material helpers
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

// Snackbar provider
import { SnackbarProvider } from 'notistack';

// Picker provider
import { LocalizationProvider } from '@material-ui/pickers';
import LuxonUtils from '@material-ui/pickers/adapter/luxon';

// Styles
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import 'assets/css/style.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
// Authentication provider
import { AuthProvider } from 'services/auth';

// Call provider
import { CallProvider } from 'services/call';

// Component error boundary
import ErrorBoundary from './components/ErrorBoundary';

// Routes
import Routes from './Routes';

// Theme
import { themes, useDarkTheme } from './theme';

const App = () => {
  const [currentTheme, toggleDarkTheme] = useDarkTheme();

  return (
    <ThemeProvider theme={createTheme(themes[currentTheme])}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={LuxonUtils}>
        <SnackbarProvider
          autoHideDuration={5000}
          classes={{
            root: 'snackbar-root-rounded'
          }}>
          <AuthProvider>
            <CallProvider>
              <BrowserRouter>
                <ErrorBoundary>
                  <Routes
                    currentTheme={currentTheme}
                    toggleDarkTheme={toggleDarkTheme}
                  />
                </ErrorBoundary>
              </BrowserRouter>
            </CallProvider>
          </AuthProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
