export default {
  root: {
    height: '50px',
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '14px',
    '@media (min-width: 660px)': {
      minWidth: '160px'
    },
    '&$selected': {
      fontWeight: 500
    },
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16
  }
};
