export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    padding: theme.spacing(1)
  },
  logoLink: {},
  logoImage: {
    cursor: 'pointer'
  },
  logoDivider: {
    marginBottom: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 'fit-content',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  },
  profileDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2)
  },
  avatar: {
    width: 50,
    height: 50
  },
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  listSubheader: {
    color: theme.palette.text.secondary
  },
  listVersionSubheader: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1.5)
  },
  listItem: {
    paddingLeft: theme.spacing(4),
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '& $listItemIcon': {
      marginRight: theme.spacing(0.5)
    },
    '&:hover': {
      backgroundColor: theme.palette.background.dark
    }
  },
  activeListItem: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    '& $listItemText': {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    '& $listItemIcon': {
      color: theme.palette.primary.main
    }
  },
  listItemIcon: {
    marginRight: 0
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  listDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  tokenInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  retryButton: {
    marginTop: theme.spacing(1)
  },
  infoChip: {
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.3),
    border: `1px solid ${theme.palette.background.dark}`
  },
  skeletonChip: {
    borderRadius: 16,
    transform: 'scale(1,1)',
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.3)
  }
});
