import { Children, PureComponent } from 'react';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';

// Authentication provider
import { withAuth } from 'services/auth';

// Router provider
import { withRouter } from 'react-router-dom';

// Material helpers
import { Drawer, withStyles, withWidth } from '@material-ui/core';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Component styles
import styles from './styles';

import { Sidebar, Topbar, Footer } from './components';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);

    // Determine whether we're on mobile
    const isMobile = ['xs', 'sm', 'md'].includes(props.width);

    // Initialize the component's state
    this.state = {
      isOpen: !isMobile,
      profile: undefined,
      profileError: undefined
    };

    // Set the ready flag to true
    this.ready = true;

    // Bind the neccesary functions
    this.handleClose = this.handleClose.bind(this);
    this.handleToggleOpen = this.handleToggleOpen.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.onSnapData = this.onSnapData.bind(this);
    this.onSnapError = this.onSnapError.bind(this);
    this.retryHook = this.retryHook.bind(this);
  }

  componentDidMount() {
    // Grab the auth provider from the props
    const { auth } = this.props;

    // Retrive the current user's bookings from the API.
    if (auth.role === 'student') {
      this.profileHook = auth.api.profile.hook(
        'profileLiveStudentProfile',
        this.onSnapData,
        this.onSnapError
      );
    }
  }

  componentWillUnmount() {
    // If the unsubscribe function is valid, unsubscribe
    if (this.profileHook) this.profileHook();

    // Set the ready flag to false
    this.ready = false;
  }

  onSnapData(snap) {
    // Update the component's state with the bookings.
    if (this.ready) {
      this.setState({
        profile: snap.data()
      });
    }
  }

  onSnapError(error) {
    // Update the component's state to show an error
    if (this.ready) {
      this.setState({
        profileError: `${getErrorMessage(error)} (try reloading the page)`
      });

      // Log the error to the console.
      console.error(error);
    }
  }

  retryHook() {
    // Reset the state
    this.setState({
      profile: undefined,
      profileError: undefined
    });

    // Unsubscribe from the bookings hook
    if (this.profileHook) this.profileHook();

    // Call componentDidMount to call the API again.
    this.componentDidMount();
  }

  // When the sidemenu is clicked out of
  handleClose() {
    this.setState({ isOpen: false });
  }

  // Toggle sidemenu
  handleToggleOpen() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  // Close the menu when clicked (mobile/tablet only)
  handleItemClick() {
    const { width } = this.props;

    // Check to see whether we're on a small screen
    if (['xs', 'sm', 'md'].includes(width)) {
      this.setState({
        isOpen: false
      });
    }
  }

  render() {
    const {
      classes,
      width,
      title,
      children,
      auth,
      currentTheme,
      toggleDarkTheme,
      showBackButton
    } = this.props;
    const { isOpen, profile, profileError } = this.state;

    // Create a isMobile flag
    const isMobile = ['xs', 'sm', 'md'].includes(width);

    return (
      <>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: !isMobile
          })}
          showBackButton={showBackButton}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
          isMobile={isMobile}
          currentTheme={currentTheme}
          toggleDarkTheme={toggleDarkTheme}
        />
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen || !isMobile}
          variant={isMobile ? 'temporary' : 'persistent'}>
          <Sidebar
            className={classes.sidebar}
            auth={auth}
            itemClicked={this.handleItemClick}
            profile={profile}
            profileError={profileError}
            onProfileRetry={this.retryHook}
          />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: !isMobile
          })}>
          {Children.toArray(children)}
          <Footer />
        </main>
      </>
    );
  }
}

export default compose(
  withAuth,
  withRouter,
  withStyles(styles),
  withWidth()
)(Dashboard);
