import API from '.';

interface ChatMessage {
  id: string;
  name: string;
  uid: string;
  content: string;
  when: number;
  unsent?: boolean;
}

interface APIChat {
  /**
   * API endpoint for sending a chat message
   */
  send: (booking: string, message: string) => Promise<void>;
}

function Chat(self: API): APIChat {
  const send = async (booking: string, message: string): Promise<void> => {
    // Run the HTTP function
    return self._http<void>(
      `chat/${booking}/send`,
      'POST',
      { data: { content: message } },
      false
    );
  };

  // Return the endpoint functions
  return { send };
}

export default Chat;
export type { ChatMessage };
