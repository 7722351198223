import { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

// ClassNames fix
import classNames from 'classnames';

// Material components
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

// Material icons
import {
  MenuRounded as MenuIcon,
  NotificationsRounded as AnnouncementsIcon,
  InputRounded as InputIcon,
  ArrowBackRounded as BackIcon,
  BrightnessHighRounded as LightThemeIcon,
  BrightnessLowRounded as DarkThemeIcon
} from '@mui/icons-material';

// Announcement dialog
import AnnouncementDialog from 'components/AnnouncementDialog';

// Custom components
import { AnnouncementList } from './components';

// Auth provider
import { AuthContext } from 'services/auth';

// Component styles
import styles from './styles';

const useStyles = makeStyles(styles);

function Topbar(props) {
  const {
    title,
    isMobile,
    onToggleSidebar,
    showBackButton,
    currentTheme,
    toggleDarkTheme,
    className
  } = props;
  const theme = useTheme();

  // Setup hooks
  const announcementDialog = useRef(null);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();

  // Setup state hook
  const [state, setState] = useState({
    announcements: null,
    announcementsEl: null,
    announcementsError: null,
    retryAPICall: false
  });

  const retryAPICall = useCallback(() => {
    setState({
      announcements: null,
      announcementsEl: null,
      announcementsError: null,
      retryAPICall: !state.retryAPICall
    });
  }, [state.retryAPICall]);

  const handleOpenAnnouncements = (e) =>
    setState({
      ...state,
      announcementsEl: e.currentTarget
    });

  const handleCloseAnnouncements = (announcement) => {
    setState({
      ...state,
      announcementsEl: null
    });
    if (announcement) announcementDialog.current.open(announcement);
  };

  useEffect(() => {
    const setupFirestoreListener = () => {
      const docRef = firebase
        .firestore()
        .doc(`${auth.role}s/${auth?.user?.uid}`);

      // Listen for changes in users announements
      return docRef.onSnapshot((doc) => {
        if (doc.exists) {
          // Retrive the current user's bookings from the API.
          auth.api.announcements.getAnnouncements().then((announcements) => {
            const data = doc.data()?.data?.announcements || [];
            const unviewedAnnouncements = data.filter(
              (ann) =>
                typeof ann.is_viewed === 'undefined' || ann.is_viewed === false
            );
            const viewedAnnouncements = data.filter(
              (ann) =>
                typeof ann.is_viewed === 'undefined' || ann.is_viewed === true
            );

            // Filter out viewed announcements from the announcements array
            const filteredAnnouncements = announcements.filter(
              (ann) =>
                !viewedAnnouncements.some(
                  (viewedAnnouncement) => viewedAnnouncement.id === ann.id
                )
            );

            // Concatenate unviewed announcements to the announcements array
            announcements = [
              ...filteredAnnouncements,
              ...unviewedAnnouncements
            ];

            // announcements = [...announcements, ...unviewedAnnouncements];
            announcements.sort((a, b) => b.when - a.when);

            setState({ announcements: announcements });
          });
        }
      });
    };

    const unsubscribe = setupFirestoreListener();

    return () => {
      // Unsubscribe from Firestore listener when the component unmounts
      unsubscribe();
    };
  }, [auth.user.id, auth]);

  useEffect(() => {
    async function retrieveAnnouncements() {
      try {
        // Retrive the current user's bookings from the API.
        let announcements = await auth.api.announcements.getAnnouncements();
        if (auth.role !== 'admin') {
          // Retrieve private announcements for non-admin users.
          let privateAnnounements = await firebase
            .firestore()
            .doc(`${auth.role}s/${auth?.user?.uid}`)
            .get();
          // Make sure data is returned and is an array
          privateAnnounements = privateAnnounements.data()?.data?.announcements;
          privateAnnounements = privateAnnounements ? privateAnnounements : [];

          // Filter out viewed announcements from privateAnnouncements
          const unviewedPrivateAnnouncements = privateAnnounements.filter(
            (ann) =>
              typeof ann.is_viewed === 'undefined' || ann.is_viewed === false
          );

          // Filter out viewed announcements from announcements
          announcements = announcements.filter(
            (ann) =>
              !privateAnnounements.some(
                (privateAnnouncement) =>
                  privateAnnouncement.id === ann.id &&
                  privateAnnouncement.is_viewed === true
              )
          );

          announcements = [...announcements, ...unviewedPrivateAnnouncements];

          announcements.sort((a, b) => b.when - a.when);

          // Update the component's state with the bookings.
          setState({ announcements: announcements });
        } else {
          // console.log('annoucements', announcements);
          setState({ announcements: announcements });
        }
      } catch (error) {
        // Update the component's state to show an error
        setState({ announcementsError: error });

        // Log the error to the console.
        console.error(error);
      }
    }

    retrieveAnnouncements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.retryAPICall]);

  const rootClassName = classNames(classes.root, className);

  return (
    <>
      <AnnouncementDialog ref={announcementDialog} />
      <AppBar className={rootClassName} elevation={1}>
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              variant="text">
              <MenuIcon />
            </IconButton>
          )}
          {showBackButton ? (
            <IconButton
              className={classes.menuButton}
              onClick={() => history.goBack()}
              variant="text">
              <BackIcon />
            </IconButton>
          ) : null}
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
          <div className={classes.topbarRight}>
            <IconButton onClick={toggleDarkTheme}>
              {currentTheme === 'light' ? (
                <LightThemeIcon />
              ) : (
                <DarkThemeIcon />
              )}
            </IconButton>
            <IconButton onClick={handleOpenAnnouncements}>
              <Badge
                badgeContent={state.announcements && state.announcements.length}
                color="primary"
                invisible={
                  !state.announcements || state.announcements.length < 1
                }>
                <AnnouncementsIcon />
              </Badge>
            </IconButton>
            {useMediaQuery(theme.breakpoints.down('sm')) ? (
              <IconButton onClick={auth.signOut}>
                <InputIcon />
              </IconButton>
            ) : (
              <Button
                startIcon={<InputIcon />}
                className={classes.signOutButton}
                onClick={auth.signOut}>
                Logout
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Popover
        anchorEl={state.announcementsEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={() => handleCloseAnnouncements()}
        open={Boolean(state.announcementsEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <AnnouncementList
          announcements={state.announcements}
          error={state.announcementsError}
          onRetry={retryAPICall}
          onSelect={handleCloseAnnouncements}
          role={auth.role}
        />
      </Popover>
    </>
  );
}

export default Topbar;
