import { PureComponent, createRef } from 'react';

// Material UI imports
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  withStyles
} from '@material-ui/core';

// Material UI Icons
import {
  MessageRounded as AnnouncementIcon,
  RefreshRounded as RefreshIcon
} from '@mui/icons-material';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Portlet items
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletToolbar,
  PortletFooter,
  AsyncStatus
} from 'components';

// DateTime helpers
import { DateTime } from 'luxon';

// Authentication provider
import { withAuth } from 'services/auth';

// Router provider
import { withRouter } from 'react-router-dom';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Cleanup exports
import compose from 'recompose/compose';

// Announcement dialog
import AnnouncementDialog from 'components/AnnouncementDialog';

// Style
import styles from './styles';

class AnnouncementsCard extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the component's state
    this.state = {
      announcements: [],
      loading: true,
      error: null
    };

    // Set the ready flag to true
    this.ready = true;

    // Create a reference to the announcement dialog
    this.dialog = createRef();

    // Bind any functions
    this.retryAPICall = this.retryAPICall.bind(this);
    this.renderAnnouncements = this.renderAnnouncements.bind(this);
  }

  async componentDidMount(store = true) {
    // Get the auth provider from the props
    const { auth } = this.props;

    try {
      // Retrive the current user's bookings from the API.
      const announcements = await auth.api.announcements.getAnnouncements(
        store
      );

      // Update the component's state with the bookings.
      if (this.ready) {
        this.setState({
          loading: false,
          announcements:
            announcements.length > 3 ? announcements.slice(0, 2) : announcements
        });
      }
    } catch (error) {
      // Update the component's state to show an error
      if (this.ready) {
        this.setState({
          loading: false,
          error: getErrorMessage(error)
        });
      }

      // Log the error to the console.
      console.error(error);
    }
  }

  retryAPICall() {
    // Reset the state
    this.setState({
      announcements: [],
      loading: true,
      error: null
    });

    // Call componentDidMount to call the API again.
    this.componentDidMount(false);
  }

  renderAnnouncements() {
    const { loading, announcements, error } = this.state;
    const { classes } = this.props;

    if (loading) {
      return (
        <div className={classes.loadingInner}>
          <List className={classes.fullList} disablePadding>
            {[0, 1].map((index) => (
              <ListItem divider={index !== 0} key={index}>
                <div className={classes.skeletonWrapper}>
                  <Skeleton width="80%" className={classes.skeletonTop} />
                  <Skeleton width="40%" className={classes.skeletonBottom} />
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      );
    }
    if (error !== null) {
      // Display the error message and retry button
      return <AsyncStatus error={error} />;
    }
    if (announcements.length === 0) {
      // Notify the user that they have no upcoming sessions.
      return (
        <AsyncStatus
          error="There are no new announcements."
          textVariant="body1"
        />
      );
    }
    // Return a rendered list of the announcements.
    return (
      <List className={classes.fullList} disablePadding>
        {announcements.map((announcement, index) => {
          // Get the formatted date string
          const date = DateTime.fromMillis(announcement.when).toRelative();

          // Return the listItem object
          return (
            <ListItem
              divider={index !== announcements.length - 1}
              key={`${announcement.when}${announcement.title}`}
              button
              onClick={() => {
                this.dialog.current.open(announcement);
              }}>
              <ListItemText primary={announcement.title} secondary={date} />
            </ListItem>
          );
        })}
      </List>
    );
  }

  render() {
    // Grab the classes from the component properties.
    const { classes, auth, history, hideViewAll } = this.props;
    const { loading } = this.state;

    // Render the content to the page.
    return (
      <>
        <AnnouncementDialog ref={this.dialog} />
        <Portlet>
          <PortletHeader>
            <PortletLabel
              icon={<AnnouncementIcon />}
              title="Latest Announcements"
            />
            <PortletToolbar>
              <IconButton
                disabled={loading}
                onClick={this.retryAPICall}
                variant="text">
                <RefreshIcon />
              </IconButton>
            </PortletToolbar>
          </PortletHeader>
          <PortletContent className={classes.content} noPadding>
            {this.renderAnnouncements()}
          </PortletContent>
          {hideViewAll ? null : (
            <PortletFooter noPadding>
              <Button
                disabled={loading}
                fullWidth
                color="primary"
                onClick={() => {
                  history.push(`/${auth.role}/dashboard/announcements`, {});
                }}>
                view all
              </Button>
            </PortletFooter>
          )}
        </Portlet>
      </>
    );
  }
}

export default compose(
  withRouter,
  withAuth,
  withStyles(styles)
)(AnnouncementsCard);
