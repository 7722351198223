// Material UI imports
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  videoRoot: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 0px',
    marginTop: theme.spacing(2.5),
    width: 310,
    height: 175
  },
  videoRootResponsive: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 0px',
    marginTop: theme.spacing(2.5),
    marginRight: theme.spacing(2.0),
    // width: 180,
    // height: 130,
    justifyContent: 'center'
  },
  videoRootLandscape: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 0px',
    marginTop: theme.spacing(2.5),
    marginRight: theme.spacing(2.0),
    // width: 180,
    // height: 50,
    justifyContent: 'center'
  },
  video: {
    width: '100%',
    height: '100%',
    borderRadius: 16,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    objectFit: 'cover'
  },
  content: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    // width: '100%',
    // height: '100%',
    padding: theme.spacing(2)
  },
  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    color: 'white',
    background: 'rgba(0,0,0,0.7)'
  },
  contentFullScreen: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    // width: '100%',
    // height: '100%',
    padding: theme.spacing(2)
  },
  indicatorFullScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    color: 'white',
    background: 'rgba(0,0,0,0.7)'
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  loaderLandscape: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute'
  },
  avatar: {
    width: 75,
    height: 75,
    marginBottom: theme.spacing(2.5),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontWeight: 700
  }
}));
