export default function retryLazy(fn, retriesLeft = 5, interval = 200) {
  return new Promise((resolve, reject) => {
    return fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft === 0) {
          reject(error);
          return;
        }
        setTimeout(() => {
          console.log(`Lazy load retries: ${retriesLeft}`);
          retryLazy(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
