// Material UI components / helpers
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';

// Material UI skeleton
import { Skeleton } from '@material-ui/lab';

// import MoneyIcon from '@mui/icons-material/Money';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles);

function FigureCard(props) {
  const classes = useStyles();
  const { name, value, featureComponent } = props;

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography
            className={classes.header}
            color="textSecondary"
            variant="h6"
            gutterBottom>
            {name && name.toUpperCase()}
          </Typography>
          <Typography variant="h3">{value || <Skeleton />}</Typography>
        </Grid>
        <Grid item>{featureComponent || null}</Grid>
      </Grid>
    </Paper>
  );
}

export default FigureCard;
