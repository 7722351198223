// Material components
import { Grid, Typography, withStyles } from '@material-ui/core';

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  },
  sub: {
    marginTop: theme.spacing(1)
  }
});

function UnderDevelopment(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">Page Under Development</Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.sub}>
              We are still developing this page - sorry about that!
            </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/under_development.png"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(UnderDevelopment);
