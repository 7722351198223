export default (theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.6),
    textAlign: 'center'
  },
  detailsHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  detailsHeaderDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5)
  },
  detailsHeaderInfo: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    flexGrow: 1
  },
  detailsHeaderImgWrapper: {
    display: 'flex',
    width: '70px !important',
    height: '70px !important'
  },
  detailsHeaderImg: {
    width: '100%',
    height: '100%'
  },
  detailsHeaderButton: {
    marginTop: theme.spacing(1.5)
  }
});
