import { Children } from 'react';

// Material helpers
import { Paper } from '@material-ui/core';

const Portlet = (props) => {
  const { children, height, ...rest } = props;

  return (
    <Paper
      elevation={1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: height || '100%'
      }}
      {...rest}>
      {Children.toArray(children)}
    </Paper>
  );
};

export default Portlet;
