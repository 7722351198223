// Material icons
import {
  PeopleRounded as PeopleIcon,
  CodeRounded as CodeIcon,
  SystemUpdateRounded as UpdateIcon,
  AnnouncementRounded as AnnounceIcon
} from '@mui/icons-material';

// eslint-disable-next-line react/display-name
export default (type = 'announce') => {
  switch (type) {
    default:
      return <AnnounceIcon />;
    case 'announce':
      return <AnnounceIcon />;
    case 'feature':
      return <CodeIcon />;
    case 'update':
      return <UpdateIcon />;
    case 'user':
      return <PeopleIcon />;
  }
};
